/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages } from 'react-intl';
import { push } from 'connected-react-router';
import queryString from 'query-string';
// import { addResizeListener, removeResizeListener } from '@folklore/size';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import { getQueryStringFromFilters } from '../../lib/utils';
import { setView as setViewActions, setList as setListActions } from '../../actions/EventsActions';
import { withListLoader, listPropType } from '../../lib/withListLoader';
import withFilters from '../../lib/withFilters';

import FeaturedEventsCarousel from '../blocks/FeaturedEventsCarousel';
import EventGroupsCarousel from '../blocks/EventGroupsCarousel';
import PageMeta from '../partials/PageMeta';
import PageHeader from '../partials/PageHeader';
import Toggles from '../buttons/Toggles';
import MarkerIcon from '../icons/Marker';
import ListIcon from '../icons/List';
import EventsFilters from '../partials/EventsFilters';
import CardsList from '../partials/CardsList';
import EventsMap from '../partials/EventsMap';
import AddBox from '../partials/AddBox';
import Button from '../buttons/Button';
import Detector from '../partials/Detector';

import styles from '../../../styles/pages/list-events.scss';

const messages = defineMessages({
    map: {
        id: 'content.events_view_map',
        defaultMessage: 'Carte',
    },
    list: {
        id: 'content.events_view_grid',
        defaultMessage: 'Grille',
    },
    moreButton: {
        id: 'content.more_events_button',
        defaultMessage: 'Plus d’événements...',
    },
    loadingMore: {
        id: 'content.more_button_loading',
        defaultMessage: 'Chargement...',
    },
});

const propTypes = {
    page: AppPropTypes.page.isRequired,
    filters: AppPropTypes.eventsFilters,
    views: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: AppPropTypes.label,
        }),
    ),
    view: PropTypes.string,
    list: listPropType.isRequired,
    setView: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    loadNextPage: PropTypes.func.isRequired,
};

const defaultProps = {
    views: [
        {
            value: 'map',
            label: messages.map,
            icon: <MarkerIcon className={styles.icon} />,
        },
        {
            value: 'list',
            label: messages.list,
            icon: <ListIcon className={styles.icon} />,
        },
    ],
    view: 'list',
    filters: null,
};

class ListEvents extends Component {
    constructor(props) {
        super(props);

        this.onViewChange = this.onViewChange.bind(this);
        this.onFiltersChange = this.onFiltersChange.bind(this);
        this.onClickMore = this.onClickMore.bind(this);

        this.refContent = null;
    }

    // componentDidMount() {
    //     addResizeListener(this.refContent, this.onContentResize);
    // }

    // componentWillUnmount() {
    //     removeResizeListener(this.refContent, this.onContentResize);
    // }

    onViewChange(view) {
        const { setView } = this.props;
        setView(view);
    }

    onFiltersChange(value) {
        const { setFilters } = this.props;
        setFilters(value);
    }

    onClickMore() {
        const { loadNextPage } = this.props;
        loadNextPage();
    }

    render() {
        const { page, views, view, filters, list } = this.props;
        const { isLoading, items, currentPage, lastPage, total } = list;

        return (
            <div className={styles.container}>
                <PageMeta {...page.share} canonical={filters !== null} noIndex={filters !== null} />
                <div className={styles.inner}>
                    <PageHeader
                        title={page.title}
                        big
                        className={styles.header}
                        colRightClassName={styles.colRight}
                        right={
                            <Toggles
                                options={views}
                                value={view}
                                buttonClassName={styles.button}
                                onChange={this.onViewChange}
                            />
                        }
                    />
                    {page.hasFeatured ? (
                        <div className={styles.featured}>
                            <FeaturedEventsCarousel />
                        </div>
                    ) : null}
                    {page.hasGroups ? (
                        <div className={styles.featured}>
                            <EventGroupsCarousel />
                        </div>
                    ) : null}
                    <div className={styles.filters}>
                        <EventsFilters
                            value={filters}
                            filtersOptions
                            onChange={this.onFiltersChange}
                        />
                    </div>
                    <div
                        className={styles.content}
                        ref={(ref) => {
                            this.refContent = ref;
                        }}
                    >
                        {view === 'list' ? (
                            <CardsList
                                items={items}
                                isLoading={total === 0 && isLoading}
                                type="event"
                                loadingCards={3}
                                withNoResultsMessage
                            />
                        ) : (
                            <EventsMap />
                        )}
                        {view === 'list' && currentPage < lastPage ? (
                            <div className={styles.loadMore}>
                                <Detector onEnter={this.onClickMore} disabled={isLoading}>
                                    <Button red disabled={isLoading} onClick={this.onClickMore}>
                                        {isLoading ? messages.loadingMore : messages.moreButton}
                                    </Button>
                                </Detector>
                            </div>
                        ) : null}
                        <div className={styles.addBox}>
                            <AddBox />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ListEvents.propTypes = propTypes;
ListEvents.defaultProps = defaultProps;

const WithListLoaderContainer = withListLoader(
    ({ urlGenerator, page, filters }) => {
        const url = urlGenerator
            .route('pages.list', {
                page: page.slug,
            })
            .replace(/(\.json|\/)?$/, '.json');
        return `${url}${
            filters !== null
                ? `?${queryString.stringify(filters, {
                      arrayFormat: 'bracket',
                  })}`
                : ''
        }`;
    },
    (props) => props,
)(ListEvents);

const WithFiltersContainer = withFilters({
    timeSpan: {
        multiple: false,
        type: 'string',
    },
    q: {
        multiple: false,
        type: 'string',
    },
    children: {
        multiple: false,
        type: 'bool',
    },
    tags: true,
    types: true,
    venues: true,
    regions: true,
    audiences: true,
    organizations: true,
})(WithListLoaderContainer);

const WithStateContainer = connect(
    ({ events }) => ({
        ...events,
    }),
    (dispatch, { location }) => ({
        setFilters: (filters) => dispatch(push(`${location}${getQueryStringFromFilters(filters)}`)),
        setView: (view) => dispatch(setViewActions(view)),
        setList: (data) => dispatch(setListActions(data)),
    }),
)(WithFiltersContainer);
const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);

export default WithUrlGeneratorContainer;
