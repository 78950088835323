/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import { Link } from 'react-router-dom';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/menus/menu.scss';

const propTypes = {
    items: AppPropTypes.menuItems,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    currentClassName: PropTypes.string,
    spacerClassName: PropTypes.string,
    linkClassName: PropTypes.string,
};

const defaultProps = {
    items: [],
    className: null,
    itemsClassName: null,
    itemClassName: null,
    currentClassName: null,
    spacerClassName: null,
    linkClassName: null,
};

const Menu = ({
    items,
    className,
    itemsClassName,
    itemClassName,
    currentClassName,
    spacerClassName,
    linkClassName,
}) => (
    <div
        className={classNames({
            [styles.container]: true,
            [className]: className !== null,
        })}
    >
        <ul
            className={classNames([
                styles.items,
                {
                    [itemsClassName]: itemsClassName !== null,
                },
            ])}
        >
            {items.map((it, index) => {
                const isItems = isArray(it);
                const {
                    key = null,
                    label,
                    url,
                    external = false,
                    spacer = false,
                    current = false,
                } = isItems ? {} : it;

                const itemKey = `col-${key || index}`;
                const finalClassName = classNames([
                    styles.item,
                    {
                        [styles.current]: current,
                        [itemClassName]: itemClassName !== null,
                        [currentClassName]: currentClassName !== null && current,
                    },
                ]);
                if (isItems) {
                    return (
                        <li className={finalClassName} key={itemKey}>
                            <Menu items={it} />
                        </li>
                    );
                }

                if (spacer) {
                    return (
                        <li
                            className={classNames([
                                finalClassName,
                                styles.spacer,
                                {
                                    [spacerClassName]: spacerClassName !== null,
                                },
                            ])}
                            key={itemKey}
                        />
                    );
                }
                return (
                    <li className={finalClassName} key={itemKey}>
                        {external ? (
                            <a
                                href={url}
                                target="_blank"
                                className={classNames([
                                    styles.link,
                                    {
                                        [linkClassName]: linkClassName !== null,
                                    },
                                ])}
                            >
                                {label}
                            </a>
                        ) : (
                            <Link
                                to={url}
                                onClick={() => {
                                    if (window.scrollTo) {
                                        window.scrollTo({
                                            top: 0,
                                        });
                                    }
                                }}
                                className={classNames([
                                    styles.link,
                                    {
                                        [linkClassName]: linkClassName !== null,
                                    },
                                ])}
                            >
                                {label}
                            </Link>
                        )}
                    </li>
                );
            })}
        </ul>
    </div>
);

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;
