import { SET_VENUES, SET_EVENTS } from '../actions/EventsMapActions';

const initialState = {
    venues: null,
    events: {},
};

const EventsMapReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_VENUES:
        return {
            ...state,
            venues: action.payload,
        };
    case SET_EVENTS: {
        const { venue, event } = action.payload;
        return {
            ...state,
            events: {
                ...state.events,
                [venue.id]: event,
            },
        };
    }
    default:
        return state;
    }
};

export default EventsMapReducer;
