import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const PlusIcon = ({ color, className }) => (
    <svg
        version="1.1"
        x="0px"
        y="0px"
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            d="M8,0H2C0.9,0,0,0.9,0,2v6c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2V2C10,0.9,9.1,0,8,0z M8,5.5H5.6V8H4.4V5.5H2v-1h2.4V2h1.1v2.5H8V5.5z"
        />
    </svg>
);

PlusIcon.propTypes = propTypes;
PlusIcon.defaultProps = defaultProps;

export default PlusIcon;
