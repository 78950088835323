import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    fat: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    fat: false,
    className: null,
};

const SearchIcon = ({ color, fat, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={fat ? '13.2px' : '18.9px'}
        height={fat ? '13.6px' : '19.6px'}
        viewBox={fat ? '0 0 13.2 13.6' : '0 0 18.9 19.6'}
        xmlSpace="preserve"
        className={className}
    >
        {fat ? (
            <g>
                <circle
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    cx="7.5"
                    cy="5.7"
                    r="4.7"
                />
                <line
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    x1="4.4"
                    y1="9.2"
                    x2="0.7"
                    y2="12.9"
                />
            </g>
        ) : (
            <g>
                <circle
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    cx="10.8"
                    cy="8.1"
                    r="7.1"
                />
                <line
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    className="st0"
                    x1="6.2"
                    y1="13.4"
                    x2="0.7"
                    y2="18.9"
                />
            </g>
        )}
    </svg>
);

SearchIcon.propTypes = propTypes;
SearchIcon.defaultProps = defaultProps;

export default SearchIcon;
