import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container } from '../lib/react-container';

import reducers from '../reducers/index';
import * as AppPropTypes from '../lib/PropTypes';

import App from './App';
import TrackingContext, { tracker } from '../lib/TrackingContext';

const propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        PropTypes.objectOf(PropTypes.string),
    ]),
    routes: PropTypes.objectOf(PropTypes.string),
    isSplash: PropTypes.bool,
    page: AppPropTypes.page,
    pages: AppPropTypes.pages,
    categories: AppPropTypes.categories,
    popup: AppPropTypes.popup,
    headerTopMenu: AppPropTypes.menuItems,
    headerMainMenu: AppPropTypes.menuItems,
    socialMenu: AppPropTypes.menuItems,
    footerMenu: AppPropTypes.menuColumns,
    googleApiKey: PropTypes.string,
    facebookAppId: PropTypes.string,
    facebookPage: PropTypes.string,
    lavalPosition: AppPropTypes.position,
    isPrerender: PropTypes.bool,
    statusCode: AppPropTypes.statusCode,
};

const defaultProps = {
    locale: 'fr',
    messages: {},
    routes: {},
    isSplash: false,
    page: null,
    pages: [],
    categories: [],
    popup: null,
    headerTopMenu: [],
    headerMainMenu: [],
    socialMenu: [],
    footerMenu: [],
    googleApiKey: null,
    facebookAppId: null,
    facebookPage: null,
    lavalPosition: null,
    isPrerender: false,
    statusCode: null,
};

class Root extends PureComponent {
    // eslint-disable-next-line class-methods-use-this
    getStoreProps() {
        const {
            page,
            pages,
            categories,
            popup,
            headerTopMenu,
            headerMainMenu,
            socialMenu,
            footerMenu,
            googleApiKey,
            facebookAppId,
            facebookPage,
            lavalPosition,
            isPrerender,
            statusCode,
        } = this.props;
        const pagesMap = pages.reduce(
            (allPages, p) => ({
                ...allPages,
                [p.url]: p,
            }),
            {},
        );
        return {
            reducers,
            initialState: {
                site: {
                    pages:
                        page !== null
                            ? {
                                ...pagesMap,
                                [page.url]: page,
                            }
                            : {
                                ...pagesMap,
                            },
                    categories,
                    popup,
                    headerTopMenu,
                    headerMainMenu,
                    socialMenu,
                    footerMenu,
                    googleApiKey,
                    facebookAppId,
                    facebookPage,
                    lavalPosition,
                    isPrerender,
                    statusCode,
                },
            },
        };
    }

    getIntlProps() {
        const { locale, messages } = this.props;
        return {
            locale,
            messages,
            textComponent: Fragment,
        };
    }

    getUrlGenerator() {
        const { routes } = this.props;
        return {
            routes,
        };
    }

    render() {
        const { isSplash } = this.props;

        return (
            <Container
                store={this.getStoreProps()}
                intl={this.getIntlProps()}
                urlGenerator={this.getUrlGenerator()}
            >
                <TrackingContext.Provider value={tracker}>
                    <App isSplash={isSplash} />
                </TrackingContext.Provider>
            </Container>
        );
    }
}

Root.propTypes = propTypes;
Root.defaultProps = defaultProps;

export default Root;
