/* eslint-disable indent */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import useSound from '../../hooks/useSound';
import { getWindowWidth } from '../../lib/utils';
import { withTracking } from '../../lib/TrackingContext';

import spotify from '../../../img/podcast-spotify.svg';
import apple from '../../../img/podcast-apple.svg';
import google from '../../../img/podcast-google.svg';
import rss from '../../../img/podcast-rss.svg';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/partials/podcast.scss';

const icons = { spotify, apple, google, rss };

const propTypes = {
    audio: AppPropTypes.audio,
    audioUrl: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        sizes: PropTypes.shape({
            medium: PropTypes.string,
        }),
    }),
    show: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string, label: PropTypes.string })),
    isSection: PropTypes.bool,
    trackEvent: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    audio: null,
    audioUrl: null,
    image: null,
    show: null,
    title: null,
    description: null,
    links: null,
    isSection: false,
    className: null,
};

const PodcastPlayer = ({
    audio,
    audioUrl,
    image,
    show,
    title,
    description,
    links,
    isSection,
    trackEvent,
    className,
}) => {
    const { width: initialWindowWidth } = getWindowWidth();
    const [windowWidth, setWindowWidth] = useState(initialWindowWidth);

    const progressRef = useRef();
    const barRef = useRef();
    const [width, setWidth] = useState(0);
    const [playing, setPlaying] = useState();
    const [currentTime, setCurrentTime] = useState(0);
    const [endTime, setEndTime] = useState(0);

    const hasShow = !isEmpty(show);
    const hasTitle = !isEmpty(title);
    const hasDescription = !isEmpty(description);

    const { url: audioObjectUrl = null } = audio || {};
    const finalUrl = audioUrl || audioObjectUrl;
    const hasAudio = finalUrl !== null;
    const hasDetails = hasShow || hasTitle || hasAudio || hasDescription;
    const hasLinks =
        links !== null && links.length > 0 && links.reduce((acc, link) => acc || link.url, false);

    const {
        play,
        pause,
        seek,
        duration,
        playing: soundPlaying,
        sound,
    } = useSound(finalUrl, { html5: true });

    const setFinalEndTime = useCallback(() => {
        setEndTime(duration());
    }, [setEndTime, duration]);

    // Clicks
    const onClickPlay = useCallback(() => {
        const isPlaying = soundPlaying();
        if (isPlaying) {
            pause();
            trackEvent('AudioPlayer', 'pause', finalUrl);
        } else {
            play();
            trackEvent('AudioPlayer', 'play', finalUrl);
        }
        setPlaying(!isPlaying);
        setFinalEndTime();
    }, [setPlaying, play, pause, soundPlaying, setFinalEndTime, trackEvent, finalUrl]);

    const onClickSeek = useCallback(
        (e) => {
            let posx = 0;
            if (e.pageX) {
                posx = e.pageX;
            } else if (e.clientX || e.clientY) {
                posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            }
            const rect = progressRef.current.getBoundingClientRect();
            const offset = posx - rect.left;
            const pct = Math.min((offset / rect.width) * 100, 100);
            const soundDuration = duration();
            const current = soundDuration > 0 ? (pct / 100) * soundDuration : 0;
            seek(current.toFixed(2));
            trackEvent('AudioPlayer', 'seek', finalUrl);
            if (!playing) {
                play();
                setPlaying(true);
                trackEvent('AudioPlayer', 'play', finalUrl);
            }
        },
        [play, playing, setPlaying, seek, duration, width, windowWidth, trackEvent, finalUrl],
    );

    const onClickLink = useCallback(
        (link) => {
            trackEvent('AudioPlayer', 'click', link);
        },
        [trackEvent],
    );

    // Track width
    const setProgressWidth = useCallback(() => {
        if (progressRef.current) {
            const rect = progressRef.current.getBoundingClientRect();
            setWidth(rect.width);
        }
    }, [setWidth, progressRef.current, windowWidth]);

    useEffect(() => {
        if (progressRef.current) {
            window.addEventListener('resize', setProgressWidth);
        }
        return () => {
            window.removeEventListener('resize', setProgressWidth);
        };
    }, [progressRef.current]);

    // Move bar
    const setBarWidth = useCallback(
        (barWidth) => {
            if (barRef.current) {
                barRef.current.style.width = `${barWidth - 7}px`;
            }
        },
        [barRef.current],
    );

    useEffect(() => {
        setProgressWidth();
        let id = null;
        if (playing && sound && barRef.current) {
            id = setInterval(() => {
                if (playing && sound) {
                    const current = seek();
                    const soundDuration = duration();
                    const pct = soundDuration > 0 ? current / soundDuration : 0;
                    setBarWidth(Math.round(pct * width));
                    setCurrentTime(current);
                }
            }, 300);
        }
        return () => {
            clearInterval(id);
        };
    }, [sound, seek, duration, playing, width, setBarWidth, setProgressWidth, setCurrentTime]);

    // Track end
    const setEnded = useCallback(() => {
        setBarWidth(width);
        setPlaying(false);
        trackEvent('AudioPlayer', 'ended', finalUrl);
    }, [width, setPlaying, setBarWidth, trackEvent, finalUrl]);

    useEffect(() => {
        if (sound) {
            sound.on('end', setEnded);
        }
        return () => {
            if (sound) {
                sound.off('end', setEnded);
            }
        };
    }, [sound, setEnded]);

    // Onload

    useEffect(() => {
        if (sound) {
            sound.on('load', setFinalEndTime);
        }
        return () => {
            if (sound) {
                sound.off('load', setFinalEndTime);
            }
        };
    }, [sound, setFinalEndTime]);

    // Resize
    const setWindowWidthOnChange = useCallback(() => {
        const { width: newWidth } = getWindowWidth();
        const soundDuration = duration();
        const pct = soundDuration > 0 ? seek() / soundDuration : 0;
        setProgressWidth();
        setBarWidth(Math.round(pct * width));
        setWindowWidth(newWidth);
    }, [width, seek, duration, setWindowWidth]);

    useEffect(() => {
        window.addEventListener('resize', setWindowWidthOnChange);
        return () => {
            window.removeEventListener('resize', setWindowWidthOnChange);
        };
    }, [setWindowWidthOnChange]);

    const buttonBlock = hasAudio ? (
        <button
            className={classNames({
                [styles.control]: true,
                [styles.play]: playing,
                [styles.pause]: !playing,
            })}
            type="button"
            onClick={onClickPlay}
        >
            <div className={styles.circle}>
                {playing ? (
                    <div className={styles.pauseIcon} />
                ) : (
                    <div className={styles.playIcon} />
                )}
            </div>
        </button>
    ) : null;

    const imageBlock =
        image !== null ? (
            <img src={image.sizes.medium} alt={title} className={styles.image} />
        ) : null;

    const inner =
        windowWidth < 760 ? (
            <div className={styles.left}>
                {imageBlock}
                {buttonBlock}
            </div>
        ) : (
            <>
                {imageBlock}
                {buttonBlock}
            </>
        );

    return (
        <div
            className={classNames({
                [styles.container]: true,
                [styles.isSection]: isSection,
                [className]: className !== null,
            })}
        >
            <div className={styles.wrapper}>
                <div className={styles.inner}>
                    {inner}
                    {hasDetails ? (
                        <div className={styles.details}>
                            <div className={styles.innerDetails}>
                                {hasShow ? <p className={styles.show}>{show}</p> : null}
                                {hasTitle ? <p className={styles.title}>{title}</p> : null}
                                <div className={styles.time} />
                                <button
                                    ref={progressRef}
                                    className={styles.progress}
                                    type="button"
                                    onClick={hasAudio ? onClickSeek : () => {}}
                                >
                                    <div ref={barRef} className={styles.bar} />
                                </button>
                                <div className={styles.time}>
                                    <span className={styles.currentTime}>
                                        {isNumber(currentTime) && currentTime > 0
                                            ? new Date(currentTime * 1000)
                                                  .toISOString()
                                                  .substr(11, 8)
                                            : '00:00:00'}
                                    </span>
                                    <span className={styles.endTime}>
                                        {isNumber(endTime) && endTime > 0
                                            ? new Date(endTime * 1000).toISOString().substr(11, 8)
                                            : '00:00:00'}
                                    </span>
                                </div>
                                {hasDescription ? (
                                    <p className={styles.description}>{description}</p>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </div>
                {hasLinks ? (
                    <div className={styles.links}>
                        <div className={classNames([styles.link])}>
                            <span className={styles.text}>Écouter sur :</span>
                        </div>
                        {links.map(({ url = null, label = null, type = null }) =>
                            url && label ? (
                                <a
                                    className={classNames([styles.link])}
                                    href={url}
                                    target="_blank"
                                    rel="noreferrer"
                                    key={`podcast-${label}`}
                                    onClick={() => onClickLink(label)}
                                >
                                    <span className={styles.icon} />
                                    {label && !type ? (
                                        <span className={styles.text}>{label}</span>
                                    ) : null}
                                    {type && icons[type] ? (
                                        <img
                                            className={classNames([
                                                styles.logo,
                                                { [styles[type]]: type !== null },
                                            ])}
                                            src={icons[type]}
                                            alt={label}
                                        />
                                    ) : null}
                                    <span className={styles.empty} />
                                </a>
                            ) : null,
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

PodcastPlayer.propTypes = propTypes;
PodcastPlayer.defaultProps = defaultProps;

const WithTrackingContainer = withTracking(PodcastPlayer);

export default WithTrackingContainer;
