import React from 'react';
// import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';

import PageMeta from '../partials/PageMeta';
import PageHeader from '../partials/PageHeader';
import ContactForm from '../partials/ContactForm';
import ContactMap from '../partials/ContactMap';

import styles from '../../../styles/pages/contact-us.scss';

const propTypes = {
    page: AppPropTypes.page.isRequired,
};

const ContactUs = ({ page }) => (
    <div className={styles.container}>
        <PageMeta {...page.share} />
        <div className={styles.inner}>
            <PageHeader title={page.title} />
            <div className={styles.content}>
                <div className={styles.cols}>
                    <div className={classNames([styles.col, styles.colLeft])}>
                        <ContactForm className={styles.form} />
                    </div>
                    <div className={classNames([styles.col, styles.colRight])}>
                        <ContactMap className={styles.map} />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

ContactUs.propTypes = propTypes;

export default React.memo(ContactUs);
