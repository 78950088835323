import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

// import * as AppPropTypes from '../../lib/PropTypes';
import Button from '../buttons/Button';
import AddIcon from '../icons/Add';
import styles from '../../../styles/partials/add-box.scss';

const messages = defineMessages({
    label: {
        id: 'content.add_box_label',
        defaultMessage: 'Vous souhaitez afficher votre événement?',
    },
    button: {
        id: 'content.add_box_button',
        defaultMessage: 'Contactez-nous!',
    },
});

const propTypes = {
    contactLink: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    contactLink: null,
    className: null,
};

const AddBox = ({ contactLink, className }) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <div className={styles.cols}>
            <div className={classNames([styles.col, styles.colIcon])}>
                <AddIcon className={styles.icon} />
            </div>
            <div className={classNames([styles.col, styles.colLabel])}>
                <div className={styles.label}>
                    <FormattedMessage {...messages.label} />
                </div>
                <Button href={contactLink} className={styles.button} red small>
                    {messages.button}
                </Button>
            </div>
        </div>
    </div>
);

AddBox.propTypes = propTypes;
AddBox.defaultProps = defaultProps;

const WithStateContainer = connect(({ site }) => ({
    contactLink: Object.keys(site.pages).find(url => site.pages[url].handle === 'contact') || null,
}))(AddBox);
export default WithStateContainer;
