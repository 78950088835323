import { SET_FILTERS_OPTIONS, SET_LIST, SET_VIEW } from '../actions/MagazinesActions';

const initialState = {
    view: 'list',
    filtersOptions: null,
    isLoading: false,
    items: null,
    currentPage: null,
    lastPage: 1,
    total: 0,
    lastResponse: null,
};

const Magazines = (state = initialState, action) => {
    switch (action.type) {
    case SET_VIEW:
        return {
            ...state,
            view: action.payload,
        };
    case SET_FILTERS_OPTIONS:
        return {
            ...state,
            filtersOptions: action.payload,
        };
    case SET_LIST: {
        return {
            ...state,
            ...action.payload,
        };
    }
    default:
        return state;
    }
};

export default Magazines;
