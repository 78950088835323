/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import Button from './Button';

import styles from '../../../styles/buttons/buy-tickets.scss';

const messages = defineMessages({
    label: {
        id: 'content.buy_tickets',
        defaultMessage: 'Acheter des billets',
    },
    labelFree: {
        id: 'content.buy_tickets_free',
        defaultMessage: 'En savoir plus',
    },
});

const propTypes = {
    label: AppPropTypes.label,
    labelFree: AppPropTypes.label,
    isFree: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    label: messages.label,
    labelFree: messages.labelFree,
    isFree: false,
    className: null,
};

const BuyTicketsButton = ({
    label, labelFree, isFree, className, ...props
}) => (
    <Button
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
        {...props}
    >
        {isFree ? labelFree : label}
    </Button>
);

BuyTicketsButton.propTypes = propTypes;
BuyTicketsButton.defaultProps = defaultProps;

export default BuyTicketsButton;
