/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages } from 'react-intl';
import { Swipeable } from 'react-swipeable';

import * as AppPropTypes from '../../lib/PropTypes';
import { withListLoader, listPropType } from '../../lib/withListLoader';
import Card from '../cards/Card';
import Button from '../buttons/Button';
import ArrowButton from '../buttons/Arrow';

import styles from '../../../styles/blocks/carousel.scss';

const messages = defineMessages({
    more: {
        id: 'content.carousel_more_button',
        defaultMessage: 'En voir plus...',
    },
});

const ITEMS_PER_PAGE = 3;
const SMALL_ITEMS_PER_PAGE = 5;

const propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    morePage: AppPropTypes.page,
    moreLabel: PropTypes.string,
    list: listPropType.isRequired,
    loadPage: PropTypes.func.isRequired,
    itemMaxWidth: PropTypes.number,
    perPage: PropTypes.number,
    smallPerPage: PropTypes.number,
    withoutPagination: PropTypes.bool,
    small: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    purplePage: PropTypes.bool,
    whitePage: PropTypes.bool,
    posterCards: PropTypes.bool,
    cardsProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    isSection: PropTypes.bool,
    isHomePage: PropTypes.bool,
    isPreview: PropTypes.bool,
    hideOnEmpty: PropTypes.bool,
    smallTitle: PropTypes.bool,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
    navigationClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    emptyCardClassName: PropTypes.string,
};

const defaultProps = {
    title: null,
    url: undefined,
    morePage: null,
    moreLabel: null,
    perPage: ITEMS_PER_PAGE,
    smallPerPage: SMALL_ITEMS_PER_PAGE,
    withoutPagination: false,
    small: false,
    itemMaxWidth: 400,
    isFullWidth: true,
    purplePage: false,
    whitePage: false,
    posterCards: true,
    cardsProps: null,
    isSection: false,
    isHomePage: false,
    isPreview: false,
    hideOnEmpty: false,
    smallTitle: false,
    className: null,
    itemsClassName: null,
    navigationClassName: null,
    buttonClassName: null,
    emptyCardClassName: null,
};

class Carousel extends PureComponent {
    static getItemsInView(width, maxWidth) {
        return width > 500 ? Math.ceil(width / maxWidth) : 1; // Math.min(, maxItems);
    }

    static getDerivedStateFromProps(props, state) {
        const urlChanged = props.url !== state.url;
        if (urlChanged) {
            return {
                url: props.url,
                index: 0,
                nextIndex: 0,
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.onResize = this.onResize.bind(this);
        this.onClickPrevious = this.onClickPrevious.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
        this.onSwipedLeft = this.onSwipedLeft.bind(this);
        this.onSwipedRight = this.onSwipedRight.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
        this.onSwiping = this.onSwiping.bind(this);

        this.refContainer = null;

        this.state = {
            url: null, // eslint-disable-line react/no-unused-state
            index: 0,
            nextIndex: 0,
            swipeOffset: 0,
            itemsInView: Carousel.getItemsInView(
                window.innerWidth,
                this.getItemsMaxWidth(),
                this.getItemsPerPage(),
            ),
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.updateItemsInView();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        this.updateItemsInView();
    }

    onClickPrevious() {
        this.previous();
    }

    onClickNext() {
        this.next();
    }

    // eslint-disable-next-line class-methods-use-this
    onSwiping(e) {
        const { deltaX: x } = e;
        const { list } = this.props;
        const { index, nextIndex, itemsInView } = this.state;
        const { total } = list;
        const isTransitioning = index !== nextIndex;
        if ((x < 0 && index === 0) || (x > 0 && index === total - itemsInView) || isTransitioning) {
            return;
        }
        const offset = x / this.refInner.offsetWidth;
        this.setState({
            swipeOffset: offset,
        });
    }

    onSwipedRight() {
        const { swipeOffset, itemsInView } = this.state;
        if (swipeOffset === 0) {
            return;
        }
        if (Math.abs(swipeOffset) > 1 / (itemsInView + 1)) {
            this.previous();
        }
        this.setState({
            swipeOffset: 0,
        });
    }

    onSwipedLeft() {
        const { swipeOffset, itemsInView } = this.state;
        if (swipeOffset === 0) {
            return;
        }
        if (Math.abs(swipeOffset) > 1 / (itemsInView + 1)) {
            this.next();
        }
        this.setState({
            swipeOffset: 0,
        });
    }

    onTransitionEnd() {
        this.setState(({ nextIndex }) => ({
            index: nextIndex,
        }));
    }

    getItemsPerPage() {
        const { small, smallPerPage, perPage, withoutPagination, list } = this.props;
        if (withoutPagination) {
            return Math.max(list.total, 1);
        }
        return small ? smallPerPage : perPage;
    }

    getItemsMaxWidth() {
        const { small, itemMaxWidth } = this.props;
        return small ? Math.round(itemMaxWidth / 1.666) : itemMaxWidth;
    }

    setIndex(index) {
        const { list, loadPage } = this.props;
        const { itemsInView } = this.state;
        const { pagesLoaded } = list;
        const perPage = this.getItemsPerPage();
        const lastIndex = index + 1 + itemsInView;
        const page = Math.ceil(lastIndex / perPage);
        if (pagesLoaded.indexOf(page) === -1) {
            loadPage(page);
        }

        this.setState({
            nextIndex: index,
        });
    }

    updateItemsInView() {
        const { list } = this.props;
        const { total } = list;
        const itemsInView = Carousel.getItemsInView(
            this.refInner.offsetWidth,
            this.getItemsMaxWidth(),
            this.getItemsPerPage(),
        );
        const lastIndex = Math.max(total - 1 - itemsInView, 0);

        this.setState(({ index: currentIndex, nextIndex }) => ({
            itemsInView,
            index: currentIndex > lastIndex ? lastIndex : currentIndex,
            nextIndex: nextIndex > lastIndex ? lastIndex : nextIndex,
        }));
    }

    previous() {
        const { list } = this.props;
        const { index } = this.state;
        const nextIndex = index === 0 ? list.total - 1 : index - 1;
        this.setIndex(nextIndex);
    }

    next() {
        const { list } = this.props;
        const { index } = this.state;
        const nextIndex = index === list.total - 1 ? 0 : index + 1;
        this.setIndex(nextIndex);
    }

    renderItem(it, index) {
        const { small, posterCards, cardsProps, emptyCardClassName } = this.props;
        const { index: currentIndex, nextIndex, itemsInView, swipeOffset } = this.state;
        const isTransitioning = currentIndex !== nextIndex;
        const isCurrent = currentIndex === index;
        const isBefore = index < currentIndex;
        const isAfter = index >= currentIndex + itemsInView;
        const isDirectBefore = isBefore && index === currentIndex - 1;
        const isDirectAfter = isAfter && index === currentIndex + itemsInView;
        const isLast = index === currentIndex + itemsInView - 1;
        const isSwiping = swipeOffset !== 0;
        // prettier-ignore
        const swipingX = (100 * -swipeOffset);
        let swipingOpacity = null;
        if (swipeOffset > 0 && isCurrent) {
            swipingOpacity = 1 - Math.abs(swipeOffset);
        } else if (swipeOffset > 0 && isDirectAfter) {
            swipingOpacity = Math.abs(swipeOffset);
        } else if (swipeOffset < 0 && isDirectBefore) {
            swipingOpacity = Math.abs(swipeOffset);
        } else if (swipeOffset < 0 && isLast) {
            swipingOpacity = 1 - Math.abs(swipeOffset);
        }

        return (
            <div
                key={`item-${index}`}
                className={classNames([
                    styles.item,
                    {
                        [styles.before]: isBefore,
                        [styles.after]: isAfter,
                        [styles.isTransitioning]: isTransitioning,
                        [styles.willAppear]:
                            isTransitioning &&
                            ((nextIndex > currentIndex && index === nextIndex + itemsInView - 1) ||
                                nextIndex === index),
                        [styles.willDisappear]:
                            isTransitioning &&
                            ((nextIndex > currentIndex && index < nextIndex) ||
                                (nextIndex < currentIndex && index === nextIndex + itemsInView)),
                        [styles.moveLeft]: isTransitioning && nextIndex > currentIndex,
                        [styles.moveRight]: isTransitioning && nextIndex < currentIndex,
                    },
                ])}
                style={{
                    width: `${100 / itemsInView}%`,
                    transform: isSwiping ? `translate(${swipingX}%, 0)` : null,
                    opacity: isSwiping ? swipingOpacity : null,
                }}
                onTransitionEnd={isTransitioning && index === 0 ? this.onTransitionEnd : null}
            >
                <div className={styles.inner}>
                    {it !== null ? (
                        <Card
                            item={it}
                            small={small}
                            poster={posterCards}
                            withShadow
                            {...cardsProps}
                        />
                    ) : (
                        <div
                            className={classNames([
                                styles.emptyCard,
                                {
                                    [styles.poster]: posterCards,
                                    [emptyCardClassName]: emptyCardClassName,
                                },
                            ])}
                        />
                    )}
                </div>
            </div>
        );
    }

    renderEmptyItems(startIndex = 0, count = null) {
        const perPage = this.getItemsPerPage();
        const items = [];
        const lastIndex = count !== null ? count : perPage;
        for (let i = 0; i < lastIndex; i += 1) {
            items.push(this.renderItem(null, startIndex + i));
        }
        return items;
    }

    renderItems() {
        const { list } = this.props;
        const { lastPage, pagesLoaded, items, isLoading, total } = list;
        if (isLoading && total === 0) {
            return this.renderEmptyItems();
        }
        const perPage = this.getItemsPerPage();
        const allPages = [];
        for (let page = 1; page <= lastPage; page += 1) {
            allPages.push(pagesLoaded.indexOf(page));
        }

        const listItems = allPages.reduce((prevItems, pageLoadedIndex, index) => {
            const startIndex = prevItems.length;
            let currentItems;
            if (pageLoadedIndex === -1) {
                const itemsCount = index + 1 === lastPage ? total % perPage : perPage;
                currentItems = this.renderEmptyItems(startIndex, itemsCount);
            } else {
                const itemsStartIndex = pageLoadedIndex * perPage;
                currentItems = items
                    .slice(itemsStartIndex, itemsStartIndex + perPage)
                    .map((item, itemIndex) => this.renderItem(item, startIndex + itemIndex));
            }
            return [...prevItems, ...currentItems];
        }, []);

        return listItems;
    }

    render() {
        const {
            title,
            list,
            morePage,
            moreLabel,
            small,
            isFullWidth,
            purplePage,
            whitePage,
            isSection,
            isHomePage,
            isPreview,
            hideOnEmpty,
            smallTitle,
            className,
            itemsClassName,
            navigationClassName,
            buttonClassName,
        } = this.props;

        const { index, itemsInView } = this.state;
        const { isLoading, pagesLoaded, total, lastResponse } = list;

        const hasPrevious = total > itemsInView && index > 0;
        const hasNext = total > itemsInView && index < total - itemsInView;

        return (
            <div
                className={classNames({
                    [styles.container]: true,
                    [styles.small]: small,
                    [styles.purplePage]: purplePage,
                    [styles.whitePage]: whitePage,
                    [styles.overrideWhite]: purplePage && isPreview,
                    [styles.hasMaxWidth]: !isFullWidth,
                    [styles.isSection]: isSection,
                    [styles.hidden]:
                        !isPreview &&
                        hideOnEmpty &&
                        lastResponse !== null &&
                        !isLoading &&
                        pagesLoaded &&
                        total === 0,
                    [className]: className !== null,
                })}
            >
                <div className={styles.maxContainer}>
                    {title && !isHomePage ? (
                        <h4
                            className={classNames({
                                [styles.title]: true,
                                [styles.smallTitle]: smallTitle,
                            })}
                        >
                            {title}
                        </h4>
                    ) : null}
                    <div
                        className={classNames({
                            [styles.items]: true,
                            [styles.centered]: true,
                            // [styles.centered]: total < itemsInView,
                            [itemsClassName]: itemsClassName !== null,
                        })}
                    >
                        <nav
                            className={classNames([
                                styles.navigation,
                                {
                                    [navigationClassName]: navigationClassName !== null,
                                },
                            ])}
                        >
                            <ArrowButton
                                direction="left"
                                className={classNames([
                                    styles.button,
                                    styles.left,
                                    {
                                        [buttonClassName]: buttonClassName !== null,
                                        [styles.disabled]: !hasPrevious,
                                    },
                                ])}
                                disabled={isLoading || !hasPrevious}
                                onClick={this.onClickPrevious}
                            />
                            <ArrowButton
                                direction="right"
                                className={classNames([
                                    styles.button,
                                    styles.right,
                                    {
                                        [buttonClassName]: buttonClassName !== null,
                                        [styles.disabled]: !hasNext,
                                    },
                                ])}
                                disabled={isLoading || !hasNext}
                                onClick={this.onClickNext}
                            />
                        </nav>
                        <Swipeable
                            className={styles.inner}
                            innerRef={(ref) => {
                                this.refInner = ref;
                            }}
                            onSwipedRight={this.onSwipedRight}
                            onSwipedLeft={this.onSwipedLeft}
                            onSwiping={this.onSwiping}
                            preventDefaultTouchmoveEvent
                            stopPropagation
                        >
                            {this.renderItems()}
                        </Swipeable>
                    </div>
                    {morePage !== null ? (
                        <div className={styles.moreContainer}>
                            <Button
                                href={morePage.url}
                                className={styles.moreButton}
                                red
                                withShadow
                            >
                                {moreLabel || messages.more}
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

Carousel.propTypes = propTypes;
Carousel.defaultProps = defaultProps;

const WithListLoaderContainer = withListLoader(({ url = null }) => url, null, {
    perPage: ({
        small = defaultProps.small,
        perPage = defaultProps.perPage,
        smallPerPage = defaultProps.smallPerPage,
    }) => (small ? smallPerPage : perPage),
})(Carousel);

export default WithListLoaderContainer;
