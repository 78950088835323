import React from 'react';
import PropTypes from 'prop-types';

const districtsMap = {
    'saint-vincent-de-paul': {
        path: 'M21.5,120.1L3.4,64.9c-1.1-3.4,0.2-7.2,3.2-9.2L26.8,42c2-1.4,4.5-1.7,6.9-1l15.1,4.5c3.7,1.1,7.7-0.6,9.5-4L76.1,7.3c1.5-2.9,4.6-4.6,7.8-4.3l0,0c3.1,0.3,5.8,2.3,6.9,5.2L104,43.6c0.9,2.4,0.6,5.1-0.8,7.2l-34.4,52.7c-0.7,1.1-1.7,2-2.8,2.6l-32.8,18.5C28.7,127.2,23.1,125,21.5,120.1z',
        width: 107.5,
        height: 128.7,
    },
    'saint-francois': {
        path: 'M23.8,18.3L3.3,82.5c-1,3,0.7,6.2,3.7,7.2l48.4,16.1c1,0.3,2,0.4,3,0.2l30.3-6.1c0.8-0.2,1.7-0.2,2.5,0l35.5,8.6c2.7,0.7,4.6,3.2,4.4,6l-0.2,3.3c-0.2,2.8,1.7,5.3,4.4,6l14,3.4c1.4,0.3,2.9,0.1,4.1-0.6l15.5-8.6c1.4-0.8,3.2-0.9,4.7-0.4l7.6,2.6c3.5,1.2,4.9,5.3,3,8.5l0,0c-2.1,3.4-0.2,7.8,3.7,8.7l4.2,0.9c0.6,0.1,1.3,0.2,1.9,0.1l45.3-5.2c0.7-0.1,1.4,0,2,0.1l48.5,11.9c2,0.5,3.6,2.1,4.2,4.1l13.1,47.6c0.8,3,3.8,4.8,6.8,4.1l60.5-13.1c1.1-0.2,2-0.7,2.8-1.5l10.1-9.8c1.1-1,2.5-1.6,4-1.6h36.1c1.4,0,2.8-0.5,3.9-1.5L472,137c1.2-1.1,1.9-2.7,1.9-4.3v-20.5c0-2.5-1.6-4.7-4-5.5l-56.2-18.5c-0.6-0.2-1.3-0.3-2-0.3l-102,3.7c-1.3,0-2.7-0.4-3.7-1.2l-42.2-32.6c-1.4-1.1-3.2-1.5-4.9-1l-26.7,6.6c-1.8,0.4-3.6,0-5-1.1l-16.9-13.9c-1.2-0.9-2.6-1.4-4.1-1.3l-16.6,1.3c-1.3,0.1-2.6-0.2-3.7-1L122.9,4c-1.7-1.2-4-1.4-5.9-0.4L77,23.8c-0.6,0.3-1.3,0.5-2,0.6l-27,2.8c-2.3,0.2-4.5-0.9-5.6-2.9l-3.5-6.2c-0.8-1.4-2.2-2.5-3.8-2.8l-4.5-0.9C27.6,13.8,24.7,15.5,23.8,18.3z',
        width: 477,
        height: 204.2,
    },
    duvernay: {
        path: 'M125,5l10.8-1.9c0.8-0.1,1.5-0.1,2.3,0.2l32.1,10.9c1.5,0.5,2.6,1.8,3,3.3l1.8,7.9c0.4,1.6,1.6,2.9,3.2,3.4l8.4,2.5c1,0.3,2.2,0.2,3.1-0.2l19.1-8.3c1-0.4,2.2-0.5,3.2-0.2l10.2,3.2c2.8,0.9,4,4.1,2.6,6.6v0c-1.7,3.2,0.7,7,4.3,6.8l59.9-3.3c0.4,0,0.8,0,1.1,0.1l48.2,9.4c1.7,0.3,3.1,1.6,3.5,3.3l13.7,49.9c0.6,2.2-0.5,4.5-2.6,5.4c-22.3,9.6-138.6,59.1-153.9,65.6c-0.9,0.4-1.9-0.1-2.2-1c-2.6-7.6-13.4-39.1-16.8-48.7c-0.6-1.7-4-2.8-4-3c-0.1-0.2-15.4-0.2-15.4-0.2s-16.9,33.7-22.3,43.9c-0.9,1.8-2.9,2.7-4.9,2.4l-6.5-1.2c-1.2-0.2-2.3-0.9-3-1.9l-2.7-3.9c-1.4-2-4.2-2.6-6.3-1.3l-36,22.9c-1.8,1.1-2.5,3.3-1.9,5.3l23.8,74.9c0.4,1.4,0.2,2.9-0.6,4.1l-36.8,51.5c-1,1.4-2.8,2.2-4.5,1.8l-6.1-1.1c-1.7-0.3-3.1-1.5-3.6-3.2l-29.6-96.5c-0.4-1.1-0.2-2.4,0.3-3.4l13-25.3c0.5-1,0.6-2.1,0.4-3.2l-10.4-42c-0.5-2.1-2.5-3.6-4.7-3.5l-1.6,0.1c-2,0.1-3.9-1.2-4.5-3.1L7.9,123c-0.5-1.5-0.2-3.2,0.8-4.4l3.8-4.6c1.1-1.3,1.3-3.1,0.6-4.7L3.4,86.7c-0.8-1.8-0.3-3.9,1.2-5.2l27.4-24.3c2.1-1.9,5.4-1.4,6.9,1l8.6,13.7c1.1,1.8,3.4,2.6,5.4,1.9l2-0.7c2.3-0.8,3.6-3.3,2.9-5.7l-7.5-24.8c-0.5-1.7,0-3.6,1.3-4.8l3.1-2.8c1.4-1.2,3.3-1.5,4.9-0.7L84,45.4c0.9,0.4,1.9,0.5,2.9,0.3l36.2-8.3c2.5-0.6,4-3.1,3.4-5.6l-5.2-21.3C120.7,8,122.4,5.4,125,5z',
        width: 358.5,
        height: 318.3,
    },
    auteuil: {
        path: 'M168.7,81.5l51,17.1c1.6,0.5,2.9,1.8,3.4,3.4l5.8,18.1c0.9,2.9-0.7,6-3.7,6.8l-18.2,5.2c-1,0.3-2.1,0.3-3.1-0.1l-34.6-11.3c-2.7-0.9-5.7,0.5-6.7,3.2l-3.1,8.1c-0.3,0.9-0.4,1.8-0.3,2.7l1,6.5c0.3,2.1-0.6,4.1-2.3,5.3l0,0c-1.4,0.9-3.1,1.2-4.7,0.6l-4.7-1.6c-1.8-0.6-3.7-0.2-5.2,1l-28.7,24.2c-0.8,0.7-1.4,1.6-1.7,2.6l-6.7,22.8c-0.5,1.8-2,3.2-3.8,3.7c-3.7,1-9.6,2.4-9.6,1.3c0-1.3-1.4-18.9-2.1-27.4c-0.2-2.6-2.2-4.6-4.8-4.9l-4.3-0.4c-2.1-0.2-4.1,0.8-5.1,2.6l-17.6,28.9c-1,1.6-2.7,2.6-4.6,2.6H43.6c-2.2,0-4.1-1.3-4.9-3.3L3.4,116.7c-0.7-1.6-0.6-3.4,0.3-4.8l18.5-31.3c0.4-0.7,1-1.3,1.7-1.8c9.7-6.2,82.4-52.9,83.5-53.3c1.1-0.4,23.3-3.9,30.4-5.1c1.4-0.2,2.7-1,3.5-2.2l8.5-11.9c1-1.4,2.5-2.2,4.2-2.3L181.8,3c3.8-0.1,6.5,3.5,5.3,7.1l-21.8,64.6C164.4,77.5,165.9,80.6,168.7,81.5z',
        width: 232.2,
        height: 205.5,
    },
    vimont: {
        path: 'M9.1,39.7L3.4,52.5c-0.4,1-0.5,2.1-0.1,3.1L30.6,138c0.7,2.1,3,3.4,5.1,2.8l2.3-0.6c1.8-0.5,3.2-2.1,3.3-4l0.5-9.4c0.1-2,1.5-3.6,3.4-4l21.3-4.4c2-0.4,4,0.6,4.8,2.4l13.8,30.5c0.7,1.5,2.1,2.5,3.8,2.5l4.4,0.2c1.8,0.1,3.5-1,4.2-2.7l3.4-8.2c0.6-1.4,1.8-2.4,3.3-2.6l8.6-1.4c2.7-0.5,4.3-3.3,3.3-5.9l-6.5-15.8c-0.5-1.1-0.4-2.4,0.1-3.4l12.6-27.6c0.4-0.9,0.5-1.8,0.3-2.8l-6.5-28c-0.5-2.2-2.7-3.7-4.9-3.3l-1.1,0.2c-2.3,0.4-4.5-1.2-5-3.5l-5.1-27.5c-0.4-2.3-2.6-3.8-4.9-3.5l-3.2,0.5c-1.9,0.3-3.4,1.8-3.6,3.7l-0.7,4.9c-0.3,1.9-1.7,3.4-3.6,3.7l0,0c-2.2,0.4-4.3-1-4.9-3.1L73.1,6.2c-0.5-2-2.4-3.3-4.4-3.2l-1,0.1c-1.6,0.1-2.9,1-3.6,2.4L51.5,31c-0.7,1.3-1.9,2.2-3.4,2.4l-35.5,3.8C11,37.3,9.7,38.2,9.1,39.7z',
        width: 125.5,
        height: 157.2,
    },
    'sainte-rose': {
        path: 'M101.4,10.1l31.2,71.4c0.6,1.4,0.9,2.8,1,4.3v0c0.2,5.5-3.5,10.5-8.8,11.9l-9.2,2.4c-3.7,1-6.7,3.6-8.1,7.2l-5.1,13c-1,2.6-1.1,5.4-0.2,8l9,27.1c1.8,5.5-0.6,11.4-5.7,14.1l-22.5,12c-1.7,0.9-3.6,1.4-5.5,1.4H62.5c-4.3,0-8.2-2.3-10.3-6l-15.8-28.2c-2.1-3.7-6-6-10.3-6h-0.8c-3.8,0-7.5-1.9-9.7-5l-10.5-15c-1.8-2.5-2.5-5.6-2-8.6c2.2-14.2,8.8-55.5,10.2-54.8c1.3,0.7,36-20,50-28.4c3.6-2.1,5.7-6,5.7-10.1v-3.7c0-5.5,3.8-10.2,9.1-11.5l9.8-2.3C93.4,2,99.1,4.9,101.4,10.1z',
        width: 136.6,
        height: 185.8,
    },
    'pont-viau': {
        path: 'M45.3,5l22.3,68.2c0.3,1,0.1,2.1-0.6,2.8c-5.1,5.8-26.2,30-26.6,28.5C40.2,103,22,46.7,18.8,36.9c-0.3-0.9-1-1.6-1.9-1.9l-9.4-2.8c-1-0.3-1.8-1.2-2-2.3L3.1,16.9c-0.3-1.7,0.9-3.3,2.6-3.4L21,12.2c1.2-0.1,2.4,0.6,2.9,1.7l1.9,4c0.8,1.6,2.7,2.2,4.2,1.2l0,0c1.1-0.7,1.6-2,1.3-3.2l-1.3-4.7c-0.4-1.4,0.3-2.9,1.6-3.4l9.8-4.4C43,2.5,44.8,3.4,45.3,5z',
        width: 70.8,
        height: 107.6,
    },
    chomedey: {
        path: 'M3.2,61.6c8.3,24.8,44.6,149.8,51.4,173c0.6,2.1,2.7,3.3,4.9,2.8l51.7-11.9c0.7-0.2,1.4-0.5,1.9-1l19.3-18.1c0.6-0.5,1.3-0.9,2.1-1l40.5-7.4c2.2-0.4,3.7-2.5,3.3-4.7l-12.8-73.5c-0.4-2.3,1.2-4.5,3.5-4.8l102.9-13.5c2.5-0.3,4.1-2.8,3.4-5.2l-2.7-9.8c-0.6-2,0.5-4.2,2.5-4.9l6.7-2.5c1.9-0.7,3.9,0,5,1.7v0c1.5,2.6,5.2,2.7,6.9,0.2l10.4-15c0.7-1,0.9-2.2,0.6-3.3l-1.8-7.3c-0.6-2.3-3-3.7-5.3-2.9l-24.6,8.5c-2.1,0.7-4.4-0.4-5.2-2.5l-5.1-13.9c-0.6-1.7-2.3-2.8-4.1-2.7l-23.5,1.4c-1.2,0.1-2.4,0.7-3.1,1.7c-2.4,3.4-7.9,10.9-7.2,10.6c0.5-0.3-3-0.5-5.7-0.7c-1.6-0.1-3.1-1.2-3.6-2.7c-2.8-7.9-10.9-31.3-15-43.3c-1.4-4.2-5.7-6.7-10.1-5.8c-16.3,3.2-54.2,10.5-63.3,12c-1.2,0.2-2.3,1-2.9,2.1l-4.6,8.6c-0.7,1.3-2.1,2.2-3.6,2.2L52,28.5c-2,0-3.7,1.5-4,3.5l-2,12.3c-0.3,1.6-1.5,3-3.1,3.3L6.2,56.3C3.8,56.9,2.5,59.3,3.2,61.6z',
        width: 307.5,
        height: 240.6,
    },
    fabreville: {
        path: 'M167,3.1l28.7,4.4c5.5,0.8,9.1,6.2,7.8,11.6l-11,47.3c-0.8,3.4,0.3,6.9,2.9,9.2l25.3,22.9c1.6,1.4,2.6,3.3,3,5.4l1.4,8c0.4,2,1.3,3.8,2.8,5.2l12.1,11.6c3.7,3.5,3.9,9.3,0.6,13.2l0,0c-1.8,2-4.3,3.2-7.1,3.3l-55.8,0.7c-4.2,0.1-7.8,2.8-9,6.8l-3.4,11.2c-1,3.4-3.9,6-7.4,6.6l-30.6,5.5c-2,0.4-4,0.1-5.8-0.8l-36.8-17.7c-2.5-1.2-5.5-1.3-8.1-0.1L49.1,170c-2.8,1.3-6,1.1-8.7-0.4l-24.2-14c-2.1-1.2-3.7-3.3-4.4-5.6l-8.4-29.5c-1.5-5.2,1.6-10.5,6.9-11.9l14.3-3.6c2.9-0.7,5.3-2.8,6.5-5.5l2.7-6.3c1.4-3.2,4.4-5.4,7.8-5.8l50-5c1.7-0.2,3.3-0.8,4.6-1.8L121.6,62c2-1.4,3.3-3.5,3.8-5.9l2.2-11.4c0.5-2.6,2.1-5,4.5-6.3l14.5-8.7c1.8-1.1,3.2-2.8,4-4.7l6.1-15.8C158.3,4.9,162.6,2.4,167,3.1z',
        width: 245.9,
        height: 179.2,
    },
    'sainte-dorothee': {
        path: 'M55.2,17.3c0.7-4.1,5.1-6.4,8.9-4.7c4.7,2.2,10.1,4.5,10.5,4.5c0.7,0,24.1-10.2,31.9-13.6c1.6-0.7,3.4-0.7,5-0.1L151,19.7c1.8,0.7,3.1,2.2,3.7,4.1l48.9,165.1c1.5,5.1-3.6,9.7-8.6,7.7L141.7,174c-1.6-0.7-2.9-2-3.5-3.6l-5.2-14.1c-0.6-1.7-1.9-3-3.6-3.7l-72.4-29.5c-1.4-0.6-2.5-1.6-3.2-2.8l-7.4-13.5c-0.7-1.4-1.9-2.4-3.4-2.9L9.8,91.7c-2.1-0.8-3.7-2.6-4.1-4.9L3.1,71.3c-0.5-3,1.2-5.9,4-7l49.9-19c3.1-1.2,4.8-4.6,3.8-7.8l-5.3-17.1C55.1,19.3,55.1,18.3,55.2,17.3L55.2,17.3z',
        width: 206.9,
        height: 200.1,
    },
    'laval-ouest': {
        path: 'M67.5,10.2L89,75.8c1.3,4-0.6,8.4-4.5,10.1l-44.3,19.8c-4.3,1.9-9.4-0.1-11.1-4.6l-0.7-1.9c-1.2-3.1-4.1-5.1-7.4-5.3l-10-0.4c-4.4-0.2-8-3.8-8-8.3v0c0-2.4,1-4.7,2.8-6.2l8.7-7.6c1.2-1.1,2.1-2.4,2.5-4L33.4,9c1.1-3.8,4.7-6.3,8.6-6l18.3,1.5C63.7,4.8,66.5,7,67.5,10.2z',
        width: 92.5,
        height: 109.4,
    },
    'laval-sur-le-lac': {
        path: 'M10.1,11.4C4.9,13.2,2,18.6,3.3,23.9c1.4,5.7,3.1,11.9,3.6,11.9c0.6,0,13.2,0.7,18.9,1.1c1.9,0.1,3.7,0.7,5.2,1.8l19,12.7c4.3,2.9,10.1,2.2,13.6-1.6l0,0c2.2-2.4,3.2-5.7,2.6-9L63,21.7c-0.5-3-2.3-5.6-4.9-7.2l-16.4-10c-2.7-1.6-6-2-8.9-1L10.1,11.4z',
        width: 69.5,
        height: 56.2,
    },
    'iles-laval': {
        path: 'M8.7,3.5L8.7,3.5c1.9-0.6,3.9-0.6,5.7,0.1l25.2,9.5c1.9,0.7,3.5,2.1,4.5,3.9l1.7,3.2c1.8,3.4,1.1,7.5-1.7,10.2l-12.8,12c-2.4,2.2-5.8,2.9-8.8,1.8l-6.9-2.6c-3-1.1-5.2-3.9-5.5-7.1L9.3,24.4c-0.2-1.7-0.9-3.4-2-4.7L5,17C1.2,12.4,3.1,5.4,8.7,3.5z',
        width: 49.8,
        height: 47.7,
    },
    'laval-des-rapides': {
        path: 'M3.25,30.66l11.31,51.81c1.05,4.81,5.31,8.24,10.23,8.23c6.57-0.01,14.69-0.17,15.43-0.9c0.82-0.82,5.78-7.03,9.1-11.22c1.94-2.44,4.86-3.89,7.98-3.96l32.21-0.68c1.74-0.04,3.46,0.36,5.01,1.16l7.31,3.76c2.64,1.36,5.74,1.53,8.52,0.47l9.55-3.65c5.14-1.96,7.88-7.56,6.28-12.82l-14.92-49.02c-1.57-5.15-6.78-8.27-12.06-7.21L86.26,9.22c-1.9,0.38-3.86,0.23-5.68-0.45L66.72,3.65c-1.72-0.64-3.58-0.81-5.39-0.5L37.27,7.26c-3.17,0.54-5.91,2.51-7.44,5.33l0,0c-1.86,3.43-5.47,5.54-9.37,5.48l-6.83-0.11C6.89,17.86,1.81,24.07,3.25,30.66z',
        width: 129.62,
        height: 93.7,
    },
};

const names = Object.keys(districtsMap);

const propTypes = {
    name: PropTypes.oneOf(names),
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    name: null,
    color: '#E95949',
    className: null,
};

const DistrictIcon = ({ name, color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={`${name !== null ? districtsMap[name].width : 1}px`}
        height={`${name !== null ? districtsMap[name].height : 1}px`}
        viewBox={`0 0 ${name !== null ? districtsMap[name].width : 1} ${name !== null ? districtsMap[name].height : 1}`}
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill="none"
            stroke={color}
            strokeWidth="6"
            strokeMiterlimit="10"
            d={name !== null ? districtsMap[name].path : ''}
        />
    </svg>
);

DistrictIcon.propTypes = propTypes;
DistrictIcon.defaultProps = defaultProps;

export default DistrictIcon;
