import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/partials/organisation.scss';

const messages = defineMessages({
    contact: {
        id: 'content.read_linked_contact',
        defaultMessage: 'Voir la fiche du répertoire',
    },
});

const propTypes = {
    organisation: AppPropTypes.eventOrganisation,
    className: PropTypes.string,
};

const defaultProps = {
    organisation: null,
    className: null,
};

const Organisation = ({ organisation, className }) => {
    const content = organisation !== null ? (
        <span className={styles.cols}>
            {organisation.logo !== null ? (
                <span className={classNames([styles.col, styles.colLogo])}>
                    <img
                        src={organisation.logo}
                        className={styles.logo}
                        alt={organisation.name}
                    />
                </span>
            ) : null}
            <span className={classNames([styles.col, styles.colLabel])}>
                <span className={styles.name}>{organisation.name}</span>
            </span>
        </span>
    ) : null;

    return (
        <div
            className={classNames({
                [styles.container]: true,
                [className]: className !== null,
            })}
        >
            {organisation.url !== null ? (
                <a
                    href={organisation.url}
                    className={styles.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {content}
                </a>
            ) : (
                content
            )}
            {organisation.linkedContactUrl ? (
                <Link className={styles.contact} to={organisation.linkedContactUrl}>
                    <FormattedMessage {...messages.contact} />
                </Link>
            ) : null}
        </div>
    );
};

Organisation.propTypes = propTypes;
Organisation.defaultProps = defaultProps;

export default Organisation;
