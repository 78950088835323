import Loadable from 'react-loadable';

export { default as Text } from './Text';
export { default as Image } from './Image';
export { default as Link } from './Link';
export { default as EventsCarousel } from './EventsCarousel';
export { default as MagazinesCarousel } from './MagazinesCarousel';
export { default as SuggestedEvents } from './SuggestedEvents';
export { default as SuggestedContacts } from './SuggestedContacts';
export { default as SuggestedMagazines } from './SuggestedMagazines';
export { default as Stats } from './Stats';
export { default as Quote } from './Quote';
export { default as Embed } from './Embed';
export { default as Logos } from './Logos';
export { default as Quizz } from './Quizz';
export { default as QuizResults } from './QuizResults';
export { default as Podcast } from './Podcast';

export const PhotosCarousel = Loadable({
    loader: () => import('./PhotosCarousel'),
    loading: () => null,
});

export const PhotosGrid = Loadable({
    loader: () => import('./PhotosGrid'),
    loading: () => null,
});

export const PhotosTimeline = Loadable({
    loader: () => import('./PhotosTimeline'),
    loading: () => null,
});
