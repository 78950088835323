import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Img from 'laravel-image';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedDate } from 'react-intl';

import PageStatus from '../partials/PageStatus';

import * as AppPropTypes from '../../lib/PropTypes';
import { getDateObject, addNonBreakingSpaces } from '../../lib/utils';
import messages from './messages';
import PlayIcon from '../icons/Play';

import styles from '../../../styles/cards/magazine-video.scss';

const propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    status: PropTypes.string,
    publishAt: PropTypes.string,
    image: AppPropTypes.image,
    video: AppPropTypes.embed,
    date: PropTypes.string,
    small: PropTypes.bool,
    withShadow: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    status: null,
    publishAt: null,
    image: null,
    video: null,
    date: null,
    small: false,
    withShadow: false,
    className: null,
};

const MagazineVideoCard = ({
    url,
    title,
    status,
    publishAt,
    image,
    video,
    date,
    small,
    withShadow,
    className,
}) => (
    <Link
        to={url}
        className={classNames([
            styles.container,
            {
                [styles.small]: small,
                [styles.withShadow]: withShadow,
                [className]: className !== null,
            },
        ])}
    >
        {status && status !== 'published' ? (
            <PageStatus className={styles.status} status={status} date={publishAt} />
        ) : null}
        {title !== null ? (
            <span className={styles.titleContainer}>
                <span className={styles.inner}>
                    <span className={styles.middle}>
                        <strong className={styles.title}>{addNonBreakingSpaces(title)}</strong>
                    </span>
                </span>
            </span>
        ) : null}

        {url !== null ? (
            <span className={styles.inner}>
                <span className={styles.middle}>
                    <span
                        className={styles.video}
                        style={{
                            backgroundImage:
                                video !== null && video.thumbnail !== null
                                    ? `url("${video.thumbnail.url}")`
                                    : null,
                        }}
                    >
                        <PlayIcon className={styles.icon} />
                    </span>
                </span>
            </span>
        ) : null}

        {title !== null && url != null ? (
            <span className={styles.action}>
                <span className={styles.button}>
                    <FormattedMessage {...messages.watchButton} />
                </span>
            </span>
        ) : null}

        {date !== null ? (
            <span className={styles.date}>
                <FormattedDate
                    value={getDateObject(date)}
                    format=""
                    month="long"
                    day="numeric"
                    year="numeric"
                />
            </span>
        ) : null}

        {image !== null ? (
            <span
                className={styles.image}
                style={{
                    backgroundImage: `url("${Img.url(image.url, {
                        // red: true,
                        purple: true,
                        small,
                        medium: !small,
                    })}")`,
                }}
            />
        ) : null}
        <span className={styles.border} />
    </Link>
);

MagazineVideoCard.propTypes = propTypes;
MagazineVideoCard.defaultProps = defaultProps;

export default MagazineVideoCard;
