/* eslint-disable react/jsx-no-target-blank, react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from '../../../styles/cards/event-group.scss';

const propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    letter: PropTypes.string,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    url: null,
    title: null,
    description: null,
    letter: 'A',
    isLoading: false,
    className: null,
};

const EventCard = ({
    url, title, description, letter, isLoading, className,
}) => (
    <Link
        to={url || '#'}
        className={classNames([
            styles.container,
            {
                // [styles.small]: small,
                [styles.isLoading]: isLoading,
                [className]: className !== null,
            },
        ])}
    >
        <div className={styles.letter}>
            <div className={styles.letterInner}>
                <div className={styles.lettrine}>{letter}</div>
            </div>
        </div>
        <div className={styles.details}>
            <strong className={styles.title}>{title}</strong>
            {description !== null ? (
                <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            ) : null}
        </div>
    </Link>
);

EventCard.propTypes = propTypes;
EventCard.defaultProps = defaultProps;

export default EventCard;
