import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const DistrictsIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="13.9px"
        height="13.9px"
        viewBox="0 0 13.9 13.9"
        xmlSpace="preserve"
        className={className}
    >
        <polygon fill={color} points="9.3,13.9 9.2,12.6 5.5,12.2 5.5,13.9" />
        <polygon fill={color} points="5.5,0 5.5,4.4 8.4,4.4 7.9,0" />
        <polygon fill={color} points="0,9.9 3.9,10.4 3.9,6 0,6" />
        <polygon fill={color} points="10,4.4 13.9,4.4 13.9,0 9.5,0" />
        <polygon fill={color} points="5.5,6 5.5,10.6 9,11 8.5,6" />
        <polygon fill={color} points="3.9,12 0,11.5 0,13.9 3.9,13.9" />
        <polygon fill={color} points="10.8,12.8 10.9,13.9 13.9,13.9 13.9,13.2" />
        <polygon fill={color} points="13.9,6 10.1,6 10.7,11.2 13.9,11.6" />
        <rect fill={color} width="3.9" height="4.4" />
    </svg>
);

DistrictsIcon.propTypes = propTypes;
DistrictsIcon.defaultProps = defaultProps;

export default DistrictsIcon;
