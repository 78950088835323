/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import { setMenuOpened as setMenuOpenedAction } from '../../actions/LayoutActions';

import Search from './Search';
import Logo from './Logo';
import Menu from '../menus/Menu';
import SearchIcon from '../icons/Search';
import Button from '../buttons/Button';
import BurgerButton from '../buttons/Burger';
import { addIcons as addSocialIcons } from '../icons/socialIcons';

import styles from '../../../styles/partials/top-bar.scss';

const propTypes = {
    logoLink: PropTypes.string,
    menuItems: AppPropTypes.menuItems,
    socialMenuItems: AppPropTypes.menuItems,
    withSearch: PropTypes.bool,
    gotoSearch: PropTypes.func.isRequired,
    menuOpened: PropTypes.bool.isRequired,
    openMenu: PropTypes.func.isRequired,
    closeMenu: PropTypes.func.isRequired,
};

const defaultProps = {
    logoLink: null,
    menuItems: [],
    socialMenuItems: [],
    withSearch: false,
};

class TopBar extends PureComponent {
    constructor(props) {
        super(props);

        this.onClickSearch = this.onClickSearch.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onSearchClear = this.onSearchClear.bind(this);
        this.onClickBurger = this.onClickBurger.bind(this);

        this.state = {
            searchOpened: false,
        };
    }

    onSearchSubmit(query) {
        const { gotoSearch } = this.props;
        gotoSearch(query);
    }

    onClickSearch() {
        this.setState(({ searchOpened }) => ({
            searchOpened: !searchOpened,
        }));
    }

    onSearchClear() {
        this.setState({
            searchOpened: false,
        });
    }

    onClickBurger() {
        const { menuOpened, closeMenu, openMenu } = this.props;
        if (menuOpened) {
            closeMenu();
        } else {
            openMenu();
        }
    }

    render() {
        const {
            logoLink, menuItems, socialMenuItems, withSearch,
        } = this.props;
        const { searchOpened } = this.state;

        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.searchOpened]: searchOpened,
                    },
                ])}
            >
                <div className={styles.cols}>
                    <div className={classNames([styles.col, styles.colLeft])}>
                        <div className={styles.logoContainer}>
                            <Link to={logoLink}>
                                <Logo className={styles.logo} />
                            </Link>
                        </div>
                    </div>
                    <div className={classNames([styles.col, styles.colCenter])}>
                        {withSearch ? (
                            <Search
                                onSubmit={this.onSearchSubmit}
                                className={styles.search}
                                inputClassName={styles.input}
                                focusedClassName={styles.focused}
                                clearButtonClassName={styles.clearButton}
                                onClear={this.onSearchClear}
                            />
                        ) : null}
                    </div>
                    <div className={classNames([styles.col, styles.colRight])}>
                        <div className={classNames([styles.cols])}>
                            <div className={classNames([styles.col, styles.colMenu])}>
                                <Menu
                                    items={menuItems}
                                    className={styles.menu}
                                    itemClassName={styles.item}
                                    currentClassName={styles.current}
                                    linkClassName={styles.link}
                                />
                            </div>
                            <div className={classNames([styles.col, styles.colSocial])}>
                                <Menu
                                    items={addSocialIcons(socialMenuItems, {
                                        className: styles.icon,
                                    })}
                                    className={styles.menuSocial}
                                    itemClassName={styles.item}
                                    currentClassName={styles.current}
                                    linkClassName={styles.link}
                                />
                                {withSearch ? (
                                    <Button
                                        icon={<SearchIcon className={styles.icon} fat />}
                                        className={styles.searchButton}
                                        transparent
                                        onClick={this.onClickSearch}
                                    />
                                ) : null}
                                <BurgerButton
                                    className={styles.burgerButton}
                                    onClick={this.onClickBurger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TopBar.propTypes = propTypes;
TopBar.defaultProps = defaultProps;

const mapStateToProps = null;
const mapDispatchToProps = (dispatch, { urlGenerator }) => ({
    openMenu: () => dispatch(setMenuOpenedAction(true)),
    closeMenu: () => dispatch(setMenuOpenedAction(false)),
    gotoSearch: query => dispatch(push(`${urlGenerator.route('search')}?q=${encodeURIComponent(query)}`)),
});
const WithStateContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopBar);
const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);

export default WithUrlGeneratorContainer;
