/**
 * Constants
 */
export const SET_ANIMATION_COMPLETE = 'home_splash@SET_ANIMATION_COMPLETE';

/**
 * Actions creator
 */
export const setAnimationComplete = value => ({
    type: SET_ANIMATION_COMPLETE,
    payload: value,
});
