/* eslint-disable react/no-danger, react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import {
    setResults as setResultsAction,
    setCount as setCountAction,
    clearQuiz as clearQuizAction,
} from '../../actions/QuizActions';

import Answer from './quiz/Answer';
import QuizButton from './quiz/QuizButton';

import * as AppPropTypes from '../../lib/PropTypes';
import { addNonBreakingSpaces } from '../../lib/utils';

import styles from '../../../styles/blocks/quizz.scss';

const propTypes = {
    id: PropTypes.number,
    question: PropTypes.string,
    answers: AppPropTypes.answers, // eslint-disable-line
    results: PropTypes.object, // eslint-disable-line
    quizType: PropTypes.string,
    isSection: PropTypes.bool,
    setResults: PropTypes.func,
    setCount: PropTypes.func,
    clearQuiz: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    id: null,
    question: null,
    answers: [],
    results: {},
    quizType: null,
    isSection: false,
    setResults: null,
    setCount: null,
    clearQuiz: null,
    className: null,
};

class Quizz extends PureComponent {
    constructor(props) {
        super(props);
        this.onClickAnswer = this.onClickAnswer.bind(this);
        this.state = {
            lastIndex: null,
        };
    }

    componentDidMount() {
        const { setCount } = this.props;
        if (setCount !== null && !this.isNeutral()) {
            setCount();
        }
    }

    componentWillUnmount() {
        const { clearQuiz } = this.props;
        if (clearQuiz !== null) {
            clearQuiz();
        }
    }

    onClickAnswer(e, it, index) {
        const { id, answers, setResults } = this.props;
        const { lastIndex } = this.state;
        if (setResults !== null && lastIndex === null && !this.isNeutral()) {
            setResults({ [id]: answers[index] ? answers[index].good : false });
        }
        this.setState(({ answerIndex }) => ({
            lastIndex: answerIndex !== index ? index : null,
        }));
    }

    isNeutral() {
        const { quizType } = this.props;
        return quizType === 'neutral';
    }

    renderQuestion() {
        const { question } = this.props;
        return !isEmpty(question) ? (
            <div className={styles.question}>
                <h4 className={styles.text}>{addNonBreakingSpaces(question)}</h4>
            </div>
        ) : null;
    }

    render() {
        const { answers, isSection, className } = this.props;
        const { lastIndex } = this.state;
        const neutral = this.isNeutral();
        const hasAnswered = !!answers[lastIndex]; // !!results[id] ||
        const hasImage = answers.some(answer => answer.image !== null);
        const selectedAnswer = hasAnswered ? answers[lastIndex] : null;

        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.isSection]: isSection,
                        [className]: className !== null,
                    },
                ])}
            >
                <div className={styles.inner}>
                    {this.renderQuestion()}
                    <div className={styles.answers}>
                        <ul className={styles.items}>
                            {answers.map((answer, index) => (
                                <QuizButton
                                    key={`quiz-button-${answer.id}-${index + 1}`}
                                    answer={answer}
                                    index={index}
                                    neutral={neutral}
                                    neutralAnswer={index === lastIndex}
                                    hasImage={hasImage}
                                    hasAnswered={(hasAnswered && !neutral) || !!selectedAnswer}
                                    onClick={this.onClickAnswer}
                                />
                            ))}
                        </ul>
                    </div>
                    {selectedAnswer ? (
                        <Answer selectedAnswer={selectedAnswer} neutral={neutral} />
                    ) : null}
                </div>
            </div>
        );
    }
}

Quizz.propTypes = propTypes;
Quizz.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ quiz }) => ({
        results: quiz ? quiz.results : {},
    }),
    dispatch => ({
        setResults: results => dispatch(setResultsAction(results)),
        setCount: count => dispatch(setCountAction(count)),
        clearQuiz: () => dispatch(clearQuizAction()),
    }),
)(Quizz);

export default WithStateContainer;
