import { useCallback, useEffect, useRef } from 'react';
import { Howl, Howler } from 'howler';

Howler.autoUnlock = true;

const useSound = (audioUrl = null, options = {}) => {
    const howlerRef = useRef(null);

    useEffect(() => {
        if (audioUrl !== null) {
            howlerRef.current = new Howl({
                src: [audioUrl],
                ...options,
            });
            howlerRef.current.load();
        }
        return () => {
            if (howlerRef.current) {
                howlerRef.current.unload();
                howlerRef.current = null;
            }
        };
    }, [audioUrl]);

    const play = useCallback(() => {
        if (howlerRef.current) {
            howlerRef.current.play();
        }
    }, [howlerRef]);

    const pause = useCallback(() => {
        if (howlerRef.current) {
            howlerRef.current.pause();
        }
    }, [howlerRef]);

    const stop = useCallback(() => {
        if (howlerRef.current) {
            howlerRef.current.stop();
        }
    }, [howlerRef]);

    const mute = useCallback(() => {
        if (howlerRef.current) {
            howlerRef.current.mute();
        }
    }, [howlerRef]);

    const changeVolume = useCallback(
        (vol) => {
            if (howlerRef.current) {
                howlerRef.current.volume(vol);
            }
            return null;
        },
        [howlerRef],
    );

    const seek = useCallback(
        (pos) => {
            if (howlerRef.current) {
                return howlerRef.current.seek(pos);
            }
            return 0;
        },
        [howlerRef],
    );

    const playing = useCallback(() => {
        if (howlerRef.current) {
            return howlerRef.current.playing();
        }
        return false;
    }, [howlerRef]);

    const duration = useCallback(() => {
        if (howlerRef.current) {
            return howlerRef.current.duration();
        }
        return 0;
    }, [howlerRef]);

    return {
        play,
        pause,
        stop,
        mute,
        changeVolume,
        seek,
        playing,
        duration,
        sound: howlerRef.current,
    };
};

export default useSound;
