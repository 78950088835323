/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { isMessage } from '../../lib/utils';
import ArrowIcon from '../icons/Arrow';
import styles from '../../../styles/blocks/link.scss';

const propTypes = {
    url: PropTypes.string,
    label: AppPropTypes.label,
    isSection: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    url: null,
    label: null,
    isSection: false,
    className: null,
};

const LinkBlock = ({
    url, label, isSection, className,
}) => (url !== null ? (
    <div
        className={classNames({
            [styles.container]: true,
            [styles.isSection]: isSection,
            [className]: className !== null,
        })}
    >
        <div className={styles.inner}>
            <a href={url} className={styles.link} target="_blank" rel="noopener noreferrer">
                <ArrowIcon className={styles.icon} />
                <span className={styles.label}>
                    {isMessage(label) ? <FormattedMessage {...label} /> : label || url}
                </span>
            </a>
        </div>
    </div>
) : null);

LinkBlock.propTypes = propTypes;
LinkBlock.defaultProps = defaultProps;

export default LinkBlock;
