import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const TwitterIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="16px"
        height="13px"
        viewBox="0 0 16 13"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            d="M16,1.5c-0.6,0.3-1.2,0.4-1.9,0.5c0.7-0.4,1.2-1,1.4-1.8c-0.6,0.4-1.3,0.6-2.1,0.8c-0.6-0.6-1.5-1-2.4-1C9.3,0,7.8,1.5,7.8,3.3c0,0.3,0,0.5,0.1,0.7C5.2,3.9,2.7,2.6,1.1,0.6c-0.3,0.5-0.4,1-0.4,1.7c0,1.1,0.6,2.1,1.5,2.7c-0.5,0-1-0.2-1.5-0.4c0,0,0,0,0,0c0,1.6,1.1,2.9,2.6,3.2C3,7.9,2.7,7.9,2.4,7.9c-0.2,0-0.4,0-0.6-0.1c0.4,1.3,1.6,2.3,3.1,2.3c-1.1,0.9-2.5,1.4-4.1,1.4c-0.3,0-0.5,0-0.8,0C1.5,12.5,3.2,13,5,13c6,0,9.3-5,9.3-9.3c0-0.1,0-0.3,0-0.4C15,2.8,15.6,2.2,16,1.5z"
        />
    </svg>
);

TwitterIcon.propTypes = propTypes;
TwitterIcon.defaultProps = defaultProps;

export default TwitterIcon;
