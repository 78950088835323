import React from 'react';
import FacebookIcon from './Facebook';
import InstagramIcon from './Instagram';
import TwitterIcon from './Twitter';
import YouTubeIcon from './YouTube';

const Icons = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    twitter: TwitterIcon,
    youtube: YouTubeIcon,
};

export const addIcons = (items, props) => items.map(({ label, ...it }) => {
    const Icon = Icons[label] || null;
    return {
        ...it,
        label:
            Icon !== null ? (
                <Icon {...props} />
            ) : null,
    };
});

export default Icons;
