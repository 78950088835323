/* eslint-disable indent */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import queryString from 'query-string';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import Button from '../buttons/Button';
import CardsList from './CardsList';
import Detector from './Detector';
import styles from '../../../styles/partials/contacts-list.scss';

import { withListLoader, listPropType } from '../../lib/withListLoader';

const messages = defineMessages({
    moreButton: {
        id: 'content.more_contacts_button',
        defaultMessage: 'Plus de contacts',
    },
    viewAll: {
        id: 'content.view_all_button',
        defaultMessage: 'Voir tout',
    },
    loadingMore: {
        id: 'content.more_button_loading',
        defaultMessage: 'Chargement...',
    },
    noResults: {
        id: 'content.no_results_crit',
        defaultMessage: 'Pas de résultats pour ces critères',
    },
});

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    list: listPropType.isRequired,
    page: AppPropTypes.page.isRequired,
    title: PropTypes.string,
    category: AppPropTypes.category,
    letter: PropTypes.string,
    loadNextPage: PropTypes.func.isRequired,
    loadable: PropTypes.bool,
    autoclose: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    category: null,
    letter: null,
    loadable: false,
    autoclose: false,
    className: null,
};

class ContactsList extends PureComponent {
    constructor(props) {
        super(props);
        this.onClickMore = this.onClickMore.bind(this);
    }

    onClickMore() {
        const { loadNextPage } = this.props;
        loadNextPage();
    }

    renderInner() {
        const { urlGenerator, list, page, title, category, letter, loadable } = this.props;
        const { items, total, currentPage, lastPage, isLoading } = list;

        // TODO: change these urls, everywhere...
        const categoryUrl =
            category !== null
                ? urlGenerator.route('pages.list.with_category', {
                      page: page.slug,
                      category: category.slug,
                  })
                : null;

        // const alphaUrl = letter !== null
        //     ? urlGenerator.route('pages.list.with_category', {
        //         page: page.slug,
        //         category: letter,
        //     })
        //     : null;

        const categoryName = category !== null ? category?.name || '' : null;
        const name = title !== null ? title : categoryName;

        return (
            <div className={styles.section}>
                <div className={styles.inner}>
                    {category !== null ? (
                        <h4 className={styles.title}>
                            <Link to={categoryUrl}>
                                <span className={styles.lettrine}>
                                    {name ? name.substr(0, 1) : 'a'}
                                </span>
                                <span className={styles.underline}>{name}</span>
                            </Link>
                        </h4>
                    ) : null}
                    {letter !== null ? (
                        <h4 className={styles.title}>
                            <span>
                                <span className={styles.lettrine}>{letter}</span>
                            </span>
                        </h4>
                    ) : null}
                    <div className={styles.list}>
                        <CardsList
                            items={loadable ? items : items.slice(0, 4)}
                            isLoading={total === 0 && isLoading}
                            loadingCards={4}
                            columns={4}
                            type="contact"
                        />
                    </div>
                    {loadable && currentPage < lastPage ? (
                        <div className={styles.loadMore}>
                            <Detector onEnter={this.onClickMore} disabled={isLoading}>
                                <Button red disabled={isLoading} onClick={this.onClickMore}>
                                    {isLoading ? messages.loadingMore : messages.moreButton}
                                </Button>
                            </Detector>
                        </div>
                    ) : null}
                    {!loadable && category !== null && total > 4 ? (
                        <div className={styles.loadMore}>
                            <Button href={categoryUrl} red>
                                {messages.viewAll}
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    render() {
        const { list, autoclose, className } = this.props;
        const { total, isLoading = false, pagesLoaded = 0 } = list;
        // console.log(autoclose, bottomReached, total);
        // if (autoclose && bottomReached && total === 0) return null;
        // console.log(list);
        const isEmpty = !isLoading && pagesLoaded > 0 && total === 0;
        const isHidden = autoclose && total === 0;

        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.hidden]: isHidden || isEmpty,
                        [styles.isLoading]: isLoading && !isEmpty && autoclose,
                        [className]: className !== null,
                    },
                ])}
            >
                {isHidden || isEmpty ? null : this.renderInner()}
                {isLoading === false && isEmpty && !autoclose ? (
                    <p className={classNames([styles.noResults])}>
                        <FormattedMessage {...messages.noResults} />
                    </p>
                ) : null}
            </div>
        );
    }
}

ContactsList.propTypes = propTypes;
ContactsList.defaultProps = defaultProps;

const WithListLoaderContainer = withListLoader(
    ({ urlGenerator, page, filters, category = null, letter = null }) => {
        let queryFilters = null;
        // console.log(filters);
        if (filters !== null) {
            queryFilters = filters;
        } else if (category !== null) {
            queryFilters = {
                disciplines: [category.id],
            };
        }
        if (letter !== null) {
            queryFilters = {
                ...queryFilters,
                letter,
            };
        }
        const query =
            queryFilters !== null && Object.keys(queryFilters).length > 0
                ? `?${queryString.stringify(queryFilters, {
                      arrayFormat: 'bracket',
                  })}`
                : '';
        const url = urlGenerator
            .route('pages.list', {
                page: page.slug,
            })
            .replace(/(\.json|\/)?$/, '.json');

        return `${url}${query}`;
    },
)(ContactsList);

const WithUrlGeneratorContainer = withUrlGenerator()(WithListLoaderContainer);

export default WithUrlGeneratorContainer;
