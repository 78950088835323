/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import * as AppPropTypes from '../../lib/PropTypes';

import TopBar from './TopBar';
import MainMenu from './MainMenu';

import styles from '../../../styles/partials/header.scss';

const propTypes = {
    homeLink: PropTypes.string.isRequired,
    topMenuItems: AppPropTypes.menuItems.isRequired,
    socialMenuItems: AppPropTypes.menuItems.isRequired,
    mainMenuItems: AppPropTypes.menuItems.isRequired,
    menuOpened: PropTypes.bool.isRequired,
};

const defaultProps = {};

const Header = ({
    homeLink, topMenuItems, socialMenuItems, mainMenuItems, menuOpened,
}) => (
    <div className={styles.container}>
        <div className={styles.topBar}>
            <TopBar
                logoLink={homeLink}
                menuItems={topMenuItems}
                socialMenuItems={socialMenuItems}
                menuOpened={menuOpened}
            />
        </div>
        <div className={styles.mainMenu}>
            <MainMenu
                logoLink={homeLink}
                menuItems={mainMenuItems}
                pagesMenuItems={topMenuItems}
                menuOpened={menuOpened}
            />
        </div>
    </div>
);

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
