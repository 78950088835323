import React from 'react';
// import PropTypes from 'prop-types';

import * as AppPropTypes from '../../lib/PropTypes';
import PageMeta from '../partials/PageMeta';
import Blocks from '../blocks/Blocks';
import HomeSplash from '../partials/HomeSplash';

import styles from '../../../styles/pages/home.scss';

const propTypes = {
    page: AppPropTypes.page.isRequired,
};

const HomePage = ({ page }) => (
    <div className={styles.container}>
        <PageMeta {...page.share} />
        <HomeSplash page={page} blocks={page.blocks.slice(0, 1)} />
        <Blocks page={page} blocks={page.blocks.slice(1)} blocksAreSections />
    </div>
);

HomePage.propTypes = propTypes;

export default React.memo(HomePage);
