/**
 * Constants
 */
export const SET_VIEW = 'magazines@SET_VIEW';
export const SET_FILTERS_OPTIONS = 'magazines@SET_FILTERS_OPTIONS';
export const SET_LIST = 'magazines@SET_LIST';

/**
 * Actions creator
 */

export const setView = view => ({
    type: SET_VIEW,
    payload: view,
});

export const setFiltersOptions = options => ({
    type: SET_FILTERS_OPTIONS,
    payload: options,
});

export const setList = data => ({
    type: SET_LIST,
    payload: data,
});
