import React from 'react';
import ReactDOM from 'react-dom';
import domready from 'domready';
import FastClick from 'fastclick';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';

import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fr';

import Root from './components/Root';

const getRootProps = () => {
    const propsEl = document.getElementById('root-props');
    return propsEl !== null ? JSON.parse(propsEl.innerHTML) || {} : {};
};

const renderRoot = (props) => {
    const rootEl = document.getElementById('root');
    const root = React.createElement(Root, props);
    ReactDOM.render(root, rootEl);
};

const boot = () => {
    FastClick.attach(document.body);
    const rootProps = getRootProps();
    // if (typeof window.Intl === 'undefined') {
    //     // const { locale = 'fr' } = rootProps;
    //     // import(`./vendor/polyfills/intl-${locale}`).then(() => renderRoot(rootProps));
    // } else {
    //     renderRoot(rootProps);
    // }
    renderRoot(rootProps);
};

const ready = (document.readyState || 'loading') !== 'loading';
if (ready) {
    boot();
} else {
    domready(boot);
}
