/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import { addNonBreakingSpaces, cleanExternalLinks } from '../../lib/utils';
import Blocks from '../blocks/Blocks';
import Button from '../buttons/Button';
import ArrowIcon from '../icons/Arrow';
import CloseButton from '../buttons/Close';

import styles from '../../../styles/partials/popup.scss';

const propTypes = {
    page: AppPropTypes.page,
    title: PropTypes.string,
    description: PropTypes.string,
    button: AppPropTypes.link,
    blocks: AppPropTypes.blocks,
    className: PropTypes.string,
    onClickClose: PropTypes.func,
};

const defaultProps = {
    page: null,
    title: null,
    description: null,
    button: null,
    blocks: null,
    className: null,
    onClickClose: null,
};

const Popup = ({
    page, title, description, button, blocks, className, onClickClose,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <div className={styles.inner}>
            {title !== null ? (
                <div className={classNames([styles.section, styles.titleSection])}>
                    <h1 className={styles.title}>{addNonBreakingSpaces(title)}</h1>
                </div>
            ) : null}

            <div className={classNames([styles.body])}>
                {description !== null ? (
                    <div className={classNames([styles.section, styles.textSection])}>
                        <div
                            className={styles.text}
                            dangerouslySetInnerHTML={{ __html: cleanExternalLinks(description) }}
                        />
                    </div>
                ) : null}

                {blocks !== null && blocks.length > 0 ? (
                    <div className={classNames([styles.section, styles.blocksSection])}>
                        <Blocks page={page} blocks={blocks} blocksAreSections />
                    </div>
                ) : null}

                {button !== null ? (
                    <div className={classNames([styles.section, styles.buttonSection])}>
                        <Button
                            href={button.url}
                            external
                            className={styles.button}
                            red
                            icon={<ArrowIcon className={styles.icon} />}
                            iconPosition="right"
                        >
                            {button.label}
                        </Button>
                    </div>
                ) : null}
            </div>
        </div>
        <CloseButton onClick={onClickClose} className={styles.closeButton} />
    </div>
);

Popup.propTypes = propTypes;
Popup.defaultProps = defaultProps;

export default React.memo(Popup);
