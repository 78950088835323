/* globals google: true */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { loadGoogleMaps } from '@folklore/services';

const propTypes = {
    markers: PropTypes.arrayOf(
        PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
            icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        }),
    ),
    apiKey: PropTypes.string.isRequired,
    locale: PropTypes.string,
    zoom: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    styles: PropTypes.arrayOf(PropTypes.object),
    width: PropTypes.number,
    height: PropTypes.number,
    tilt: PropTypes.number,
    mapType: PropTypes.string,
    draggable: PropTypes.bool,
    withoutUi: PropTypes.bool,
    withoutInteraction: PropTypes.bool,
    className: PropTypes.string,
    onReady: PropTypes.func,
    onZoomChange: PropTypes.func,
    onCenterChange: PropTypes.func,
    onBoundsChange: PropTypes.func,
    onMarkerClick: PropTypes.func,
    onMarkerMouseOver: PropTypes.func,
    onMarkerMouseOut: PropTypes.func,
};

const defaultProps = {
    markers: [],
    zoom: 4,
    latitude: 45.5,
    longitude: -73.3,
    locale: 'en',
    styles: null,
    width: null,
    height: null,
    mapType: 'roadmap',
    tilt: null,
    draggable: true,
    withoutUi: false,
    withoutInteraction: false,
    className: null,
    onReady: null,
    onZoomChange: null,
    onCenterChange: null,
    onBoundsChange: null,
    onMarkerClick: null,
    onMarkerMouseOver: null,
    onMarkerMouseOut: null,
};

class Map extends Component {
    constructor(props) {
        super(props);

        this.createMap = this.createMap.bind(this);
        this.updateMarker = this.updateMarker.bind(this);
        this.onZoomChange = this.onZoomChange.bind(this);
        this.onCenterChange = this.onCenterChange.bind(this);
        this.onBoundsChange = this.onBoundsChange.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onMarkerMouseOver = this.onMarkerMouseOver.bind(this);
        this.onMarkerMouseOut = this.onMarkerMouseOut.bind(this);
        this.refMap = null;
        this.map = null;
        this.markers = [];
        this.markersListeners = [];
        this.zoomChangedListener = null;
        this.centerChangedListener = null;
        this.boundsChangedListener = null;

        this.state = {
            ready: false,
        };
    }

    componentDidMount() {
        const { apiKey, locale } = this.props;
        loadGoogleMaps({
            apiKey,
            locale,
        })
            .then(this.createMap)
            .then(() => this.setState({
                ready: true,
            }));
    }

    componentDidUpdate(
        {
            zoom: prevZoom,
            tilt: prevTilt,
            latitude: prevLatitude,
            longitude: prevLongitude,
            markers: prevMarkers,
        },
        { ready: prevReady },
    ) {
        const {
            zoom, tilt, latitude, longitude, markers, onReady,
        } = this.props;
        const { ready } = this.state;
        const readyChanged = prevReady !== ready;
        if (readyChanged && ready && onReady !== null) {
            onReady();
        }

        const zoomChanged = prevZoom !== zoom;
        if (this.map && zoomChanged) {
            this.map.setZoom(zoom);
        }

        const tiltChanged = prevTilt !== tilt;
        if (this.map && tiltChanged) {
            this.map.setTilt(tilt);
        }

        const centerChanged = prevLatitude !== latitude || prevLongitude !== longitude;
        if (this.map && centerChanged) {
            this.map.setCenter(new google.maps.LatLng(latitude, longitude));
        }

        const markersChanged = prevMarkers !== markers;
        if ((markersChanged || readyChanged) && ready) {
            this.updateMarkers();
        }
    }

    componentWillUnmount() {
        this.markers.forEach((marker, index) => this.destroyMarker(marker, index));
        this.removeMapListeners();
    }

    onBoundsChange() {
        const { onBoundsChange } = this.props;
        if (onBoundsChange !== null) {
            onBoundsChange();
        }
    }

    onZoomChange() {
        const { onZoomChange } = this.props;
        if (onZoomChange !== null) {
            onZoomChange();
        }
    }

    onCenterChange() {
        const { onCenterChange } = this.props;
        if (onCenterChange !== null) {
            onCenterChange();
        }
    }

    onMarkerClick(marker, index) {
        const { markers, onMarkerClick } = this.props;
        if (onMarkerClick) {
            onMarkerClick(marker, markers[index], index);
        }
    }

    onMarkerMouseOver(marker, index) {
        const { markers, onMarkerMouseOver } = this.props;
        if (onMarkerMouseOver) {
            onMarkerMouseOver(marker, markers[index], index);
        }
    }

    onMarkerMouseOut(marker, index) {
        const { markers, onMarkerMouseOut } = this.props;
        if (onMarkerMouseOut) {
            onMarkerMouseOut(marker, markers[index], index);
        }
    }

    createMap() {
        const {
            latitude,
            longitude,
            zoom,
            tilt,
            styles,
            mapType,
            draggable,
            withoutUi,
            withoutInteraction,
        } = this.props;

        const center = new google.maps.LatLng(latitude, longitude);
        // Create map
        const options = {
            center,
            zoom,
            styles,
            disableDefaultUI: withoutUi,
            disableDoubleClickZoom: !withoutInteraction,
            draggable: draggable && !withoutInteraction,
            scrollwheel: !withoutInteraction,
            clickableIcons: !withoutInteraction,
            mapTypeId: mapType,
        };
        this.map = new google.maps.Map(this.refMap, options);
        if (tilt !== null) {
            this.map.setTilt(tilt);
        }

        this.addMapListeners();

        this.updateMarkers();

        const promises = [];
        promises.push(
            new Promise((resolve) => {
                const listener = this.map.addListener('tilesloaded', () => {
                    google.maps.event.removeListener(listener);
                    resolve();
                });
            }),
        );
        promises.push(
            new Promise((resolve) => {
                const listener = this.map.addListener('projection_changed', () => {
                    google.maps.event.removeListener(listener);
                    resolve();
                });
            }),
        );
        return Promise.all(promises).then(() => this.map);
    }

    addMapListeners() {
        this.zoomChangedListener = this.map.addListener('zoom_changed', this.onZoomChange);
        this.centerChangedListener = this.map.addListener('center_changed', this.onCenterChange);
        this.boundsChangedListener = this.map.addListener('bounds_changed', this.onBoundsChange);
    }

    removeMapListeners() {
        if (this.zoomChangedListener !== null) {
            google.maps.event.removeListener(this.zoomChangedListener);
            this.zoomChangedListener = null;
        }
        if (this.centerChangedListener !== null) {
            google.maps.event.removeListener(this.centerChangedListener);
            this.centerChangedListener = null;
        }
        if (this.boundsChangedListener !== null) {
            google.maps.event.removeListener(this.boundsChangedListener);
            this.boundsChangedListener = null;
        }
    }

    updateMarkers() {
        const { markers } = this.props;
        const newMarkers = markers.map(this.updateMarker);

        // Clear unused markers
        const currentMarkersCount = this.markers.length;
        const newMarkersCount = newMarkers.length;
        if (currentMarkersCount > newMarkers.length) {
            for (let i = newMarkersCount; i < currentMarkersCount; i += 1) {
                this.destroyMarker(this.markers[i], i);
            }
        }

        this.markers = newMarkers;
    }

    updateMarker(data, index) {
        const { latitude, longitude, icon } = data;
        const currentMarker = get(this.markers, index, null);
        const position = new google.maps.LatLng(latitude, longitude);
        const marker = currentMarker
            || new google.maps.Marker({
                map: this.map,
            });
        if (currentMarker === null) {
            this.addMarkerListeners(marker, index);
        }
        marker.setPosition(position);
        marker.setIcon(icon || null);
        return marker;
    }

    destroyMarker(marker, index) {
        this.removeMarkerListeners(marker, index);
        marker.setMap(null);
    }

    addMarkerListeners(marker, index) {
        this.markersListeners[index] = {
            click: marker.addListener('click', () => this.onMarkerClick(marker, index)),
            mouseover: marker.addListener('mouseover', () => this.onMarkerMouseOver(marker, index)),
            mouseout: marker.addListener('mouseout', () => this.onMarkerMouseOut(marker, index)),
        };
    }

    removeMarkerListeners(marker, index) {
        const currentListeners = this.markersListeners[index] || null;
        if (currentListeners !== null) {
            Object.keys(currentListeners).forEach((event) => {
                google.maps.event.removeListener(currentListeners[event]);
            });
            this.markersListeners[index] = null;
        }
    }

    render() {
        const { width, height, className } = this.props;
        const style = {
            position: 'relative',
            width,
            height,
        };
        const mapStyle = {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        };
        return (
            <div
                className={classNames({
                    [className]: className !== null,
                })}
                style={style}
            >
                <div
                    style={mapStyle}
                    ref={(ref) => {
                        this.refMap = ref;
                    }}
                />
            </div>
        );
    }
}

Map.propTypes = propTypes;
Map.defaultProps = defaultProps;

export default Map;
