/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import Img from 'laravel-image';
import Loadable from 'react-loadable';
import { push } from 'connected-react-router';

import * as AppPropTypes from '../../lib/PropTypes';
import PageMeta from '../partials/PageMeta';
import BackBar from '../partials/BackBar';
import Blocks from '../blocks/Blocks';
import PageHeader from '../partials/PageHeader';
import MapVisual from '../partials/MapVisual';
import DistrictDetails from '../partials/DistrictDetails';
import Share from '../partials/Share';

import styles from '../../../styles/pages/district.scss';

const DistrictsMap = Loadable({
    loader: () => import('../partials/DistrictsMap'),
    loading: () => null,
});

const messages = defineMessages({
    viewAll: {
        id: 'content.view_all_districts',
        defaultMessage: '← Voir tous les quartiers',
    },
    eventsTitle: {
        id: 'content.district_events_title',
        defaultMessage: 'Événements dans ce quartier',
    },
});

const propTypes = {
    page: AppPropTypes.page.isRequired,
    gotoDistrict: PropTypes.func.isRequired,
};

const defaultProps = {};

const DistrictPage = ({ page, gotoDistrict }) => (
    <div className={styles.container}>
        <PageMeta {...page.share} />
        <BackBar url={page.parent.url} label={messages.viewAll} />
        <div className={styles.inner}>
            <PageHeader
                title={page.title}
                right={
                    <div className={styles.mapContainer}>
                        <div className={styles.mapSizer}>
                            <DistrictsMap
                                className={styles.map}
                                withoutText
                                highlightedDistrict={page.handle}
                                onClickDistrict={(e, slug) => gotoDistrict(slug)}
                            />
                        </div>
                    </div>
                }
                className={styles.header}
                titleClassName={styles.title}
                colLeftClassName={styles.colLeft}
                colRightClassName={styles.colRight}
            />
            <MapVisual
                name={page.title}
                image={
                    page.image !== null
                        ? {
                              url: Img.url(page.image.url, {
                                  blue: true,
                                  large: true,
                              }),
                          }
                        : null
                }
                className={styles.visual}
                colLeftClassName={styles.colLeft}
                colRightClassName={styles.colRight}
            />
            <div className={styles.content}>
                <div className={styles.cols}>
                    <div className={classNames([styles.col, styles.colLeft])}>
                        <Blocks
                            page={page}
                            blocks={[
                                page.description !== null
                                    ? {
                                          id: -1,
                                          type: 'text',
                                          text: page.description,
                                      }
                                    : null,
                                ...(page.blocks || []),
                            ].filter((it) => it !== null)}
                        />
                    </div>
                    <div className={classNames([styles.col, styles.colRight])}>
                        <DistrictDetails {...page} className={styles.details} />
                    </div>
                </div>
                <div className={styles.blocks}>
                    <Blocks
                        page={page}
                        blocks={[
                            {
                                id: `district-${page.id}`,
                                type: 'suggested_events',
                                title: <FormattedMessage {...messages.eventsTitle} />,
                                withoutFeatured: true,
                                newWindow: true,
                                white: true,
                            },
                        ]}
                    />
                </div>
            </div>
            <Share type={page.type} {...page.share} className={styles.share} isSection />
        </div>
    </div>
);

DistrictPage.propTypes = propTypes;
DistrictPage.defaultProps = defaultProps;

const WithStateContainer = connect(null, (dispatch, { page }) => ({
    gotoDistrict: (slug) => {
        const district = page.parent.districts.find((it) => it.handle === slug) || null;
        if (district !== null) {
            dispatch(push(district.url));
        }
    },
}))(DistrictPage);

export default WithStateContainer;
