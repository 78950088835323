import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    good: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    good: false,
    color: 'currentColor',
    className: null,
};

const AnswerIcon = ({ good, color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="24px"
        height="22.75px"
        viewBox="0 0 24 22.75"
        xmlSpace="preserve"
        className={className}
    >
        {good ? (
            <polyline
                fill="none"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="2,13.25 10,20.75 22,2 "
            />
        ) : (
            <g>
                <line
                    fill="none"
                    stroke={color}
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="4.5"
                    y1="3.88"
                    x2="19.5"
                    y2="18.88"
                />
                <line
                    fill="none"
                    stroke={color}
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="4.5"
                    y1="18.88"
                    x2="19.5"
                    y2="3.88"
                />
            </g>
        )}
    </svg>
);

AnswerIcon.propTypes = propTypes;
AnswerIcon.defaultProps = defaultProps;

export default AnswerIcon;
