import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import * as AppPropTypes from '../../lib/PropTypes';
import Menu from './Menu';

import styles from '../../../styles/menus/columns.scss';

const propTypes = {
    columns: AppPropTypes.menuColumns,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
    colsClassName: PropTypes.string,
    colClassName: PropTypes.string,
    menuClassName: PropTypes.string,
    itemsClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    currentClassName: PropTypes.string,
    spacerClassName: PropTypes.string,
    linkClassName: PropTypes.string,
};

const defaultProps = {
    columns: [],
    className: null,
    titleClassName: null,
    colsClassName: null,
    colClassName: null,
    menuClassName: null,
    itemsClassName: null,
    itemClassName: null,
    currentClassName: null,
    spacerClassName: null,
    linkClassName: null,
};

const ColumnsMenu = ({
    columns,
    className,
    titleClassName,
    colsClassName,
    colClassName,
    menuClassName,
    itemsClassName,
    itemClassName,
    currentClassName,
    spacerClassName,
    linkClassName,
}) => (
    <div
        className={classNames({
            [styles.container]: true,
            [className]: className !== null,
        })}
    >
        <div
            className={classNames([
                styles.cols,
                {
                    [colsClassName]: colsClassName !== null,
                },
            ])}
        >
            {columns.map(({ title = null, url = null, items }, index) => (
                <div
                    className={classNames([
                        styles.col,
                        {
                            [colClassName]: colClassName !== null,
                        },
                    ])}
                    key={`col-${title || index}`}
                >
                    <div className={styles.inner}>
                        {title !== null ? (
                            <h4
                                className={classNames([
                                    styles.title,
                                    {
                                        [titleClassName]: titleClassName !== null,
                                    },
                                ])}
                            >
                                {url !== null ? (
                                    <Link to={url}>{title}</Link>
                                ) : title}
                            </h4>
                        ) : null}
                        <div className={styles.menuContainer}>
                            <Menu
                                items={items}
                                className={classNames([
                                    styles.menu,
                                    {
                                        [menuClassName]: menuClassName !== null,
                                    },
                                ])}
                                itemsClassName={classNames([
                                    styles.items,
                                    {
                                        [itemsClassName]: itemsClassName !== null,
                                    },
                                ])}
                                itemClassName={classNames([
                                    styles.item,
                                    {
                                        [itemClassName]: itemClassName !== null,
                                    },
                                ])}
                                spacerClassName={classNames([
                                    styles.spacer,
                                    {
                                        [spacerClassName]: spacerClassName !== null,
                                    },
                                ])}
                                linkClassName={classNames([
                                    styles.link,
                                    {
                                        [linkClassName]: linkClassName !== null,
                                    },
                                ])}
                                currentClassName={classNames([
                                    styles.current,
                                    {
                                        [currentClassName]: currentClassName !== null,
                                    },
                                ])}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

ColumnsMenu.propTypes = propTypes;
ColumnsMenu.defaultProps = defaultProps;

export default ColumnsMenu;
