/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { addNonBreakingSpaces, cleanExternalLinks } from '../../lib/utils';
import styles from '../../../styles/blocks/text.scss';

const propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    isSummary: PropTypes.bool,
    isSection: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    subtitle: null,
    text: null,
    isSummary: false,
    isSection: false,
    className: null,
};

const TextBlock = ({
    title, subtitle, text, isSummary, isSection, className,
}) => (
    <div
        className={classNames({
            [styles.container]: true,
            [styles.isSummary]: isSummary,
            [styles.isSection]: isSection,
            [className]: className !== null,
        })}
    >
        <div className={styles.inner}>
            {title !== null ? (
                <h2
                    className={classNames([
                        styles.title,
                        {
                            [styles.withSubtitle]: subtitle !== null,
                        },
                    ])}
                >
                    {addNonBreakingSpaces(title)}
                </h2>
            ) : null}
            {subtitle !== null ? <h3 className={styles.subtitle}>{subtitle}</h3> : null}
            {text !== null ? (
                <div
                    className={styles.text}
                    dangerouslySetInnerHTML={{ __html: cleanExternalLinks(text) }}
                />
            ) : null}
        </div>
    </div>
);

TextBlock.propTypes = propTypes;
TextBlock.defaultProps = defaultProps;

export default TextBlock;
