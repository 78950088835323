import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    checkColor: PropTypes.string,
    checked: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    checkColor: '#E95949',
    checked: false,
    className: null,
};

const CheckIcon = ({
    color, checkColor, checked, className,
}) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="11.8px"
        height="12.1px"
        viewBox="0 0 11.8 12.1"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            d="M7.2,3.1c1,0,1.8,0.8,1.8,1.8v4.4c0,1-0.8,1.8-1.8,1.8H2.8c-1,0-1.8-0.8-1.8-1.8V4.1c0-0.6,0.4-1,1-1H7.2M7.2,2.1H2c-1.1,0-2,0.9-2,2v5.2c0,1.5,1.3,2.8,2.8,2.8h4.4c1.5,0,2.8-1.3,2.8-2.8V4.9C10,3.3,8.7,2.1,7.2,2.1L7.2,2.1z"
        />
        <polyline
            fill="none"
            stroke={checkColor}
            strokeWidth="2"
            strokeMiterlimit="10"
            opacity={checked ? 1 : 0}
            points="3,5.9 5.7,8.6 11,0.6"
        />
    </svg>
);
CheckIcon.propTypes = propTypes;
CheckIcon.defaultProps = defaultProps;

export default CheckIcon;
