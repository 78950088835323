import { SET_RESULTS, SET_COUNT, CLEAR_QUIZ } from '../actions/QuizActions';

const initialState = {
    results: {},
    count: 0,
};

const QuizReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_RESULTS:
        return {
            ...state,
            results: {
                ...state.results,
                ...action.payload,
            },
        };
    case SET_COUNT:
        return {
            ...state,
            count: state.count + 1,
        };
    case CLEAR_QUIZ: {
        return {
            ...state,
            results: {},
            count: 0,
        };
    }
    default:
        return state;
    }
};

export default QuizReducer;
