/* eslint-disable indent */
import React from 'react';
// import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import PageMeta from '../partials/PageMeta';
import BackBar from '../partials/BackBar';
import PageHeader from '../partials/PageHeader';
import MapVisual from '../partials/MapVisual';
import ContactDetails from '../partials/ContactDetails';
import Blocks from '../blocks/Blocks';

import styles from '../../../styles/pages/contact.scss';

const messages = defineMessages({
    viewAll: {
        id: 'content.view_all_contacts',
        defaultMessage: '← Voir tous les acteurs culturels',
    },
});

const propTypes = {
    page: AppPropTypes.contactPage.isRequired,
};

const defaultProps = {};

const ContactPage = ({ page }) => {
    const blocks = page.blocks.map((it) =>
        it.type === 'photos_carousel'
            ? {
                  ...it,
                  opposite: true,
              }
            : it,
    );
    const firstBlocks = blocks.slice(0, 1);
    const otherBlocks = blocks.slice(1);
    const hasDetails =
        page.address !== null ||
        page.contact !== null ||
        page.links.length > 0 ||
        page.districts.length > 0 ||
        page.categories.length > 0;

    return (
        <div className={styles.container}>
            <PageMeta {...page.share} />
            <BackBar url={page.parent.url} label={messages.viewAll} />
            <div className={styles.inner}>
                <PageHeader
                    title={page.title}
                    className={styles.header}
                    titleClassName={styles.title}
                    colLeftClassName={styles.colLeft}
                    colRightClassName={styles.colRight}
                />
                <MapVisual
                    image={page.image}
                    latitude={page.address !== null ? page.address.latitude || null : null}
                    longitude={page.address !== null ? page.address.longitude || null : null}
                    className={styles.visual}
                    colLeftClassName={styles.colLeft}
                    colRightClassName={styles.colRight}
                />
                <div className={styles.content}>
                    <div className={styles.cols}>
                        <div className={classNames([styles.col, styles.colLeft])}>
                            <Blocks page={page} blocks={firstBlocks} className={styles.blocks} />
                        </div>
                        {hasDetails ? (
                            <div className={classNames([styles.col, styles.colRight])}>
                                <ContactDetails {...page} className={styles.details} />
                            </div>
                        ) : null}
                    </div>
                    <Blocks page={page} blocks={otherBlocks} className={styles.blocks} />
                </div>
            </div>
        </div>
    );
};

ContactPage.propTypes = propTypes;
ContactPage.defaultProps = defaultProps;

export default React.memo(ContactPage);
