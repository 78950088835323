import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loadable from 'react-loadable';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import Search from './Search';
import Toggles from '../buttons/Toggles';
import FiltersBar from './FiltersBar';
import Button from '../buttons/Button';
import CloseIcon from '../icons/Close';
import { setFiltersOptions as setFiltersOptionsAction } from '../../actions/EventsActions';
import { loadFiltersOptions } from '../../lib/requests';

import styles from '../../../styles/partials/events-filters.scss';
import togglesStyles from '../../../styles/buttons/toggles.scss';

const Calendar = Loadable({
    loader: () => import('./Calendar'),
    loading: () => null,
});

const DateRange = Loadable({
    loader: () => import('./Calendar').then(({ DateRange: DateRangeComponent }) => DateRangeComponent),
    loading: () => null,
});

const messages = defineMessages({
    today: {
        id: 'content.filters_today',
        defaultMessage: 'Aujourd’hui',
    },
    thisWeek: {
        id: 'content.filters_week',
        defaultMessage: 'Cette semaine',
    },
    thisMonth: {
        id: 'content.filters_month',
        defaultMessage: 'Ce mois-ci',
    },
    custom: {
        id: 'content.filters_custom',
        defaultMessage: 'Personnalisé',
    },
});

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    timeSpanOptions: AppPropTypes.toggleOptions,
    filtersOptions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: AppPropTypes.label,
            options: AppPropTypes.toggleOptions,
        }),
    ),
    value: PropTypes.shape({
        timeSpan: PropTypes.string,
    }),
    setFiltersOptions: PropTypes.func.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

const defaultProps = {
    timeSpanOptions: [
        {
            value: 'today',
            label: messages.today,
        },
        {
            value: 'week',
            label: messages.thisWeek,
        },
        {
            value: 'month',
            label: messages.thisMonth,
        },
        {
            value: 'custom',
            label: messages.custom,
        },
    ],
    filtersOptions: null,
    value: null,
    className: null,
    onChange: null,
};

class EventsFilters extends PureComponent {
    static getDerivedStateFromProps({ value }, { timeSpan: prevTimeSpan }) {
        const timeSpan = value !== null ? value.timeSpan || null : null;
        const timeSpanChanged = timeSpan !== prevTimeSpan;
        if (timeSpanChanged) {
            const matches = (timeSpan || '').match(
                /custom:([0-9]{4}-[0-9]{2}-[0-9]{2})?,([0-9]{4}-[0-9]{2}-[0-9]{2})?$/,
            );
            const startDate = matches !== null ? matches[1] || null : null;
            const endDate = matches !== null ? matches[2] || null : null;
            const calendarValue = startDate !== null || endDate !== null
                ? {
                    start: startDate,
                    end: endDate,
                }
                : null;
            return {
                timeSpan,
                timeSpanValue: (timeSpan || '').replace(/^custom(:.*)$/, 'custom'),
                calendarValue,
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.onFiltersOptionsLoaded = this.onFiltersOptionsLoaded.bind(this);
        this.onTimeSpanChange = this.onTimeSpanChange.bind(this);
        this.onFiltersChange = this.onFiltersChange.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onSearchClear = this.onSearchClear.bind(this);
        this.onCalendarChange = this.onCalendarChange.bind(this);
        this.onCalendarClickOutside = this.onCalendarClickOutside.bind(this);
        this.onClickCustomClear = this.onClickCustomClear.bind(this);

        this.state = {
            timeSpan: null, // eslint-disable-line react/no-unused-state
            timeSpanValue: null,
            calendarOpened: false,
            calendarValue: null,
        };
    }

    componentDidMount() {
        const { filtersOptions } = this.props;
        if (filtersOptions === null) {
            this.loadFilters();
        }
    }

    onFiltersOptionsLoaded(filters) {
        const { setFiltersOptions } = this.props;
        setFiltersOptions(filters);
    }

    onTimeSpanChange(timeSpan) {
        const { value, onChange } = this.props;
        const { calendarOpened } = this.state;
        const oldTimeSpan = value !== null ? value.timeSpan || null : null;

        if (
            timeSpan === 'custom'
            || (oldTimeSpan !== null
                && oldTimeSpan.match(/^custom(:.*)$/) !== null
                && timeSpan === null)
        ) {
            this.setState({
                calendarOpened: !calendarOpened,
            });
            return;
        }

        if (calendarOpened) {
            this.setState({
                calendarOpened: false,
            });
        }

        const newValue = oldTimeSpan !== timeSpan
            ? {
                ...value,
                timeSpan,
            }
            : { timeSpan: null };
        if (onChange !== null) {
            onChange(newValue);
        }
    }

    onFiltersChange(filters) {
        const { value, onChange } = this.props;
        const newValue = {
            ...value,
            ...filters,
        };
        if (onChange !== null) {
            onChange(newValue);
        }
    }

    onSearchSubmit(e, query) {
        const { value, onChange } = this.props;
        e.preventDefault();
        const newValue = {
            ...value,
            q: query,
        };
        if (onChange !== null) {
            onChange(newValue);
        }
    }

    onSearchClear() {
        const { value, onChange } = this.props;
        const newValue = {
            ...value,
            q: null,
        };
        if (onChange !== null) {
            onChange(newValue);
        }
    }

    onClickCustomClear() {
        const { value, onChange } = this.props;
        const { calendarOpened } = this.state;
        const newValue = {
            ...value,
            timeSpan: null,
        };

        if (calendarOpened) {
            this.setState({
                calendarOpened: false,
            });
        }

        if (onChange !== null) {
            onChange(newValue);
        }
    }

    // eslint-disable-next-line
    onCalendarChange({ start, end }) {
        const { value, onChange } = this.props;
        const newValue = {
            ...value,
            timeSpan: `custom:${start || ''},${end || ''}`,
        };
        if (onChange !== null) {
            onChange(newValue);
        }
    }

    onCalendarClickOutside() {
        this.setState({
            calendarOpened: false,
        });
    }

    loadFilters() {
        const { urlGenerator } = this.props;
        const url = urlGenerator.route('api.events_filters');
        loadFiltersOptions(url).then(this.onFiltersOptionsLoaded);
    }

    renderCalendar() {
        const { calendarValue } = this.state;
        return (
            <Calendar
                value={calendarValue}
                onChange={this.onCalendarChange}
                className={styles.calendar}
                onClickOutside={this.onCalendarClickOutside}
            />
        );
    }

    // eslint-disable-next-line
    renderCustomLabel() {
        const { calendarValue } = this.state;
        if (calendarValue === null) {
            return messages.custom;
        }
        const { start = null, end = null } = calendarValue;
        if (start === null && end === null) {
            return messages.custom;
        }
        return <DateRange start={start} end={end} />;
    }

    // eslint-disable-next-line
    renderClearButton() {
        return (
            <Button
                className={classNames([togglesStyles.button, styles.button, styles.closeButton])}
                onClick={this.onClickCustomClear}
            >
                <CloseIcon className={styles.closeIcon} />
            </Button>
        );
    }

    render() {
        const {
            timeSpanOptions, filtersOptions, value, className,
        } = this.props;
        const { calendarOpened, timeSpanValue } = this.state;
        const { timeSpan, q: query = null, ...filtersValue } = value || {};
        const timeSpanIsCustom = timeSpanValue === 'custom';
        const finalTimeSpanOptions = timeSpanIsCustom || calendarOpened
            ? timeSpanOptions.map(option => (option.value === 'custom'
                ? {
                    ...option,
                    className: classNames([
                        styles.customItem,
                        {
                            [styles.hasValue]: timeSpanIsCustom,
                        },
                    ]),
                    label: timeSpanIsCustom ? this.renderCustomLabel() : option.label,
                    children:
                                    calendarOpened || timeSpanIsCustom ? (
                                        <Fragment>
                                            {calendarOpened ? this.renderCalendar() : null}
                                            {timeSpanIsCustom ? this.renderClearButton() : null}
                                        </Fragment>
                                    ) : null,
                }
                : option))
            : timeSpanOptions;
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
            >
                <div className={styles.main}>
                    <div className={styles.cols}>
                        <div className={classNames([styles.col, styles.colLeft])}>
                            <Toggles
                                options={finalTimeSpanOptions}
                                value={calendarOpened ? 'custom' : timeSpanValue}
                                isGrouped
                                className={styles.toggles}
                                itemClassName={styles.item}
                                buttonClassName={styles.button}
                                onChange={this.onTimeSpanChange}
                            />
                        </div>
                        <div className={classNames([styles.col, styles.colRight])}>
                            <Search
                                value={query}
                                className={styles.search}
                                light
                                fatIcon
                                onClear={this.onSearchClear}
                                onSubmit={this.onSearchSubmit}
                            />
                        </div>
                    </div>
                </div>
                {filtersOptions ? (
                    <div className={styles.more}>
                        <FiltersBar
                            options={filtersOptions || []}
                            value={value !== null ? filtersValue : null}
                            onChange={this.onFiltersChange}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

EventsFilters.propTypes = propTypes;
EventsFilters.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ events }, { filtersOptions }) => ({
        filtersOptions: filtersOptions !== null ? events.filtersOptions : null,
    }),
    dispatch => ({
        setFiltersOptions: options => dispatch(setFiltersOptionsAction(options)),
    }),
)(EventsFilters);
const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);
export default WithUrlGeneratorContainer;
