import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import Carousel from './Carousel';

const messages = defineMessages({
    title: {
        id: 'content.magazines_carousel',
        defaultMessage: 'Poursuivez votre lecture',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    morePage: AppPropTypes.page,
    magazinesPage: AppPropTypes.page,
};

const defaultProps = {
    title: null,
    morePage: null,
    magazinesPage: null,
};

const MagazinesCarousel = ({
    intl,
    urlGenerator,
    id,
    title,
    morePage,
    magazinesPage,
    ...props
}) => (
    <Carousel
        url={urlGenerator.route('api.magazines_carousel', {
            block: id,
        })}
        id={id}
        title={title || intl.formatMessage(messages.title)}
        morePage={morePage || magazinesPage}
        isFullWidth={false}
        {...props}
    />
);

MagazinesCarousel.propTypes = propTypes;
MagazinesCarousel.defaultProps = defaultProps;

const WithStateContainer = connect(({ site = null }) => ({
    magazinesPage:
        site !== null
            ? Object.keys(site.pages).find(url => site.pages[url].handle === 'magazines') || null
            : null,
}))(MagazinesCarousel);
const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);
const WithIntlContainer = injectIntl(WithUrlGeneratorContainer);
export default WithIntlContainer;
