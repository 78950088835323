import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ArrowIcon from '../icons/Arrow';
import Button from './Button';

import styles from '../../../styles/buttons/arrow.scss';

const propTypes = {
    direction: PropTypes.oneOf(['left', 'right']),
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    direction: 'left',
    color: null,
    className: null,
};

const ArrowButton = ({
    direction, color, className, ...props
}) => (
    <Button
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
                [styles[color]]: color !== null,
            },
        ])}
        {...props}
    >
        <ArrowIcon direction={direction} className={styles.icon} />
    </Button>
);

ArrowButton.propTypes = propTypes;
ArrowButton.defaultProps = defaultProps;

export default ArrowButton;
