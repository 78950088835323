import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as MagazineComponents from './index';
import PageStatus from '../partials/PageStatus';

import { pascalCase } from '../../lib/utils';
import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/magazines/magazine.scss';

const propTypes = {
    item: AppPropTypes.magazine,
    components: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    smallStatus: PropTypes.bool,
    isPreview: PropTypes.bool,
};

const defaultProps = {
    item: null,
    components: MagazineComponents,
    smallStatus: false,
    isPreview: false,
};

const Magazine = ({ item, components, smallStatus, isPreview, ...props }) => {
    const type = item.type.replace(/^magazine_/i, '').replace(/^magazine$/i, 'text');

    const MagazineComponent = components[pascalCase(type)] || null;
    if (MagazineComponent === null) {
        return null;
    }

    return (
        <Fragment>
            {item.status && item.status !== 'published' ? (
                <PageStatus
                    className={classNames([
                        styles.status,
                        {
                            [styles.small]: smallStatus,
                        },
                    ])}
                    status={item.status}
                    date={item.publishAt}
                />
            ) : null}
            <MagazineComponent {...props} page={item} {...item} isPreview={isPreview} />
        </Fragment>
    );
};

Magazine.propTypes = propTypes;
Magazine.defaultProps = defaultProps;

export default Magazine;
