import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import PageMeta from '../partials/PageMeta';
import BackBar from '../partials/BackBar';
import PageHeader from '../partials/PageHeader';
import MapVisual from '../partials/MapVisual';
import BuyTicketsButton from '../buttons/BuyTickets';
import Blocks from '../blocks/Blocks';
import FacebookIcon from '../icons/Facebook';
import EventDetails from '../partials/EventDetails';
import EventBox from '../partials/EventBox';
import Share from '../partials/Share';
import MDAIcon from '../icons/MDA';

import styles from '../../../styles/pages/event.scss';

const messages = defineMessages({
    viewAll: {
        id: 'content.view_all_events',
        defaultMessage: '← Voir tous les événements',
    },
    facebookEvent: {
        id: 'content.facebook_event',
        defaultMessage: 'Événement Facebook',
    },
    credits: {
        id: 'content.photo_by',
        defaultMessage: 'Photo par {name}',
    },
});

const propTypes = {
    page: AppPropTypes.event.isRequired,
};

const defaultProps = {};

const EventPage = ({ page }) => (
    <div className={styles.container}>
        <PageMeta {...page.share} />
        {page.parent !== null ? <BackBar url={page.parent.url} label={messages.viewAll} /> : null}
        <div className={styles.inner}>
            <PageHeader
                title={page.title}
                right={
                    page.ticketUrl !== null ? (
                        <BuyTicketsButton
                            href={page.ticketUrl}
                            isFree={page.isFree}
                            external
                            className={styles.buyButton}
                        />
                    ) : null
                }
                className={styles.header}
                titleClassName={styles.title}
                textClassName={styles.text}
                colLeftClassName={styles.colLeft}
                colRightClassName={styles.colRight}
            />
            <MapVisual
                image={page.image}
                imageChildren={(
                    <Fragment>
                        {page.isMDA ? <MDAIcon className={styles.mdaIcon} /> : null}
                        {page.disciplines !== null && page.disciplines.length > 0 ? (
                            <div className={styles.category}>{page.disciplines[0].name}</div>
                        ) : null}
                        {page.image !== null && (page.image.credits || null) !== null ? (
                            <div className={styles.credits}>
                                <FormattedMessage
                                    {...messages.credits}
                                    values={{ name: page.image.credits }}
                                />
                            </div>
                        ) : null}
                    </Fragment>
                )}
                latitude={page.venue !== null ? page.venue.latitude || null : null}
                longitude={page.venue !== null ? page.venue.longitude || null : null}
                className={classNames([
                    styles.visual,
                    {
                        [styles.withCredits]:
                            page.image !== null && (page.image.credits || null) !== null,
                    },
                ])}
                colLeftClassName={styles.colLeft}
                colRightClassName={styles.colRight}
            />

            <div className={styles.content}>
                <div className={styles.cols}>
                    <div className={classNames([styles.col, styles.colLeft])}>
                        <Blocks
                            blocks={[
                                page.description !== null && {
                                    id: 'block_title',
                                    type: 'text',
                                    text: page.description,
                                },
                                page.video !== null && {
                                    id: 'block_embed',
                                    type: 'embed',
                                    embed: page.video,
                                },
                                (page.photos || null) !== null
                                    && page.photos.length > 0 && {
                                    id: 'block_photos',
                                    type: 'photos_carousel',
                                    photos: page.photos,
                                    opposite: true,
                                },
                            ].filter(Boolean)}
                            className={styles.blocks}
                        />
                        {page.facebookUrl !== null ? (
                            <div className={styles.facebook}>
                                <a
                                    href={page.facebookUrl}
                                    className={styles.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FacebookIcon white className={styles.icon} />
                                    <span className={styles.label}>
                                        <FormattedMessage {...messages.facebookEvent} />
                                    </span>
                                </a>
                            </div>
                        ) : null}
                        <EventDetails
                            organisation={page.organisation}
                            contact={page.contact}
                            audiences={page.audiences}
                            groups={page.groups}
                            className={styles.details}
                        />
                    </div>
                    <div className={classNames([styles.col, styles.colRight])}>
                        <EventBox
                            date={page.date}
                            dates={page.dates}
                            prices={page.prices}
                            isFree={page.isFree}
                            venue={page.venue}
                            organisation={page.organisation}
                            ticketUrl={page.ticketUrl}
                            className={styles.box}
                        />
                    </div>
                </div>
            </div>
            <Share type={page.type} {...page.share} className={styles.share} isSection />
        </div>
    </div>
);

EventPage.propTypes = propTypes;
EventPage.defaultProps = defaultProps;

export default React.memo(EventPage);
