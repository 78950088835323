/* globals LOCALE: true */
import { getJSON, postJSON } from '@folklore/fetch';

const locale = LOCALE || null;
const getLocaleHeaders = () => (
    locale !== null ? {
        'Accept-Language': locale,
    } : null
);

export const loadPage = url => getJSON(url, {
    credentials: 'same-origin',
    headers: {
        ...getLocaleHeaders(),
    },
});

export const loadList = url => getJSON(url, {
    credentials: 'same-origin',
    headers: {
        ...getLocaleHeaders(),
    },
});

export const loadNewArticle = url => getJSON(url, {
    credentials: 'same-origin',
    headers: {
        ...getLocaleHeaders(),
    },
});

export const loadFiltersOptions = url => getJSON(url, {
    credentials: 'same-origin',
    headers: {
        ...getLocaleHeaders(),
    },
});

export const subscribeNewsletter = (url, email) => postJSON(url, {
    email,
}, {
    credentials: 'same-origin',
    headers: {
        ...getLocaleHeaders(),
    },
});

export const sendMessage = (url, email, message, newsletter = true) => postJSON(url, {
    email,
    message,
    newsletter,
}, {
    credentials: 'same-origin',
    headers: {
        ...getLocaleHeaders(),
    },
});

export const loadVenues = url => getJSON(url, {
    credentials: 'same-origin',
    headers: {
        ...getLocaleHeaders(),
    },
});

export const loadVenueEvents = url => getJSON(url, {
    credentials: 'same-origin',
    headers: {
        ...getLocaleHeaders(),
    },
});
