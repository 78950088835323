import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import { loadNewArticle } from '../../lib/requests';
import { setNewArticle as setNewArticleAction } from '../../actions/SiteActions';
import MagazineIcon from '../icons/Magazine';
import Card from '../cards/Card';

import styles from '../../../styles/partials/new-article.scss';

const messages = defineMessages({
    title: {
        id: 'content.new_article_title',
        defaultMessage: 'Consultez les derniers articles',
    },
    magazine: {
        id: 'content.new_article_magazine_button',
        defaultMessage: 'Magazine',
    },
});

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    page: AppPropTypes.page,
    magazinePageUrl: PropTypes.string,
    setNewArticle: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    page: null,
    magazinePageUrl: null,
    className: null,
};

class NewArticle extends Component {
    static getDerivedStateFromProps(props, state) {
        if (state.magazinePageUrl === null && props.magazinePageUrl !== null) {
            return {
                magazinePageUrl: props.magazinePageUrl,
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.onPageLoaded = this.onPageLoaded.bind(this);

        this.state = {
            magazinePageUrl: null,
        };
    }

    componentDidMount() {
        const { page } = this.props;
        if (page === null) {
            this.loadPage();
        }
    }

    componentDidUpdate({ page: prevPage }) {
        const { page } = this.props;
        const pageChanged = prevPage !== page;
        if (pageChanged && page === null) {
            this.loadPage();
        }
    }

    onPageLoaded({ magazinePageUrl = null, article }) {
        const { setNewArticle } = this.props;
        if (magazinePageUrl !== null) {
            this.setState({
                magazinePageUrl,
            });
        }
        setNewArticle(article);
    }

    loadPage() {
        const { urlGenerator } = this.props;
        const { magazinePageUrl } = this.state;
        const url = `${urlGenerator.route(
            'api.new_article',
        )}?with_magazine_page_url=${magazinePageUrl === null}`;
        loadNewArticle(url).then(this.onPageLoaded);
    }

    render() {
        const { page, className } = this.props;
        const { magazinePageUrl } = this.state;
        if (page === null) {
            return null;
        }
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
            >
                <div className={styles.cols}>
                    <div className={styles.col}>
                        <div className={styles.contentContainer}>
                            <h4 className={styles.title}>
                                <FormattedMessage {...messages.title} />
                            </h4>
                            <Link to={magazinePageUrl || '#'} className={styles.link}>
                                <MagazineIcon withStroke withoutEye className={styles.icon} />
                                <span className={styles.label}>
                                    <FormattedMessage {...messages.magazine} />
                                    {' →'}
                                </span>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.cardContainer}>
                            <Card item={page} small poster className={styles.card} />
                            <Card
                                type="magazine"
                                url={page.url}
                                image={page.image}
                                small
                                poster
                                className={styles.cardEmpty}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NewArticle.propTypes = propTypes;
NewArticle.defaultProps = defaultProps;

const mapStateToProps = ({ site }) => {
    const magazineUrl = Object.keys(site.pages).find(url => site.pages[url].handle === 'magazine') || null;
    return {
        page: site.newArticle,
        magazinePageUrl: magazineUrl,
    };
};
const mapDispatchToProps = dispatch => ({
    setNewArticle: page => dispatch(setNewArticleAction(page)),
});
const WithStateContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewArticle);
const WithUrlContainer = withUrlGenerator()(WithStateContainer);
export default WithUrlContainer;
