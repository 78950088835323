/* eslint-disable indent */
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { isAfter, subDays } from 'date-fns';
import * as AppPropTypes from '../../lib/PropTypes';
import { getDateObject } from '../../lib/utils';
import Button from '../buttons/Button';
import BuyTicketsButton from '../buttons/BuyTickets';

import styles from '../../../styles/partials/event-box.scss';

const messages = defineMessages({
    moreOtherDates: {
        id: 'content.other_dates_more',
        defaultMessage: '+ {count, plural, one {# autre date} other {# autres dates}}',
    },
    lessOtherDates: {
        id: 'content.other_dates_less',
        defaultMessage: 'Moins de dates',
    },
    otherDatesTitle: {
        id: 'content.other_dates_title',
        defaultMessage: 'Dates',
    },
    to: {
        id: 'content.date_to',
        defaultMessage: 'au',
    },
    and: {
        id: 'content.date_and',
        defaultMessage: 'et',
    },
    free: {
        id: 'content.free',
        defaultMessage: 'Gratuit',
    },
    viewOnMap: {
        id: 'content.view_on_map',
        defaultMessage: 'Voir sur la carte',
    },
    room: {
        id: 'content.room',
        defaultMessage: 'Salle: {room}',
    },
    finished: {
        id: 'content.finished',
        defaultMessage: 'Past event',
    },
});

const propTypes = {
    date: AppPropTypes.eventDate,
    dates: AppPropTypes.eventDates,
    venue: AppPropTypes.venue,
    prices: AppPropTypes.prices,
    isFree: PropTypes.bool,
    ticketUrl: PropTypes.string,
    maxOtherDates: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    date: null,
    dates: [],
    venue: null,
    prices: [],
    isFree: false,
    ticketUrl: null,
    maxOtherDates: 3,
    className: null,
};

class EventBox extends PureComponent {
    constructor(props) {
        super(props);

        this.onClickOtherDates = this.onClickOtherDates.bind(this);

        this.state = {
            otherDatesOpened: false,
            now: subDays(new Date(), 1),
        };
    }

    onClickOtherDates() {
        this.setState(({ otherDatesOpened }) => ({
            otherDatesOpened: !otherDatesOpened,
        }));
    }

    // eslint-disable-next-line class-methods-use-this
    renderDate(dateObject) {
        const { dates } = this.props;
        const datesCount = dates.length;
        const isRange = dateObject.end !== null;

        return isRange ? (
            <div className={classNames([styles.date, styles.range])}>
                <div className={styles.start}>
                    <FormattedDate value={dateObject.start} day="2-digit" month="short" />
                </div>
                <div className={styles.year}>
                    <FormattedDate value={dateObject.start} year="numeric" />
                </div>
                <div className={styles.to}>
                    <FormattedMessage {...(datesCount === 2 ? messages.and : messages.to)} />
                </div>
                <div className={styles.end}>
                    <FormattedDate value={dateObject.end} day="2-digit" month="short" />
                </div>
                <div className={styles.year}>
                    <FormattedDate value={dateObject.end} year="numeric" />
                </div>
            </div>
        ) : (
            <div className={styles.date}>
                <div className={styles.day}>
                    <FormattedDate value={dateObject.start} day="2-digit" />
                </div>
                <div className={styles.month}>
                    <FormattedDate value={dateObject.start} month="short" />
                </div>
                <div className={styles.year}>
                    <FormattedDate value={dateObject.start} year="numeric" />
                </div>
                <div className={styles.time}>
                    <FormattedTime value={dateObject.start} />
                </div>
            </div>
        );
    }

    render() {
        const { date, dates, venue, prices, isFree, ticketUrl, maxOtherDates, className } =
            this.props;
        const { otherDatesOpened, now } = this.state;
        const dateObject =
            date !== null
                ? {
                      ...date,
                      start: getDateObject(date.start),
                      end: date.end !== null ? getDateObject(date.end) : null,
                  }
                : null;
        const datesCount = dates.length;
        // prettier-ignore
        const otherDates = otherDatesOpened ? dates : dates.slice(0, maxOtherDates);
        const hiddenDatesCount = Math.max(datesCount - maxOtherDates, 0);

        let after = true;
        if (dateObject !== null) {
            if (dateObject.end !== null) {
                after = isAfter(dateObject.end, now);
            } else if (dateObject.start !== null) {
                after = isAfter(dateObject.start, now);
            }
        }

        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
            >
                <div className={styles.calendar}>
                    {!after ? (
                        <>
                            <h3 className={classNames([styles.title, styles.firstTitle])}>
                                <FormattedMessage {...messages.finished} />
                            </h3>
                            <hr />
                        </>
                    ) : null}
                    <div className={styles.cols}>
                        <div className={classNames([styles.col, styles.colDate])}>
                            {dateObject !== null ? this.renderDate(dateObject) : null}
                        </div>
                        <div className={classNames([styles.col, styles.colDates])}>
                            {datesCount > 1 ? (
                                <div className={styles.otherDates}>
                                    <h4 className={styles.title}>
                                        <FormattedMessage {...messages.otherDatesTitle} />
                                    </h4>
                                    <ul className={styles.items}>
                                        {otherDates.map((otherDate) => (
                                            <li
                                                className={styles.item}
                                                key={`date-${otherDate.formatted}`}
                                            >
                                                {otherDate.formatted}
                                            </li>
                                        ))}
                                    </ul>
                                    {hiddenDatesCount > 0 ? (
                                        <Button
                                            transparent
                                            className={styles.button}
                                            onClick={this.onClickOtherDates}
                                        >
                                            <FormattedMessage
                                                {...(otherDatesOpened
                                                    ? messages.lessOtherDates
                                                    : messages.moreOtherDates)}
                                                values={{ count: hiddenDatesCount }}
                                            />
                                        </Button>
                                    ) : null}
                                </div>
                            ) : null}
                            {prices.length > 0 ? (
                                <div
                                    className={classNames([
                                        styles.prices,
                                        {
                                            [styles.bigger]: prices.length === 1,
                                            [styles.hasOtherDates]: datesCount > 1,
                                        },
                                    ])}
                                >
                                    <table cellSpacing="0" cellPadding="0">
                                        <tbody>
                                            {prices.map(({ price, description }) => (
                                                <tr key={`price-${price}-${description}`}>
                                                    {description !== null ? (
                                                        <Fragment>
                                                            <td className={styles.description}>
                                                                {description}
                                                            </td>
                                                            <td className={styles.arrow}>›</td>
                                                        </Fragment>
                                                    ) : null}
                                                    <td
                                                        className={styles.price}
                                                        colSpan={description === null ? 3 : null}
                                                    >
                                                        {parseFloat(price) === 0 ? (
                                                            <FormattedMessage {...messages.free} />
                                                        ) : (
                                                            `${parseFloat(price).toFixed(2)} $`
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <hr />
                {venue !== null ? (
                    <div className={styles.venue}>
                        <div className={styles.name}>{venue.name}</div>
                        {venue.room !== null ? (
                            <div className={styles.room}>
                                <FormattedMessage
                                    {...messages.room}
                                    values={{ room: venue.room.name }}
                                />
                            </div>
                        ) : null}
                        <div className={styles.address}>{venue.address}</div>
                        <div className={styles.city}>
                            {venue.city} {venue.region}
                        </div>
                        <div className={styles.postalCode}>{venue.postalCode}</div>
                        <a
                            href={venue.googleMapsUrl}
                            className={styles.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FormattedMessage {...messages.viewOnMap} />
                        </a>
                    </div>
                ) : null}
                {ticketUrl !== null ? (
                    <div className={styles.actions}>
                        <BuyTicketsButton
                            href={ticketUrl}
                            isFree={isFree}
                            external
                            className={styles.buyButton}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

EventBox.propTypes = propTypes;
EventBox.defaultProps = defaultProps;

export default EventBox;
