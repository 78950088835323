import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const MarkerIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="12.7px"
        height="20.7px"
        viewBox="0 0 12.7 20.7"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            d="M6.3,0C2.8,0,0,2.9,0,6.5c0,0-0.5,5.2,6.4,14.2c0,0,5.8-8.3,6.3-13.5c0,0,0-0.1,0-0.1c0-0.2,0-0.4,0-0.6C12.7,2.9,9.9,0,6.3,0z M6.3,8.9C5,8.9,3.9,7.8,3.9,6.4C3.9,5,5,3.8,6.3,3.8S8.8,5,8.8,6.4C8.8,7.8,7.7,8.9,6.3,8.9z"
        />
    </svg>
);

MarkerIcon.propTypes = propTypes;
MarkerIcon.defaultProps = defaultProps;

export default MarkerIcon;
