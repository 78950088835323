import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const EventsIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        xmlSpace="preserve"
        className={className}
    >
        <rect fill={color} width="16" height="3.2" />
        <path
            fill={color}
            d="M0,4.9V16h16V4.9H0z M7.2,14.1L4,11.2l0.5-0.6L5,10l0,0l0.1-0.1l2,1.7l3.8-4.1l0.6,0.6l0.5,0.5l0,0l0.1,0.1L7.2,14.1z"
        />
    </svg>
);

EventsIcon.propTypes = propTypes;
EventsIcon.defaultProps = defaultProps;

export default EventsIcon;
