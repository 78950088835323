import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import ColumnsMenu from '../menus/Columns';
import Newsletter from './Newsletter';
import NewArticle from './NewArticle';
import { addIcons as addSocialIcons } from '../icons/socialIcons';

import styles from '../../../styles/partials/footer.scss';

const propTypes = {
    menuColumns: AppPropTypes.menuColumns.isRequired,
    splashIsGrey: PropTypes.bool,
    splashIsWhite: PropTypes.bool,
};

const defaultProps = {
    splashIsGrey: false,
    splashIsWhite: false,
};

const Footer = ({ menuColumns, splashIsGrey, splashIsWhite }) => {
    const menuColumnsCount = menuColumns.length;
    return (
        <div className={styles.container}>
            <div
                className={classNames([styles.splash, {
                    [styles.isGrey]: splashIsGrey,
                    [styles.isWhite]: splashIsWhite,
                }])}
            >
                <div className={styles.wrapper}>
                    <div className={styles.middle}>
                        <div className={styles.inner}>
                            <div className={styles.cols}>
                                <div className={classNames([styles.col, styles.colNewsletter])}>
                                    <Newsletter
                                        className={styles.newsletter}
                                    />
                                </div>
                                <div className={classNames([styles.col, styles.colNewArticle])}>
                                    <NewArticle className={styles.newArticle} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.backgroundColor} />
            </div>
            <div className={styles.menu}>
                <div className={styles.inner}>
                    <ColumnsMenu
                        columns={menuColumns.map(
                            (col, index) => (index === menuColumnsCount - 1
                                ? {
                                    ...col,
                                    items: col.items.map(
                                        it => (isArray(it) ? addSocialIcons(it, {
                                            white: true,
                                            className: styles.icon,
                                        }) : it),
                                    ),
                                }
                                : col),
                        )}
                        titleClassName={styles.title}
                        colsClassName={styles.cols}
                        colClassName={styles.col}
                        itemClassName={styles.item}
                        linkClassName={styles.link}
                    />
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
