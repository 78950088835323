/* eslint-disable react/no-danger */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { addNonBreakingSpaces } from '../../lib/utils';
import styles from '../../../styles/partials/page-header.scss';

const propTypes = {
    href: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    right: PropTypes.node,
    big: PropTypes.bool,
    icon: PropTypes.node,
    isPageHeading: PropTypes.bool,

    children: PropTypes.node,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    textClassName: PropTypes.string,
    colLeftClassName: PropTypes.string,
    colRightClassName: PropTypes.string,
};

const defaultProps = {
    href: null,
    title: null,
    description: null,
    right: null,
    big: false,
    icon: null,
    isPageHeading: true,
    children: null,
    className: null,
    innerClassName: null,
    titleClassName: null,
    textClassName: null,
    colLeftClassName: null,
    colRightClassName: null,
};

const PageHeader = ({
    href,
    title,
    description,
    right,
    big,
    icon,
    isPageHeading,
    children,
    className,
    innerClassName,
    titleClassName,
    textClassName,
    colLeftClassName,
    colRightClassName,
}) => {
    const innerElements = (
        <Fragment>
            {icon}
            <span
                className={classNames([
                    styles.text,
                    {
                        [textClassName]: textClassName !== null,
                    },
                ])}
            >
                {addNonBreakingSpaces(title)}
            </span>
        </Fragment>
    );

    const titleElement = (
        <Fragment>
            {isPageHeading ? (
                <h1
                    className={classNames([
                        styles.title,
                        {
                            [titleClassName]: titleClassName !== null,
                        },
                    ])}
                >
                    {href !== null ? (
                        <Link to={href} className={styles.link}>
                            {innerElements}
                        </Link>
                    ) : (
                        innerElements
                    )}
                </h1>
            ) : (
                <strong
                    className={classNames([
                        styles.title,
                        {
                            [titleClassName]: titleClassName !== null,
                        },
                    ])}
                >
                    {href !== null ? (
                        <Link to={href} className={styles.link}>
                            {innerElements}
                        </Link>
                    ) : (
                        innerElements
                    )}
                </strong>
            )}
            {description !== null ? (
                <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            ) : null}
        </Fragment>
    );

    return (
        <div
            className={classNames({
                [styles.container]: true,
                [styles.big]: big,
                [className]: className !== null,
            })}
        >
            <div
                className={classNames([
                    styles.inner,
                    {
                        [innerClassName]: innerClassName !== null,
                    },
                ])}
            >
                {right !== null ? (
                    <div className={styles.cols}>
                        <div
                            className={classNames([
                                styles.col,
                                styles.colLeft,
                                {
                                    [colLeftClassName]: colLeftClassName !== null,
                                },
                            ])}
                        >
                            {titleElement}
                        </div>
                        <div
                            className={classNames([
                                styles.col,
                                styles.colRight,
                                {
                                    [colRightClassName]: colRightClassName !== null,
                                },
                            ])}
                        >
                            {right}
                        </div>
                    </div>
                ) : (
                    titleElement
                )}
            </div>
            {children}
        </div>
    );
};

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default PageHeader;
