import SiteReducer from './SiteReducer';
import LayoutReducer from './LayoutReducer';
import EventsReducer from './EventsReducer';
import EventsMapReducer from './EventsMapReducer';
import ContactsReducer from './ContactsReducer';
import MagazinesReducer from './MagazinesReducer';
import HomeSplashReducer from './HomeSplashReducer';
import QuizReducer from './QuizReducer';

export default {
    site: SiteReducer,
    layout: LayoutReducer,
    events: EventsReducer,
    eventsMap: EventsMapReducer,
    contacts: ContactsReducer,
    magazines: MagazinesReducer,
    homeSplash: HomeSplashReducer,
    quiz: QuizReducer,
};
