import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#E95949',
    className: null,
};

const MapIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="1570.9px"
        height="840.2px"
        viewBox="0 0 1570.9 840.2"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill="none"
            stroke={color}
            strokeWidth="30"
            strokeMiterlimit="10"
            d="M796.6,43.2l61.4-3.2c7.8-0.4,15.4,2.6,20.9,8.2l3.2,3.3c6.5,6.7,16,9.6,25.2,7.6l14.4-3.1c3.6-0.8,7.1-2.3,10.1-4.5l43.2-31.4c5.2-3.8,11.6-5.6,18-5.1l39,3.1c6,0.5,11.7,3,16.2,7.1l47.7,43.9c5.3,4.9,12.5,7.5,19.7,7.1l120.6-6.3c14.9-0.8,27.6,10.7,28.4,25.6l0.5,9.6c0.8,14.9,13.5,26.4,28.4,25.6l154.1-8.1c1.8-0.1,3.5,0,5.3,0.2l79.5,11.4c12.8,1.8,22.5,12.5,23.1,25.3l0.1,1.6c0.4,7.2-2.1,14.2-6.9,19.5l-51.6,57.3c-4.8,5.3-11.5,8.5-18.7,8.9l-49.2,2.6c-7.2,0.4-13.9,3.6-18.7,8.9l-16.9,18.8c-3.6,4-8.3,6.9-13.6,8.2L1199.8,330c-4.5,1.1-8.5,3.3-11.9,6.4L992.4,518.2c0,0-193.9,193.1-187.7,189.6c5.6-3.2-287.2,95.9-346.2,115.9c-6.6,2.2-13.8,1.8-20.2-1.1L215.2,717.7c-9.1-4.3-19.8-3.2-27.8,2.9l-10.7,8.1c-6.7,5.1-15.3,6.7-23.4,4.5l-19-5.2c-10.5-2.9-18.3-11.7-19.7-22.5l-2-15.1c-1.2-9.4-7.3-17.5-16.1-21.3L31.3,641c-9.4-4.1-15.7-13.1-16.3-23.4l0,0c-0.7-13.4,8.5-25.3,21.7-27.9l30.8-6.2c11.6-2.3,20.4-11.9,21.6-23.7l8.3-80.4c1.1-11.1,9-20.3,19.7-23.3L288.5,409c5.7-1.6,10.7-4.9,14.3-9.6l75.8-98.6c4.8-6.3,12.1-10.1,20-10.5l87.6-4.6c8.3-0.4,15.9-4.6,20.7-11.4l32.9-46.7c4.8-6.8,12.4-11,20.7-11.4l9.1-0.5c10.2-0.5,19.2-6.7,23.3-16l10.1-22.9c2-4.6,5.3-8.5,9.3-11.3L782.7,48C786.8,45.1,791.6,43.5,796.6,43.2z"
        />
    </svg>
);

MapIcon.propTypes = propTypes;
MapIcon.defaultProps = defaultProps;

export default MapIcon;
