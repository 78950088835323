import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Carousel from '../blocks/Carousel';

import styles from '../../../styles/partials/events-map-popup.scss';

const propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
    maxInteractionTime: PropTypes.number,
    onClickOutside: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    width: 300,
    x: 0,
    y: 0,
    maxInteractionTime: 300,
    onClickOutside: null,
    className: null,
};

class EventsMapPopup extends PureComponent {
    constructor(props) {
        super(props);

        this.onResize = this.onResize.bind(this);
        this.onInteractionStart = this.onInteractionStart.bind(this);
        this.onInteractionEnd = this.onInteractionEnd.bind(this);

        this.refContainer = null;
        this.interactionStartTime = null;

        this.state = {
            maxWidth: window.innerWidth,
            maxHeight: window.innerHeight,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        document.addEventListener('touchstart', this.onInteractionStart, true);
        document.addEventListener('touchend', this.onInteractionEnd, true);
        document.addEventListener('mousedown', this.onInteractionStart, true);
        document.addEventListener('mouseup', this.onInteractionEnd, true);
        this.updateSize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        document.removeEventListener('touchstart', this.onInteractionStart, true);
        document.removeEventListener('touchend', this.onInteractionEnd, true);
        document.removeEventListener('mousedown', this.onInteractionStart, true);
        document.removeEventListener('mouseup', this.onInteractionEnd, true);
    }

    onResize() {
        this.updateSize();
    }

    onInteractionStart() {
        this.interactionStartTime = (new Date()).getTime();
    }

    onInteractionEnd(e) {
        const { maxInteractionTime, onClickOutside } = this.props;
        const now = (new Date()).getTime();
        const elapsed = this.interactionStartTime !== null ? (now - this.interactionStartTime) : 0;
        if (elapsed < maxInteractionTime && !this.refContainer.contains(e.target)) {
            onClickOutside(e);
        }
    }

    getCardPosition(x, y) {
        const { width } = this.props;
        const { maxWidth, maxHeight } = this.state;
        const isSmall = maxWidth < width * 2;
        let position = 'bottom';
        const halfWidth = width / 2;
        if (y > maxHeight / 2 && (x > halfWidth && x < maxWidth - halfWidth)) {
            position = 'top';
        } else if (!isSmall && x < halfWidth) {
            position = 'right';
        } else if (!isSmall && x > maxWidth - halfWidth) {
            position = 'left';
        }
        return position;
    }

    updateSize() {
        const parent = this.refContainer.parentNode || null;
        this.setState({
            maxWidth: parent !== null ? parent.offsetWidth : window.innerWidth,
            maxHeight: parent !== null ? parent.offsetHeight : window.innerHeight,
        });
    }

    render() {
        const {
            url, x, y, width, className,
        } = this.props;
        const { maxWidth } = this.state;
        const position = this.getCardPosition(x, y);
        const halfWidth = width / 2;
        const isSmall = maxWidth < width * 2;
        // prettier-ignore
        const isOutLeft = isSmall && x < halfWidth;
        const isOutRight = isSmall && maxWidth - x < halfWidth;
        const isOut = isOutLeft || isOutRight;
        return (
            <div
                className={classNames([
                    styles.container,
                    styles[position],
                    {
                        [className]: className !== null,
                    },
                ])}
                style={{
                    transform: `translate(${x}px, ${y}px)`,
                }}
                ref={(ref) => {
                    this.refContainer = ref;
                }}
            >
                <div
                    className={styles.inner}
                    style={{
                        transform: isOut
                            ? `translate(${-50
                                  + (isOutLeft
                                      ? ((halfWidth - x) / halfWidth) * 100
                                      : -(((halfWidth - (maxWidth - 20 - x)) / halfWidth) * 100))
                                      * 0.5}%, ${position === 'top' ? '-100%' : '0'})`
                            : null,
                    }}
                >
                    <Carousel
                        url={url}
                        cardsProps={{ red: true, withShadow: false }}
                        posterCards={false}
                        className={styles.carousel}
                        itemsClassName={styles.items}
                        buttonClassName={styles.button}
                        emptyCardClassName={styles.emptyCard}
                    />
                </div>
                <div className={styles.pin} />
            </div>
        );
    }
}

EventsMapPopup.propTypes = propTypes;
EventsMapPopup.defaultProps = defaultProps;

export default EventsMapPopup;
