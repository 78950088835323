import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';
import isArray from 'lodash/isArray';

import Organisation from './Organisation';
import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/partials/event-details.scss';

const messages = defineMessages({
    contact: {
        id: 'content.details_contact',
        defaultMessage: 'Contact',
    },
    audiences: {
        id: 'content.details_audiences',
        defaultMessage: '{count, plural, one {Public} other {Publics}}',
    },
    organisation: {
        id: 'content.details_organisation',
        defaultMessage: 'Organisateur',
    },
    group: {
        id: 'content.details_group',
        defaultMessage: 'Partenaire',
    },
    groups: {
        id: 'content.details_groups',
        defaultMessage: 'Partenaires',
    },
    disciplines: {
        id: 'content.details_disciplines',
        defaultMessage: '{count, plural, one {Discipline} other {Disciplines}}',
    },
});

const propTypes = {
    contact: AppPropTypes.contact,
    audiences: AppPropTypes.eventEntities,
    disciplines: AppPropTypes.eventEntities,
    organisation: AppPropTypes.eventOrganisation,
    groups: PropTypes.arrayOf(AppPropTypes.eventOrganisation), // TODO: get this from caligram...
    className: PropTypes.string,
    colClassName: PropTypes.string,
};

const defaultProps = {
    contact: null,
    audiences: null,
    organisation: null,
    groups: [],
    disciplines: null,
    className: null,
    colClassName: null,
};

const EventDetails = ({
    contact,
    audiences,
    disciplines,
    organisation,
    groups,
    className,
    colClassName,
}) => (
    <div
        className={classNames({
            [styles.container]: true,
            [className]: className !== null,
        })}
    >
        <div className={styles.cols}>
            {organisation !== null ? (
                <div
                    className={classNames([
                        styles.col,
                        {
                            [colClassName]: colClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage {...messages.organisation} />
                    </h4>
                    <Organisation organisation={organisation} />
                </div>
            ) : null}
            {contact !== null ? (
                <div
                    className={classNames([
                        styles.col,
                        {
                            [colClassName]: colClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage {...messages.contact} />
                    </h4>
                    {contact.name !== null ? (
                        <div className={styles.name}>{contact.name}</div>
                    ) : null}
                    {contact.phone !== null ? (
                        <div className={styles.phone}>{contact.phone}</div>
                    ) : null}
                    {contact.email !== null ? (
                        <div className={styles.email}>
                            <a href={`mailto:${contact.email}`}>{contact.email}</a>
                        </div>
                    ) : null}
                    {contact.url !== null ? (
                        <div className={styles.link}>
                            <a href={contact.url}>{contact.url}</a>
                        </div>
                    ) : null}
                </div>
            ) : null}
            {groups !== null && isArray(groups) && groups.length > 0 ? (
                <div
                    className={classNames([
                        styles.col,
                        {
                            [colClassName]: colClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        {groups.length > 1 ? (
                            <FormattedMessage {...messages.groups} />
                        ) : (
                            <FormattedMessage {...messages.group} />
                        )}
                    </h4>
                    {groups.map((partner, i) => (
                        <Organisation
                            key={`partner-${partner.id}-${i + 1}`}
                            organisation={partner}
                        />
                    ))}
                </div>
            ) : null}
        </div>
        <div className={styles.cols}>
            {audiences !== null && audiences.length > 0 ? (
                <div
                    className={classNames([
                        styles.col,
                        {
                            [colClassName]: colClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage
                            {...messages.audiences}
                            values={{ count: audiences.length }}
                        />
                    </h4>
                    <ul>
                        {audiences.map(audience => (
                            <li key={`audience-${audience.id}`}>{audience.name}</li>
                        ))}
                    </ul>
                </div>
            ) : null}
            {disciplines !== null && disciplines.length > 0 ? (
                <div
                    className={classNames([
                        styles.col,
                        {
                            [colClassName]: colClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage
                            {...messages.disciplines}
                            values={{ count: disciplines.length }}
                        />
                    </h4>
                    <ul>
                        {disciplines.map(discipline => (
                            <li key={`discipline-${discipline.id}`}>{discipline.name}</li>
                        ))}
                    </ul>
                </div>
            ) : null}
        </div>
    </div>
);
EventDetails.propTypes = propTypes;
EventDetails.defaultProps = defaultProps;

export default EventDetails;
