import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import Carousel from './Carousel';

const messages = defineMessages({
    title: {
        id: 'content.events_carousel',
        defaultMessage: 'Explorez les événements',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    morePage: AppPropTypes.page,
    eventsPage: AppPropTypes.page,
    isPreview: PropTypes.bool,
};

const defaultProps = {
    title: null,
    morePage: null,
    eventsPage: null,
    isPreview: false,
};

const EventsCarousel = ({
    intl,
    urlGenerator,
    id,
    title,
    morePage,
    eventsPage,
    isPreview,
    ...props
}) => (
    <Carousel
        id={id}
        title={title || intl.formatMessage(messages.title)}
        url={urlGenerator.route('api.events_carousel', {
            block: id,
        })}
        morePage={morePage || eventsPage}
        isPreview={isPreview}
        isFullWidth={false}
        hideOnEmpty
        withoutPagination
        {...props}
    />
);

EventsCarousel.propTypes = propTypes;
EventsCarousel.defaultProps = defaultProps;

const WithStateContainer = connect(({ site = null }) => ({
    eventsPage:
        site !== null
            ? Object.keys(site.pages).find((url) => site.pages[url].handle === 'events') || null
            : null,
}))(EventsCarousel);
const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);
const WithIntlContainer = injectIntl(WithUrlGeneratorContainer);
export default WithIntlContainer;
