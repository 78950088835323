import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl';

import MagazineIcon from '../icons/Magazine';

import styles from '../../../styles/buttons/more.scss';

const messages = defineMessages({
    readMore: {
        id: 'content.button_read_more',
        defaultMessage: 'Lire la suite +',
    },
    viewMore: {
        id: 'content.button_view_more',
        defaultMessage: 'Voir la suite +',
    },
});

const propTypes = {
    href: PropTypes.string,
    action: PropTypes.oneOf(['read', 'view']),
    purple: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    href: null,
    action: 'read',
    purple: false,
    className: null,
};

const MoreButton = ({
    href, action, purple, className,
}) => (
    <Link
        to={href || '#'}
        className={classNames([
            styles.container,
            {
                [styles.purple]: purple,
                [className]: className !== null,
            },
        ])}
    >
        <MagazineIcon eyeColor="#fff" className={styles.icon} />
        <span className={styles.label}>
            <FormattedMessage {...(action === 'read' ? messages.readMore : messages.viewMore)} />
        </span>
    </Link>
);

MoreButton.propTypes = propTypes;
MoreButton.defaultProps = defaultProps;

export default MoreButton;
