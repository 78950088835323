import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    white: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    white: false,
    className: null,
};

const FacebookIcon = ({ color, white, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        xmlSpace="preserve"
        className={className}
    >
        <polygon fill={white ? '#454896' : '#fff'} points="30.7,40 12.1,40 12.1,2.8 40,7.4" />
        <path
            fill={color}
            d="M40,6c0,9.3,0,18.7,0,28c0,0.1-0.1,0.2-0.1,0.3c-0.6,2.8-2.3,4.7-5.1,5.5c-0.3,0.1-0.6,0.1-0.8,0.2c-2.6,0-5.2,0-7.7,0c0-0.1,0-0.2,0-0.3c0-4.8,0-9.6,0-14.3c0-0.1,0-0.2,0-0.4c0.2,0,0.3,0,0.4,0c2,0,3.9,0,5.9,0c0.3,0,0.4-0.1,0.4-0.4c0-2,0-4,0-5.9c0-0.1,0-0.2,0-0.4c-2.2,0-4.4,0-6.6,0c0-1,0-2,0-3c0.1-0.9,0.7-1.4,1.6-1.4c1.5,0,3.1,0,4.6,0c0.1,0,0.3,0,0.4,0c0-2.2,0-4.4,0-6.6c-0.1,0-0.2,0-0.3,0c-1.6,0-3.3,0-4.9,0c-1.4,0-2.8,0.4-3.9,1.2c-2,1.4-3.2,3.4-3.6,5.8C20,15.4,20,16.6,20,17.8c0,0.1,0,0.2,0,0.4c-1.7,0-3.3,0-4.9,0c0,0.1,0,0.2,0,0.3c0,2,0,4.1,0,6.1c0,0.3,0.1,0.3,0.3,0.3c1.4,0,2.8,0,4.2,0c0.1,0,0.2,0,0.4,0C20,30,20,35,20,40c-4.7,0-9.3,0-14,0c-0.1,0-0.2-0.1-0.3-0.1c-2.8-0.6-4.7-2.3-5.5-5.1C0.1,34.6,0.1,34.3,0,34c0-9.3,0-18.7,0-28c0-0.1,0.1-0.3,0.1-0.4c0.6-2.7,2.3-4.5,5-5.3C5.4,0.1,5.7,0.1,6,0c9.3,0,18.7,0,28,0c0.1,0,0.2,0.1,0.2,0.1c2.9,0.6,4.7,2.3,5.6,5.1C39.9,5.5,39.9,5.7,40,6z"
        />
    </svg>
);

FacebookIcon.propTypes = propTypes;
FacebookIcon.defaultProps = defaultProps;

export default FacebookIcon;
