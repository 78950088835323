import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import Button from '../buttons/Button';

import styles from '../../../styles/partials/district-details.scss';

const messages = defineMessages({
    address: {
        id: 'content.details_address',
        defaultMessage: 'Adresse',
    },
    links: {
        id: 'content.details_links',
        defaultMessage: '{count, plural, one {Lien} other {Liens}}',
    },
    aboutLabel: {
        id: 'content.district_about_button',
        defaultMessage: 'En savoir plus',
    },
});

const propTypes = {
    address: AppPropTypes.address,
    links: AppPropTypes.links,
    aboutUrl: PropTypes.string,
    className: PropTypes.string,
    detailsClassName: PropTypes.string,
};

const defaultProps = {
    address: null,
    links: null,
    aboutUrl: null,
    className: null,
    detailsClassName: null,
};

const DistrictDetails = ({
    address, links, aboutUrl, className, detailsClassName,
}) => (
    <div
        className={classNames({
            [styles.container]: true,
            [className]: className !== null,
        })}
    >
        <div className={styles.inner}>
            {address !== null ? (
                <div
                    className={classNames([
                        styles.details,
                        {
                            [detailsClassName]: detailsClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage {...messages.address} />
                    </h4>
                    {address.address !== null ? (
                        <div className={styles.address}>{address.address}</div>
                    ) : null}
                    {address.city !== null ? (
                        <div className={styles.city}>{address.city}</div>
                    ) : null}
                    {address.postalCode !== null ? (
                        <div className={styles.postalCode}>{address.postalCode}</div>
                    ) : null}
                </div>
            ) : null}

            {links !== null && links.length > 0 ? (
                <div
                    className={classNames([
                        styles.details,
                        {
                            [detailsClassName]: detailsClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage {...messages.links} values={{ count: links.length }} />
                    </h4>
                    <ul>
                        {links.map(link => (
                            <li key={`link-${link.url}`}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer">{link.label || link.url}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}

            {aboutUrl !== null ? (
                <div className={classNames([styles.details, styles.about])}>
                    <Button href={aboutUrl} external red>{messages.aboutLabel}</Button>
                </div>
            ) : null}
        </div>
    </div>
);

DistrictDetails.propTypes = propTypes;
DistrictDetails.defaultProps = defaultProps;

export default DistrictDetails;
