import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#fff',
    className: null,
};

const Logo = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="129px"
        height="34.7px"
        viewBox="0 0 129 34.7"
        xmlSpace="preserve"
        className={className}
    >
        <g>
            <rect x="67.9" y="21.7" fill={color} width="3.6" height="12.7" />
            <polygon
                fill={color}
                points="81.6,21.7 79.6,34.5 83,34.5 84,26.4 85,34.5 88.5,34.5 86.4,21.7"
            />
            <polygon fill={color} points="92.2,21.7 94.3,34.5 97.5,34.5 95.9,21.7" />
            <polygon fill={color} points="98,34.5 101.2,34.5 103.3,21.7 99.6,21.7" />
            <polygon
                fill={color}
                points="108.7,21.7 106.6,34.5 110,34.5 111,26.4 112.1,34.5 115.5,34.5 113.4,21.7"
            />
            <rect x="120.5" y="21.7" fill={color} width="3.6" height="12.7" />
            <polygon
                fill={color}
                points="71.7,0 68.1,0 68.1,12.7 76.5,12.7 76.5,9.2 71.7,9.2"
            />
            <polygon
                fill={color}
                points="84.4,0 85.3,6.4 83.1,6.4 83.9,0 80.7,0 78.7,12.7 82.3,12.7 82.7,9.8 85.7,9.8 86.1,12.7 89.7,12.7 87.7,0"
            />
            <polygon
                fill={color}
                points="100.3,12.7 102.4,0 98.9,0 97.9,8.1 96.9,0 93.5,0 95.5,12.7"
            />
            <polygon
                fill={color}
                points="111.5,0 112.3,6.4 110.1,6.4 110.9,0 107.7,0 105.7,12.7 109.3,12.7 109.7,9.8 112.7,9.8 113.1,12.7 116.7,12.7 114.7,0"
            />
            <polygon
                fill={color}
                points="124.3,0 120.6,0 120.6,12.7 129,12.7 129,9.2 124.3,9.2"
            />
            <path
                fill={color}
                d="M6.5,23.2l-1.6-0.9c-0.7-0.4-1.1-1.2-1.1-2.2v-0.4c0-1,0.4-1.4,1.2-1.4c0.8,0,1.1,0.5,1.2,1.3v1.8h3.6v-1.7c0-3.4-1.5-4.9-4.7-4.9c-3.2,0-4.8,1.5-4.8,4.9V20c0,0.7,0,1.3,0.1,1.7c0.1,0.4,0.2,0.9,0.4,1.3c0.4,0.9,1.2,1.6,2.5,2.6l1.8,1.2c0.5,0.3,0.8,0.7,0.9,1.1c0.1,0.4,0.2,0.9,0.2,1.7v0.1c0,1-0.3,1.5-1.3,1.5c-1,0-1.3-0.5-1.3-1.6v-1.5H0v1.5c0,1.7,0.4,3,1.1,3.8c0.8,0.8,2,1.3,3.8,1.3c3.4,0,4.9-1.7,4.9-5V29c0-1.3-0.3-2.4-0.7-3.3C8.6,24.8,7.8,24,6.5,23.2z"
            />
            <rect x="14" y="15" fill={color} width="3.6" height="19.5" />
            <path
                fill={color}
                d="M26.9,14.7L26.9,14.7c-1.9,0-3.2,0.5-3.9,1.5c-0.7,1-1,2.2-1,3.8v9.3c0,1.6,0.3,2.8,1,3.8c0.7,1,2,1.5,3.9,1.5v0c3.2,0,4.9-1.7,4.9-5.2v-5.8h-5v3.5h1.3v2.3c0,1.1-0.4,1.7-1.2,1.7c-0.3,0-0.6-0.1-0.8-0.3c-0.4-0.4-0.4-0.8-0.4-1.5v-9.3c0-1.2,0.4-1.8,1.3-1.8c0.8,0,1.2,0.6,1.2,1.7v1.4h3.6v-1.4C31.8,16.5,30.2,14.7,26.9,14.7z"
            />
            <polygon
                fill={color}
                points="43.4,15 43.4,26.4 40.2,15 36.1,15 36.1,34.5 39.5,34.5 39.5,22.6 43.1,34.5 46.9,34.5 46.9,15"
            />
            <polygon
                fill={color}
                points="58.4,12.7 55.1,12.7 54,15 51,15 51,34.5 59.6,34.5 59.6,30.9 54.6,30.9 54.6,26.4 58.7,26.4 58.7,22.8 54.6,22.8 54.6,18.5 59.6,18.5 59.6,15 56.9,15"
            />
        </g>
    </svg>
);

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
