import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/buttons/burger.scss';

const propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

const defaultProps = {
    className: null,
    onClick: null,
};

const BurgerButton = ({ onClick, className }) => (
    <button
        type="button"
        className={classNames([styles.container, {
            [className]: className !== null,
        }])}
        onClick={onClick}
    >
        <span className={styles.bar} />
        <span className={styles.bar} />
        <span className={styles.bar} />
    </button>
);

BurgerButton.propTypes = propTypes;
BurgerButton.defaultProps = defaultProps;

export default BurgerButton;
