import React from 'react';
// import PropTypes from 'prop-types';

import * as AppPropTypes from '../../lib/PropTypes';

import PageMeta from '../partials/PageMeta';
import PageHeader from '../partials/PageHeader';
import Blocks from '../blocks/Blocks';

import styles from '../../../styles/pages/page.scss';

const propTypes = {
    page: AppPropTypes.page.isRequired,
};

const Page = ({ page }) => (
    <div className={styles.container}>
        <PageMeta {...page.share} />
        <div className={styles.inner}>
            <PageHeader title={page.title} />
        </div>
        <div className={styles.blocks}>
            <Blocks
                page={page}
                blocks={page.blocks || []}
                blocksAreSections
            />
        </div>
    </div>
);

Page.propTypes = propTypes;

export default React.memo(Page);
