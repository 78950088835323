/* globals gtag: true, GOOGLE_ANALYTICS_ID: true */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import Loadable from 'react-loadable';
import {
    Route, Switch, Redirect, withRouter,
} from 'react-router';
import { connect } from 'react-redux';
import { withUrlGenerator } from '../lib/react-container';

import * as AppPropTypes from '../lib/PropTypes';
import { getPageFromLocation, getPageUrlFromLocation } from '../lib/utils';
import MainLayout from './layouts/Main';
import Error from './pages/Error';
import PageLoader from './pages/PageLoader';

import '../../styles/main.global.scss';

const SplashPage = Loadable({
    loader: () => import('./pages/Splash'),
    loading: () => null,
});

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    history: AppPropTypes.history.isRequired,
    pages: PropTypes.objectOf(AppPropTypes.page).isRequired,
    categories: AppPropTypes.categories.isRequired,
    isSplash: PropTypes.bool,
};

const defaultProps = {
    isSplash: false,
};

class App extends Component {
    constructor(props) {
        super(props);

        this.onLocationChange = this.onLocationChange.bind(this);
        this.renderPage = this.renderPage.bind(this);
        this.renderError = this.renderError.bind(this);

        this.historyUnlisten = null;
        this.trackingTimeout = null;
    }

    componentDidMount() {
        const { history } = this.props;
        this.historyUnlisten = history.listen(this.onLocationChange);
    }

    componentWillUnmount() {
        if (this.historyUnlisten !== null) {
            this.historyUnlisten();
        }
        if (this.trackingTimeout) {
            clearTimeout(this.trackingTimeout);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    onLocationChange(location) {
        this.trackingTimeout = setTimeout(() => {
            if (typeof gtag !== 'undefined' && typeof GOOGLE_ANALYTICS_ID !== 'undefined') {
                gtag('config', GOOGLE_ANALYTICS_ID, {
                    page_path: location.pathname + location.search,
                });
            }
            this.trackingTimeout = null;
        }, 50);
    }

    renderPage({ location, match }) {
        const { pages, categories, urlGenerator } = this.props;
        const pageUrl = getPageUrlFromLocation(location, match, urlGenerator);
        const page = getPageFromLocation(pages, location, match, urlGenerator);
        const { category: categoryParam = null } = match.params;
        const category = categoryParam !== null
            ? categories.find(it => it.slug === categoryParam) || null
            : null;
        const query = !isEmpty(location.search)
            ? queryString.parse(location.search, {
                arrayFormat: 'bracket',
            })
            : null;

        return (
            <MainLayout page={page} category={category}>
                <PageLoader
                    page={page}
                    pageUrl={pageUrl}
                    category={category}
                    location={location.pathname}
                    query={query}
                />
            </MainLayout>
        );
    }

    // eslint-disable-next-line class-methods-use-this
    renderError() {
        return (
            <MainLayout>
                <Error />
            </MainLayout>
        );
    }

    render() {
        const { urlGenerator, isSplash } = this.props;

        return isSplash ? (
            <SplashPage />
        ) : (
            <Switch>
                <Route exact path="/" render={() => <Redirect to={urlGenerator.route('home')} />} />
                <Route exact path={urlGenerator.route('home')} render={this.renderPage} />
                <Route exact path={urlGenerator.route('districts.show')} render={this.renderPage} />
                <Route exact path={urlGenerator.route('events.show')} render={this.renderPage} />
                <Route
                    exact
                    path={urlGenerator.route('events_group.show')}
                    render={this.renderPage}
                />
                <Route
                    exact
                    path={urlGenerator.route('pages.list.with_category')}
                    render={this.renderPage}
                />
                <Route
                    exact
                    path={urlGenerator.route('pages.show.with_parent')}
                    render={this.renderPage}
                />
                <Route exact path={urlGenerator.route('pages.show')} render={this.renderPage} />
                <Route path="*" render={this.renderError} />
            </Switch>
        );
    }
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

const WithStateContainer = connect(({ site }) => ({
    pages: site.pages,
    categories: site.categories,
}))(App);
const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);
const WithRouterContainer = withRouter(WithUrlGeneratorContainer);
export default WithRouterContainer;
