import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Star from '../icons/Star';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/cards/contact.scss';

const propTypes = {
    url: PropTypes.string,
    name: PropTypes.string,
    image: AppPropTypes.image,
    categories: AppPropTypes.categories,
    small: PropTypes.bool,
    professional: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    url: null,
    name: null,
    image: null,
    categories: [],
    small: false,
    professional: false,
    className: null,
};

const ContactCard = ({ url, name, image, categories, small, professional, className }) => {
    const inner = (
        <span className={styles.inner}>
            <span className={styles.border}>
                <span className={styles.inner}>
                    <span
                        className={styles.image}
                        style={{
                            backgroundImage: image !== null ? `url("${image.sizes.small}")` : null,
                        }}
                    />
                    {professional && !small ? (
                        <span className={styles.professional}>
                            <Star />
                        </span>
                    ) : null}
                </span>
            </span>
            <span className={styles.label}>
                <span className={styles.name}>{name}</span>
                <span className={styles.categories}>
                    {categories.map((category) => (
                        <span key={`category-${category.id}`} className={styles.category}>
                            {category.name}
                        </span>
                    ))}
                </span>
            </span>
        </span>
    );
    const cardClassName = classNames([
        styles.container,
        {
            [styles.small]: small,
            [className]: className !== null,
        },
    ]);
    return url !== null ? (
        <Link to={url} className={cardClassName}>
            {inner}
        </Link>
    ) : (
        <div className={cardClassName}>{inner}</div>
    );
};

ContactCard.propTypes = propTypes;
ContactCard.defaultProps = defaultProps;

export default ContactCard;
