/* eslint-disable react/no-danger, react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import * as AppPropTypes from '../../lib/PropTypes';
import { addNonBreakingSpaces } from '../../lib/utils';

import styles from '../../../styles/blocks/logos.scss';

const propTypes = {
    title: PropTypes.string,
    logos: AppPropTypes.logos, // eslint-disable-line react/no-unused-prop-types
    isSection: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    logos: [],
    isSection: false,
    className: null,
};

class Logos extends PureComponent {
    static getDerivedStateFromProps(props) {
        return {
            logos: props.logos.filter(({ logo }) => logo !== null),
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            logos: null,
        };
    }

    render() {
        const { title, isSection, className } = this.props;
        const { logos } = this.state;
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.isSection]: isSection,
                        [className]: className !== null,
                    },
                ])}
            >
                <div className={styles.inner}>
                    {!isEmpty(title) ? (
                        <div className={styles.titleContainer}>
                            <h4 className={styles.title}>{addNonBreakingSpaces(title)}</h4>
                        </div>
                    ) : null}
                    <div className={styles.items}>
                        {logos.map(({ logo, name = null, url = null }, index) => (
                            <div className={styles.item} key={`logo-${index}`}>
                                {url !== null ? (
                                    <a
                                        href={url}
                                        className={styles.link}
                                        title={name}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        <img
                                            src={logo.sizes.small}
                                            className={styles.logo}
                                            alt={name}
                                        />
                                    </a>
                                ) : (
                                    <img
                                        src={logo.sizes.small}
                                        className={styles.logo}
                                        alt={name}
                                        title={name}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

Logos.propTypes = propTypes;
Logos.defaultProps = defaultProps;

export default Logos;
