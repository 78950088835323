/* eslint-disable indent */
import { SET_VIEW, SET_FILTERS_OPTIONS, SET_LIST } from '../actions/EventsActions';

const initialState = {
    view: 'list',
    filtersOptions: null,
    isLoading: false,
    items: null,
    currentPage: null,
    lastPage: 1,
    total: 0,
    pagesLoaded: [],
    lastResponse: null,
};

const EventsReducer = (state = initialState, action) => {
    // console.log('action', action.type, action.payload);

    switch (action.type) {
        case SET_VIEW:
            return {
                ...state,
                view: action.payload,
            };
        case SET_FILTERS_OPTIONS:
            return {
                ...state,
                filtersOptions: action.payload,
            };
        case SET_LIST: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};

export default EventsReducer;
