import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const InstagramIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            d="M8,0c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8C0,3.6,3.6,0,8,0z M8,13.6C8,13.6,8,13.6,8,13.6c0.5,0,1.1,0,1.6,0c0.5,0,1,0,1.5-0.1c1.3-0.3,2.1-1.1,2.4-2.3c0.1-0.5,0.1-1.1,0.1-1.6c0-1.1,0-2.1,0-3.2c0-0.5,0-1-0.1-1.5c-0.3-1.2-1-2-2.2-2.3c-0.5-0.1-1-0.2-1.5-0.2c-1.3,0-2.5,0-3.8,0c-0.4,0-0.9,0-1.3,0.1c-1.2,0.3-2,1-2.3,2.1c-0.1,0.5-0.2,1-0.2,1.5c0,1.4,0,2.8,0,4.3c0,0.4,0.1,0.7,0.2,1c0.3,0.9,0.8,1.6,1.7,1.9c0.5,0.2,1.1,0.3,1.6,0.3C6.5,13.6,7.3,13.6,8,13.6z"
        />
        <path
            fill={color}
            d="M8,3.3c0.8,0,1.6,0,2.4,0.1c0.4,0,0.8,0.1,1.2,0.3C12.1,4,12.4,4.4,12.5,5c0.1,0.3,0.1,0.7,0.1,1c0,1.3,0,2.6,0,4c0,0.4,0,0.7-0.1,1.1c-0.2,0.8-0.8,1.3-1.6,1.5c-0.3,0.1-0.6,0.1-0.9,0.1c-1.3,0-2.7,0-4,0c-0.4,0-0.7,0-1.1-0.1c-0.8-0.2-1.3-0.8-1.5-1.6C3.4,10.4,3.4,10,3.4,9.5c0-1.2,0-2.3,0-3.5c0-0.4,0-0.7,0.1-1.1c0.2-0.8,0.8-1.3,1.6-1.4c0.5-0.1,1-0.1,1.6-0.1C7.1,3.3,7.5,3.4,8,3.3C8,3.4,8,3.3,8,3.3z M5.1,8c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9c0-1.6-1.3-2.9-2.9-2.9C6.4,5.1,5.1,6.4,5.1,8z M11,5.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C10.3,5.3,10.6,5.6,11,5.7z"
        />
        <path
            fill={color}
            d="M6.1,8c0-1,0.9-1.9,1.9-1.9C9,6.1,9.9,7,9.9,8C9.9,9,9,9.9,8,9.9C6.9,9.9,6.1,9,6.1,8z"
        />
    </svg>
);

InstagramIcon.propTypes = propTypes;
InstagramIcon.defaultProps = defaultProps;

export default InstagramIcon;
