/* globals dataLayer */

import React from 'react';

export const tracker = {
    trackEvent: (category, action, label) => {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'analyticsEvent',
                eventCategory: category,
                eventAction: action,
                eventLabel: label,
            });
        }
    },

    trackSocial: (network, action) => {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'analyticsSocial',
                socialNetwork: network,
                socialAction: action,
            });
        }
    },
};

const TrackingContext = React.createContext(tracker);

export const withTracking = ComponentWithTracking => props => (
    <TrackingContext.Consumer>
        {trackingProps => <ComponentWithTracking {...trackingProps} {...props} />}
    </TrackingContext.Consumer>
);

export default TrackingContext;
