import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import Dropdown from '../buttons/Dropdown';

import styles from '../../../styles/partials/filters.scss';

const propTypes = {
    options: AppPropTypes.filtersOptions,
    value: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.arrayOf(PropTypes.number),
        ]),
    ),
    className: PropTypes.string,
    colClassName: PropTypes.string,
    dropdownClassName: PropTypes.string,
    dropdownButtonClassName: PropTypes.string,
    dropdownMenuClassName: PropTypes.string,
    onChange: PropTypes.func,
};

const defaultProps = {
    options: [],
    value: null,
    className: null,
    colClassName: null,
    dropdownClassName: null,
    dropdownButtonClassName: null,
    dropdownMenuClassName: null,
    onChange: null,
};

class Filters extends PureComponent {
    constructor(props) {
        super(props);

        this.onDropdownOpen = this.onDropdownOpen.bind(this);
        this.onDropdownClose = this.onDropdownClose.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);

        this.refDropdowns = [];

        this.state = {
            dropdownOpenedIndex: null,
        };
    }

    componentDidUpdate(prevProps, { dropdownOpenedIndex: prevDropdownOpenedIndex }) {
        const { dropdownOpenedIndex } = this.state;
        const dropdownOpenedIndexChanged = prevDropdownOpenedIndex !== dropdownOpenedIndex;
        if (dropdownOpenedIndexChanged) {
            if (prevDropdownOpenedIndex !== null) {
                this.refDropdowns[prevDropdownOpenedIndex].close();
            }
        }
    }

    onDropdownOpen(e, option, index) {
        this.setState({
            dropdownOpenedIndex: index,
        });
    }

    onDropdownClose() {
        this.setState({
            dropdownOpenedIndex: null,
        });
    }

    onFilterChange(filterValue, filter) {
        const { value, onChange } = this.props;
        const newValue = {
            ...value,
            [filter.name]: filterValue,
        };
        if (onChange !== null) {
            onChange(newValue);
        }
    }

    render() {
        const {
            options,
            value,
            className,
            colClassName,
            dropdownClassName,
            dropdownButtonClassName,
            dropdownMenuClassName,
        } = this.props;

        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
            >
                <div className={styles.cols}>
                    {options.map((option, index) => (
                        <div
                            key={`option-${option.name}`}
                            className={classNames([
                                styles.col,
                                {
                                    [colClassName]: colClassName !== null,
                                },
                            ])}
                        >
                            <Dropdown
                                options={option.options}
                                label={option.label}
                                value={value !== null ? value[option.name] || null : null}
                                className={classNames([
                                    styles.dropdown,
                                    {
                                        [dropdownClassName]: dropdownClassName !== null,
                                    },
                                ])}
                                buttonClassName={classNames([
                                    styles.dropdownButton,
                                    {
                                        [dropdownButtonClassName]: dropdownButtonClassName !== null,
                                    },
                                ])}
                                dropdownClassName={classNames([
                                    styles.dropdownMenu,
                                    {
                                        [dropdownMenuClassName]: dropdownMenuClassName !== null,
                                    },
                                ])}
                                onChange={(val) => this.onFilterChange(val, option, index)}
                                onOpen={(e) => this.onDropdownOpen(e, option, index)}
                                onClose={(e) => this.onDropdownClose(e, option, index)}
                                closeOnSelect
                                ref={(ref) => {
                                    this.refDropdowns[index] = ref;
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

Filters.propTypes = propTypes;
Filters.defaultProps = defaultProps;

export default Filters;
