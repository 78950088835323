import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import { setFiltersOptions as setFiltersOptionsAction } from '../../actions/ContactsActions';
import ListFilters from './ListFilters';

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    filtersOptions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: AppPropTypes.label,
            options: AppPropTypes.toggleOptions,
        }),
    ),
    setFiltersOptions: PropTypes.func.isRequired,
};

const defaultProps = {
    filtersOptions: null,
};

const ContactsFilters = ({ urlGenerator, ...props }) => (
    <ListFilters {...props} filtersEndpoint={urlGenerator.route('api.contacts_filters')} />
);

ContactsFilters.propTypes = propTypes;
ContactsFilters.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ contacts }) => ({
        filtersOptions: contacts.filtersOptions,
    }),
    dispatch => ({
        setFiltersOptions: options => dispatch(setFiltersOptionsAction(options)),
    }),
)(ContactsFilters);
const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);
export default WithUrlGeneratorContainer;
