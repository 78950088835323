import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withUrlGenerator } from '../../lib/react-container';

// import * as AppPropTypes from '../../lib/PropTypes';
import { withListLoader, listPropType } from '../../lib/withListLoader';
import Button from '../buttons/Button';
import CardsList from '../partials/CardsList';

import styles from '../../../styles/blocks/suggested-contacts.scss';

const messages = defineMessages({
    title: {
        id: 'content.suggested_contacts_title',
        defaultMessage: 'Répertoire culturel',
    },
    button: {
        id: 'content.suggested_contacts_button',
        defaultMessage: 'Voir le répertoire',
    },
});

const propTypes = {
    title: PropTypes.string,
    list: listPropType.isRequired,
    directoryLink: PropTypes.string,
    isSection: PropTypes.bool,
    filters: PropTypes.object, // eslint-disable-line
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
};

const defaultProps = {
    title: null,
    directoryLink: null,
    isSection: false,
    filters: null,
    className: null,
    itemsClassName: null,
};

const SuggestedContacts = ({
    title, list, directoryLink, isSection, className,
}) => (
    <div
        className={classNames({
            [styles.container]: true,
            [styles.isSection]: isSection,
            [className]: className !== null,
        })}
    >
        <div className={styles.inner}>
            <h2 className={styles.title}>
                {!isEmpty(title) ? title : <FormattedMessage {...messages.title} />}
            </h2>
            <div className={styles.list}>
                <div className={styles.cards}>
                    <CardsList
                        items={list.items}
                        type="contact"
                        isLoading={list.isLoading}
                        loadingCards={4}
                        columns={4}
                    />
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    href={directoryLink || (list.lastResponse || {}).directoryLink || '#'}
                    className={styles.button}
                    red
                    withShadow
                >
                    {messages.button}
                </Button>
            </div>
        </div>
    </div>
);

SuggestedContacts.propTypes = propTypes;
SuggestedContacts.defaultProps = defaultProps;
SuggestedContacts.withoutMargin = true;

const WithListLoaderContainer = withListLoader(
    ({
        urlGenerator, directoryLink, filters, id,
    }) => `${urlGenerator.route('api.suggested_contacts', {
        block_or_district: id,
    })}?with_directory_link=${
        directoryLink !== null ? 'false' : 'true'
    }&filters=${JSON.stringify(filters)}`,
    null,
    {
        perPage: 8,
    },
)(SuggestedContacts);
const WithStateContainer = connect(({ site = null }) => ({
    directoryLink:
        site !== null
            ? Object.keys(site.pages).find(url => site.pages[url].handle === 'directory') || null
            : null,
}))(WithListLoaderContainer);
const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);

export default WithUrlGeneratorContainer;
