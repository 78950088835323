import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Img from 'laravel-image';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { addNonBreakingSpaces } from '../../lib/utils';

import styles from '../../../styles/cards/district.scss';

const messages = defineMessages({
    event: {
        id: 'content.district_events_count',
        defaultMessage: '{count, plural, =0 {Aucun événement} one {# événement} other {# événements}}',
    },
    location: {
        id: 'content.district_locations_count',
        defaultMessage: '{count, plural, =0 {Aucun site patrimonial} one {# site patrimonial} other {# sites patrimoniaux}}',
    },
    contact: {
        id: 'content.district_contacts_count',
        defaultMessage: '{count, plural, =0 {Aucun acteur culturel} one {# acteur culturel} other {# acteurs culturels}}',
    },
});

const propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    image: AppPropTypes.image,
    stats: AppPropTypes.districtStats,
    small: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    image: null,
    stats: null,
    small: false,
    className: null,
};

const DistrictCard = ({
    url,
    title,
    image,
    stats,
    small,
    className,
}) => (
    <Link
        to={url}
        className={classNames([
            styles.container,
            {
                [styles.small]: small,
                [className]: className !== null,
            },
        ])}
    >
        <strong className={styles.title}>{addNonBreakingSpaces(title)}</strong>
        <span
            className={styles.image}
            style={{
                backgroundImage: image !== null ? `url("${Img.url(image.url, {
                    blue: true,
                    small: true,
                })}")` : null,
            }}
        />
        <span className={styles.stats}>
            {stats !== null && stats.events > 0 ? (
                <span className={styles.line}>
                    <FormattedMessage {...messages.event} values={{ count: stats.events }} />
                </span>
            ) : null}
            {stats !== null && stats.locations > 0 ? (
                <span className={styles.line}>
                    <FormattedMessage {...messages.location} values={{ count: stats.locations }} />
                </span>
            ) : null}
            {stats !== null && stats.contacts > 0 ? (
                <span className={styles.line}>
                    <FormattedMessage {...messages.contact} values={{ count: stats.contacts }} />
                </span>
            ) : null}
        </span>
    </Link>
);

DistrictCard.propTypes = propTypes;
DistrictCard.defaultProps = defaultProps;

export default DistrictCard;
