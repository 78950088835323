import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import * as AppPropTypes from '../../lib/PropTypes';
import { setAnimationComplete as setAnimationCompleteAction } from '../../actions/HomeSplashActions';
import Blocks from '../blocks/Blocks';
import Lottie from './Lottie';

import styles from '../../../styles/partials/home-splash.scss';

const propTypes = {
    page: AppPropTypes.page,
    blocks: AppPropTypes.blocks,
    isSmall: PropTypes.bool,
    animationComplete: PropTypes.bool,
    setAnimationComplete: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    page: null,
    blocks: null,
    animationComplete: false,
    isSmall: false,
    className: null,
};

class HomeSplash extends PureComponent {
    constructor(props) {
        super(props);

        this.onAnimationsLoaded = this.onAnimationsLoaded.bind(this);

        this.onLogoReady = this.onLogoReady.bind(this);
        this.onLogoComplete = this.onLogoComplete.bind(this);

        this.onMapReady = this.onMapReady.bind(this);
        this.onMapEnterFrame = this.onMapEnterFrame.bind(this);
        this.onMapComplete = this.onMapComplete.bind(this);

        this.logoEventListeners = [
            {
                eventName: 'DOMLoaded',
                callback: this.onLogoReady,
            },
            {
                eventName: 'complete',
                callback: this.onLogoComplete,
            },
        ];

        this.mapEventListeners = [
            {
                eventName: 'DOMLoaded',
                callback: this.onMapReady,
            },
            {
                eventName: 'enterFrame',
                callback: this.onMapEnterFrame,
            },
            {
                eventName: 'complete',
                callback: this.onMapComplete,
            },
        ];

        this.refLogo = null;
        this.refMap = null;

        this.state = {
            animations: null,
            isPaused: true,
            logoReady: false,
            mapReady: false,
            logoComplete: props.animationComplete,
            mapComplete: props.animationComplete,
            blocksVisible: props.animationComplete,
        };
    }

    componentDidMount() {
        import('../../../animations/index')
            .then(({ default: animations }) => animations)
            .then(this.onAnimationsLoaded);
    }

    componentDidUpdate(
        { isSmall: prevIsSmall },
        {
            logoReady: prevLogoReady,
            mapReady: prevMapReady,
            logoComplete: prevLogoComplete,
            mapComplete: prevMapComplete,
        },
    ) {
        const { isSmall, animationComplete } = this.props;
        const { logoReady, mapReady, logoComplete, mapComplete } = this.state;
        const logoReadyChanged = prevLogoReady !== logoReady;
        const mapReadyChanged = prevMapReady !== mapReady;
        if ((logoReadyChanged || mapReadyChanged) && mapReady && logoReady) {
            if (!animationComplete) {
                this.startAnimation();
            } else {
                this.gotoAnimationEnd();
            }
        }

        const logoCompleteChanged = prevLogoComplete !== logoComplete;
        const mapCompleteChanged = prevMapComplete !== mapComplete;
        if ((logoCompleteChanged || mapCompleteChanged) && mapComplete && logoComplete) {
            const { setAnimationComplete } = this.props;
            setAnimationComplete(true);
        }

        const isSmallChanged = prevIsSmall !== isSmall;
        if (isSmallChanged && animationComplete) {
            this.gotoAnimationEnd();
        }
    }

    onAnimationsLoaded(animations) {
        this.setState({
            animations,
        });
    }

    onLogoReady() {
        this.setState({
            logoReady: true,
        });
    }

    onMapReady() {
        this.setState({
            mapReady: true,
        });
    }

    // eslint-disable-next-line
    onMapEnterFrame(e) {
        const { blocksVisible } = this.state;
        const progress = e.currentTime / e.totalTime;
        if (progress > 0.6 && !blocksVisible) {
            this.setState({
                blocksVisible: true,
            });
        }
    }

    onLogoComplete() {
        this.setState(({ mapComplete, isPaused }) => ({
            logoComplete: true,
            isPaused: mapComplete ? true : isPaused,
        }));
    }

    onMapComplete() {
        this.setState(({ logoComplete, isPaused }) => ({
            mapComplete: true,
            isPaused: logoComplete ? true : isPaused,
        }));
    }

    startAnimation() {
        // console.log('start'); // eslint-disable-line
        this.setState({
            isPaused: false,
        });
    }

    gotoAnimationEnd() {
        this.refLogo.goToAndStop(this.refLogo.getDuration(true), true);
        this.refMap.goToAndStop(this.refMap.getDuration(true), true);
    }

    render() {
        const { page, blocks, isSmall, animationComplete, className } = this.props;
        const { isPaused, blocksVisible, animations } = this.state;
        const logoAnimation = animations !== null ? animations.logo : null;
        const mapAnimation = animations !== null ? animations.map : null;

        return (
            <div
                className={classNames({
                    [styles.container]: true,
                    [className]: className !== null,
                })}
            >
                <div className={styles.titleContainer}>
                    <h1 className={styles.title}>Signé Laval</h1>
                    <div
                        className={classNames([
                            styles.animation,
                            {
                                [styles.isSmall]: isSmall,
                                [styles.isComplete]: animationComplete,
                            },
                        ])}
                    >
                        {logoAnimation !== null ? (
                            <Lottie
                                options={{
                                    loop: false,
                                    autoplay: false,
                                    animationData: logoAnimation,
                                }}
                                speed={2}
                                isPaused={isPaused}
                                eventListeners={this.logoEventListeners}
                                isClickToPauseDisabled
                                animRef={(ref) => {
                                    this.refLogo = ref;
                                }}
                            />
                        ) : null}
                    </div>
                </div>
                {blocks !== null && blocks.length > 0 ? (
                    <div
                        className={classNames([
                            styles.blocksContainer,
                            {
                                [styles.visible]: blocksVisible,
                            },
                        ])}
                    >
                        <Blocks page={page} blocks={blocks} isHomePage />
                    </div>
                ) : null}
                <div className={styles.background}>
                    {mapAnimation !== null ? (
                        <Lottie
                            options={{
                                loop: false,
                                autoplay: false,
                                animationData: mapAnimation,
                            }}
                            speed={2}
                            isPaused={isPaused}
                            eventListeners={this.mapEventListeners}
                            isClickToPauseDisabled
                            animRef={(ref) => {
                                this.refMap = ref;
                            }}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

HomeSplash.propTypes = propTypes;
HomeSplash.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ layout, homeSplash }) => ({
        isSmall: layout.size.width < 760,
        ...homeSplash,
    }),
    (dispatch) => ({
        setAnimationComplete: (value) => dispatch(setAnimationCompleteAction(value)),
    }),
)(HomeSplash);
export default WithStateContainer;
