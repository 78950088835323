import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AnswerIcon from '../../icons/Answer';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../../styles/blocks/quiz/quiz-button.scss';

const propTypes = {
    answer: PropTypes.object.isRequired, // eslint-disable-line
    index: PropTypes.number.isRequired,
    neutral: PropTypes.bool,
    neutralAnswer: PropTypes.bool,
    hasImage: PropTypes.bool,
    hasAnswered: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    neutralAnswer: false,
    hasImage: false,
    hasAnswered: false,
    neutral: false,
    className: null,
};

const QuizButton = ({
    answer,
    index,
    neutral,
    neutralAnswer,
    hasImage,
    hasAnswered,
    onClick,
    className,
}) => {
    const onClickAnswer = useCallback(
        (e) => {
            onClick(e, answer, index);
        },
        [onClick, answer, index],
    );
    return (
        <li
            className={classNames([
                styles.container,
                {
                    [styles.withImage]: hasImage,
                    [styles.good]: !neutral && hasAnswered && answer.good,
                    [styles.bad]: !neutral && hasAnswered && !answer.good,
                    [styles.discarded]: hasAnswered,
                    [styles.neutralAnswer]: neutralAnswer,
                    [className]: className !== null,
                },
            ])}
        >
            <button type="button" className={styles.button} onClick={onClickAnswer}>
                {hasImage ? (
                    <span
                        className={styles.image}
                        style={{
                            backgroundImage:
                                answer.image !== null ? `url("${answer.image.sizes.small}")` : null,
                        }}
                    />
                ) : null}
                <span className={styles.label}>
                    <span className={styles.innerLabel}>{answer.label}</span>
                    <AnswerIcon good={answer.good} className={styles.icon} />
                </span>
            </button>
        </li>
    );
};

QuizButton.propTypes = propTypes;
QuizButton.defaultProps = defaultProps;

export default QuizButton;
