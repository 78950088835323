import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { isMessage } from '../../lib/utils';

import styles from '../../../styles/partials/back-bar.scss';

const propTypes = {
    url: PropTypes.string.isRequired,
    label: AppPropTypes.label.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const BackBar = ({ url, label, className }) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <Link to={url} className={styles.link}>
            {isMessage(label) ? <FormattedMessage {...label} /> : label}
        </Link>
    </div>
);

BackBar.propTypes = propTypes;
BackBar.defaultProps = defaultProps;

export default BackBar;
