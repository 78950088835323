import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    // color: '#E95949',
    className: null,
};

const AlphaIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="17.8px"
        height="18.7px"
        viewBox="0 0 17.8 18.7"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            d="M5.6,15.1l-1.2,3.6H0L6.7,0h4.5l6.7,18.7h-4.5l-1.2-3.6H5.6z M11,11.4L8.9,5.2l-2.1,6.2H11z"
        />
    </svg>
);

AlphaIcon.propTypes = propTypes;
AlphaIcon.defaultProps = defaultProps;

export default AlphaIcon;
