import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import Helmet from 'react-helmet';

const propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            url: PropTypes.string,
            size: PropTypes.shape({
                width: PropTypes.number,
                height: PropTypes.number,
            }),
        }),
    ]),
    canonical: PropTypes.bool,
    noIndex: PropTypes.bool,
};

const defaultProps = {
    description: null,
    image: null,
    canonical: false,
    noIndex: false,
};

const PageMeta = ({
    title, description, image, url, canonical, noIndex,
}) => (
    <Helmet>
        <title>{title}</title>
        {!isEmpty(description) ? <meta name="description" content={description} /> : null}
        <meta property="og:title" content={title} />
        {!isEmpty(image) && !isObject(image) ? <meta property="og:image" content={image} /> : null}
        {isObject(image) && !isEmpty(image.url || null) ? (
            <meta property="og:image" content={image.url} />
        ) : null}
        {isObject(image) && !isEmpty(image.size || null) ? (
            <meta property="og:image:width" content={image.size.width} />
        ) : null}
        {isObject(image) && !isEmpty(image.size || null) ? (
            <meta property="og:image:height" content={image.size.height} />
        ) : null}
        <meta property="og:type" content="website" />
        {!isEmpty(description) ? <meta property="og:description" content={description} /> : null}
        <meta property="og:url" content={url} />
        {canonical ? <link rel="canonical" href={url} /> : null}
        {noIndex ? <meta name="robots" content="noindex" /> : null}
    </Helmet>
);

PageMeta.propTypes = propTypes;
PageMeta.defaultProps = defaultProps;

export default PageMeta;
