import { SET_FILTERS_OPTIONS, SET_VIEW, SET_MAX_LETTERS } from '../actions/ContactsActions';

const initialState = {
    filtersOptions: null,
    view: 'type',
    maxLetters: 4,
};

const ContactsReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_VIEW:
        return {
            ...state,
            view: action.payload,
        };
    case SET_MAX_LETTERS:
        return {
            ...state,
            maxLetters: action.payload,
        };
    case SET_FILTERS_OPTIONS:
        return {
            ...state,
            filtersOptions: action.payload,
        };
    default:
        return state;
    }
};

export default ContactsReducer;
