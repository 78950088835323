/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';
import { stringify as createQueryString } from 'query-string';
import hoistStatics from 'hoist-non-react-statics';

import { loadList } from './requests';

const listShape = {
    isLoading: PropTypes.bool.isRequired,
    items: PropTypes.array,
    lastPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number,
    total: PropTypes.number.isRequired,
    pagesLoaded: PropTypes.arrayOf(PropTypes.number),
    lastResponse: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const listPropTypes = {
    ...listShape,
    setList: PropTypes.func.isRequired,
};

export const listPropType = PropTypes.shape({
    ...listShape,
    items: listShape.items.isRequired,
    currentPage: listShape.currentPage.isRequired,
    pagesLoaded: listShape.pagesLoaded.isRequired,
});

const defaultMapPropsToUrl = ({ url }) => url || null;

const defaultList = {
    isLoading: false,
    items: null,
    lastPage: 1,
    total: 0,
    currentPage: null,
    lastResponse: null,
};

// prettier-ignore
const listHasChanged = (prevList, list) => (
    list !== prevList
    && (list === null
        || prevList === null
        || Object.keys(listShape).reduce(
            (hasChanged, key) => (
                hasChanged || (list[key] || null) !== (prevList[key] || null)
            ),
            false,
        ))
);

export const withListLoader = (
    mapPropsToUrl = defaultMapPropsToUrl,
    mapPropsToList = null,
    opts,
) => {
    const options = {
        perPage: null,
        pageParamName: 'page',
        perPageParamName: 'per_page',
        ...opts,
    };

    const {
        perPage: PER_PAGE,
        pageParamName: PAGE_PARAM_NAME,
        perPageParamName: PER_PAGE_PARAM_NAME,
    } = options;

    // prettier-disable
    return (WrappedComponent) => {
        class WithListLoader extends Component {
            static getDerivedStateFromProps(props, state) {
                const newUrl = mapPropsToUrl !== null ? mapPropsToUrl(props) : null;
                let newList = null;
                if (mapPropsToList !== null) {
                    newList = mapPropsToList(props);
                    if (newList !== null) {
                        PropTypes.checkPropTypes(listPropTypes, newList, 'list', 'WithListLoader');
                    }
                }
                const urlChanged = newUrl !== state.url;
                const listChanged = listHasChanged(state.list, newList);

                // if (listChanged) {
                //     console.log(state.list);
                // }

                if (urlChanged || listChanged) {
                    const newState = urlChanged
                        ? {
                              ...defaultList,
                              list:
                                  state.list !== null
                                      ? {
                                            ...state.list,
                                            ...defaultList,
                                        }
                                      : null,
                              url: newUrl,
                          }
                        : {};
                    if (listChanged) {
                        newState.list = pick(newList, Object.keys(listPropTypes));
                    }
                    // if (newState.list) {
                    //     const { setList } = newState.list || {};
                    //     console.log(setList);
                    //     // setList(newState);
                    // }
                    return newState;
                }
                return null;
            }

            constructor(props) {
                super(props);

                this.loadItems = this.loadItems.bind(this);
                this.loadNextPage = this.loadNextPage.bind(this);
                this.loadPage = this.loadPage.bind(this);
                this.resetItems = this.resetItems.bind(this);

                this.onPageLoaded = this.onPageLoaded.bind(this);
                this.onItemsLoaded = this.onItemsLoaded.bind(this);
                this.onLoadError = this.onLoadError.bind(this);

                this.state = {
                    url: null,
                    list: null,
                    ...defaultList,
                };
            }

            componentDidMount() {
                const url = this.getUrl();
                // console.log('mount', this.props);
                // Always load on mount because back button
                if (url !== null) {
                    this.loadItems();
                }
            }

            componentDidUpdate(prevProps, { url: prevUrl }) {
                const { url } = this.state;
                const urlChanged = prevUrl !== url;
                if (urlChanged && url !== null) {
                    this.loadItems();
                }
            }

            componentWillUnmount() {
                // console.log('unmount', this.props);
                // Always clear page state on unmount because back button...
                this.setList({
                    currentPage: null,
                    lastPage: 0,
                    pagesLoaded: [],
                    items: [],
                    isLoading: false,
                    lastResponse: null,
                });
            }

            onPageLoaded(response) {
                const {
                    data: newItems = [],
                    current_page: currentPage = 1,
                    last_page: lastPage = 1,
                    total = 0,
                } = this.getItemsResponse(response) || {};
                const { items: stateItems, list } = this.state;
                const items = this.isControlled() ? list.items : stateItems;
                const nextItems = sortBy(
                    [
                        ...(items || []).filter(({ page }) => page !== currentPage),
                        {
                            page: currentPage,
                            items: [...newItems],
                        },
                    ],
                    ({ page }) => page,
                );
                this.setList({
                    currentPage: nextItems.length === total ? lastPage : currentPage,
                    lastPage,
                    total,
                    items: nextItems,
                    isLoading: false,
                    lastResponse: response,
                });
            }

            onItemsLoaded(response) {
                const res = this.getItemsResponse(response);

                if (!response) {
                    // console.log('error fetching list', response); // eslint-disable-line
                    return;
                }

                const {
                    data: newItems = [],
                    current_page: currentPage = 1,
                    last_page: lastPage = 1,
                    total = 0,
                } = res;

                const nextItems = [
                    {
                        page: currentPage,
                        items: [...newItems],
                    },
                ];
                this.setList({
                    currentPage: nextItems.length === total ? lastPage : currentPage,
                    lastPage,
                    total,
                    items: nextItems,
                    isLoading: false,
                    lastResponse: response,
                });
            }

            // eslint-disable-next-line class-methods-use-this
            onLoadError() {
                this.setList({
                    isLoading: false,
                });
            }

            getUrl(query = null) {
                const { url } = this.state;
                if (url === null) {
                    return null;
                }
                const cleanUrl = url.replace(/[&?]$/, '');
                // eslint-disable-next-line operator-linebreak
                const queryString =
                    query !== null
                        ? createQueryString(query, {
                              arrayFormat: 'bracket',
                          })
                        : null;
                const urlHasQuery = cleanUrl.indexOf('?') !== -1;
                return `${cleanUrl}${!urlHasQuery && !isEmpty(queryString) ? '?' : '&'}${
                    queryString || ''
                }`;
            }

            getItems() {
                const { list, items } = this.state;
                return this.isControlled() ? list.items || null : items;
            }

            getCurrentPage() {
                const { list, currentPage } = this.state;
                return this.isControlled() ? list.currentPage || null : currentPage;
            }

            getLastPage() {
                const { list, lastPage } = this.state;
                return this.isControlled() ? list.lastPage || null : lastPage;
            }

            getPerPage() {
                return isFunction(PER_PAGE) ? PER_PAGE(this.props) : PER_PAGE;
            }

            getPerPageParamName() {
                return isFunction(PER_PAGE_PARAM_NAME)
                    ? PER_PAGE_PARAM_NAME(this.props)
                    : PER_PAGE_PARAM_NAME;
            }

            getPageParamName() {
                return isFunction(PAGE_PARAM_NAME) ? PAGE_PARAM_NAME(this.props) : PAGE_PARAM_NAME;
            }

            getPageQuery(page = null) {
                const query = {
                    [this.getPageParamName()]: page || this.getCurrentPage() || 1,
                };
                const perPage = this.getPerPage();
                if (perPage !== null) {
                    query[this.getPerPageParamName()] = perPage;
                }
                return query;
            }

            // eslint-disable-next-line class-methods-use-this
            getItemsResponse(response) {
                return isArray(response) ? { data: response, total: response.length } : response;
            }

            getList() {
                // eslint-disable-next-line
                let { items, lastPage, currentPage, total, isLoading, lastResponse } = this.state;
                if (this.isControlled()) {
                    const { list } = this.state;
                    /* eslint-disable prefer-destructuring */
                    items = list.items || null;
                    lastPage = list.lastPage;
                    currentPage = list.currentPage || null;
                    total = list.total;
                    isLoading = list.isLoading;
                    lastResponse = list.lastResponse;
                    /* eslint-enable prefer-destructuring */
                }
                const safeItems = items || [];
                return {
                    isLoading,
                    items: safeItems.reduce(
                        (prevItems, itemsPage) => [...prevItems, ...itemsPage.items],
                        [],
                    ),
                    lastPage,
                    currentPage: currentPage || 1,
                    total,
                    pagesLoaded: safeItems.map(({ page }) => page),
                    lastResponse,
                };
            }

            setList(data) {
                if (this.isControlled()) {
                    // console.log('setlist', this.state, this.props);
                    const { list } = this.state;
                    const { setList = null, ...currentData } = list;
                    if (setList !== null) {
                        // console.log('oi', currentData, data);
                        setList({
                            ...currentData,
                            ...data,
                        });
                    } else {
                        this.setState({
                            ...currentData,
                            ...data,
                        });
                    }
                } else {
                    this.setState({
                        ...data,
                    });
                }
            }

            isControlled() {
                const { list } = this.state;
                return list !== null;
            }

            resetItems() {
                this.setList({
                    currentPage: null,
                    lastPage: 1,
                    total: 0,
                    items: null,
                    isLoading: false,
                });
            }

            loadRequest(query = null) {
                this.setList({
                    isLoading: true,
                });
                return loadList(this.getUrl(query)).catch(this.onLoadError);
            }

            loadNextPage() {
                const currentPage = this.getCurrentPage();
                const nextPage = currentPage !== null ? currentPage + 1 : 1;
                return this.loadPage(nextPage);
            }

            loadPage(nextPage) {
                const lastPage = this.getLastPage();
                if (nextPage > lastPage) {
                    return Promise.resolve();
                }
                const query = this.getPageQuery(nextPage);
                return this.loadRequest(query).then(this.onPageLoaded);
            }

            loadItems(query = null) {
                const pageQuery = this.getPageQuery();
                return this.loadRequest({
                    ...pageQuery,
                    ...query,
                }).then(this.onItemsLoaded);
            }

            render() {
                const list = this.getList();
                return (
                    <WrappedComponent
                        list={list}
                        loadItems={this.loadItems}
                        loadPage={this.loadPage}
                        loadNextPage={this.loadNextPage}
                        resetItems={this.resetItems}
                        {...this.props}
                    />
                );
            }
        }

        return hoistStatics(WithListLoader, WrappedComponent);
    };
};

export default withListLoader;
