import React from 'react';
// import PropTypes from 'prop-types';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import Carousel from './Carousel';

import styles from '../../../styles/blocks/featured-events-carousel.scss';

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const defaultProps = {};

const FeaturedEventsCarousel = ({ urlGenerator, ...props }) => (
    <Carousel
        className={styles.container}
        url={urlGenerator.route('api.featured_events')}
        itemMaxWidth={1000}
        cardsProps={{
            horizontal: true,
        }}
        posterCards={false}
        withoutPagination
        emptyCardClassName={styles.emptyCard}
        purplePage
        {...props}
    />
);

FeaturedEventsCarousel.propTypes = propTypes;
FeaturedEventsCarousel.defaultProps = defaultProps;

const WithUrlGeneratorContainer = withUrlGenerator()(FeaturedEventsCarousel);
export default WithUrlGeneratorContainer;
