import { SET_ANIMATION_COMPLETE } from '../actions/HomeSplashActions';

const initialState = {
    animationComplete: false,
};

const HomeSplashReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_ANIMATION_COMPLETE: {
        return {
            ...state,
            animationComplete: action.payload,
        };
    }
    default:
        return state;
    }
};

export default HomeSplashReducer;
