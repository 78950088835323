import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import { withListLoader, listPropType } from '../../lib/withListLoader';
import Card from '../cards/Card';
import Button from '../buttons/Button';
import CardsList from '../partials/CardsList';

import styles from '../../../styles/blocks/suggested-events.scss';

const messages = defineMessages({
    title: {
        id: 'content.suggested_events_title',
        defaultMessage: 'Événements',
    },
    button: {
        id: 'content.suggested_events_button',
        defaultMessage: 'Voir tous les événements',
    },
});

const propTypes = {
    title: AppPropTypes.label,
    list: listPropType.isRequired,
    eventsLink: PropTypes.string,
    withoutFeatured: PropTypes.bool,
    newWindow: PropTypes.bool,
    whitePage: PropTypes.bool,
    purplePage: PropTypes.bool,
    isSection: PropTypes.bool,
    filters: PropTypes.object, // eslint-disable-line
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
};

const defaultProps = {
    title: null,
    eventsLink: null,
    withoutFeatured: false,
    newWindow: false,
    whitePage: false,
    purplePage: false,
    isSection: false,
    filters: null,
    className: null,
    itemsClassName: null,
};

const SuggestedEvents = ({
    title,
    list,
    eventsLink,
    withoutFeatured,
    newWindow,
    whitePage,
    isSection,
    className,
}) => {
    const itemsCount = list.items.length;
    const hasFeatured = !withoutFeatured || itemsCount === 1;

    return list.isLoading || itemsCount > 0 ? (
        <div
            className={classNames({
                [styles.container]: true,
                [styles.isWhiteBackground]: !whitePage,
                [styles.isSection]: isSection,
                [className]: className !== null,
            })}
        >
            <div className={styles.inner}>
                <h2 className={styles.title}>
                    <span className={styles.text}>
                        {!isEmpty(title) ? title : <FormattedMessage {...messages.title} />}
                    </span>
                </h2>
                <div className={styles.list}>
                    {hasFeatured ? (
                        <div className={styles.featured}>
                            <Card
                                item={itemsCount > 0 ? list.items[0] : null}
                                type="event"
                                horizontal
                                external={newWindow}
                                isLoading={list.isLoading}
                            />
                        </div>
                    ) : null}
                    <div className={styles.cards}>
                        <CardsList
                            items={hasFeatured ? list.items.slice(1) : list.items}
                            type="event"
                            isLoading={list.isLoading}
                            external={newWindow}
                            loadingCards={3}
                        />
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        href={eventsLink || (list.lastResponse || {}).eventsLink || '#'}
                        className={styles.button}
                        red
                        withShadow
                    >
                        {messages.button}
                    </Button>
                </div>
            </div>
        </div>
    ) : null;
};

SuggestedEvents.propTypes = propTypes;
SuggestedEvents.defaultProps = defaultProps;
SuggestedEvents.withoutMargin = true;

const WithListLoaderContainer = withListLoader(
    ({ urlGenerator, eventsLink, filters, id }) =>
        `${urlGenerator.route('api.suggested_events', {
            block_or_district: id,
        })}?with_events_link=${eventsLink !== null ? 'false' : 'true'}&filters=${JSON.stringify(
            filters,
        )}`,
    null,
    {
        perPage: 4,
    },
)(SuggestedEvents);
const WithStateContainer = connect(({ site = null }) => ({
    eventsLink:
        site !== null
            ? Object.keys(site.pages).find((url) => site.pages[url].handle === 'events') || null
            : null,
}))(WithListLoaderContainer);
const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);

export default WithUrlGeneratorContainer;
