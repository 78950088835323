import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as AppPropTypes from '../../lib/PropTypes';
import { loadPage } from '../../lib/requests';
import { pascalCase } from '../../lib/utils';
import {
    setPage as setPageActions,
    setStatusCode as setStatusCodeActions,
} from '../../actions/SiteActions';
import ErrorPage from './Error';

import * as PageComponents from './index';

const propTypes = {
    pageUrl: PropTypes.string.isRequired,
    page: AppPropTypes.page,
    setPage: PropTypes.func.isRequired,
    statusCode: PropTypes.number,
    setStatusCode: PropTypes.func.isRequired,
};

const defaultProps = {
    page: null,
    statusCode: null,
};

class PageLoader extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        const pageChanged = nextProps.page !== prevState.page;
        if (pageChanged && nextProps.page !== null) {
            return {
                page: nextProps.page,
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.onPageLoaded = this.onPageLoaded.bind(this);
        this.onPageLoadError = this.onPageLoadError.bind(this);

        this.state = {
            page: props.page,
        };
    }

    componentDidMount() {
        const { statusCode } = this.props;
        const { page } = this.state;
        if (statusCode === null) {
            if (page === null) {
                this.loadPage();
            } else {
                window.scrollTo({ top: 0 });
            }
        }
    }

    componentDidUpdate({ pageUrl: prevPageUrl }, { page: prevPage }) {
        const { statusCode, pageUrl, page: askedPage } = this.props;
        const pageUrlChanged = prevPageUrl !== pageUrl;
        if (pageUrlChanged && askedPage === null && statusCode === null) {
            this.loadPage();
        }

        const { page } = this.state;
        const pageChanged = prevPage !== page;
        if (pageChanged && page !== null) {
            setTimeout(() => {
                // console.log('hackish stuff');
                window.scrollTo({ top: 0 });
            }, 300);
        }
    }

    onPageLoaded(page) {
        const { setPage } = this.props;
        const { url } = page;
        setPage(url, page);
    }

    onPageLoadError(e) {
        const { setStatusCode } = this.props;
        if (e.name === 'ResponseError') {
            setStatusCode(e.status);
        }
    }

    loadPage() {
        const { pageUrl } = this.props;
        loadPage(pageUrl.replace(/(\.json|\/)?$/, '.json'))
            .then(this.onPageLoaded)
            .catch(this.onPageLoadError);
    }

    render() {
        const { statusCode, pageUrl, setPage, setStatusCode, ...pageProps } = this.props;
        const { page } = this.state;

        if (statusCode !== null) {
            return <ErrorPage />;
        }

        if (page === null) {
            return null;
        }

        const PageComponent = PageComponents[pascalCase(page.type)];
        return <PageComponent {...pageProps} page={page} />;
    }
}

PageLoader.propTypes = propTypes;
PageLoader.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ site }) => ({
        statusCode: site.statusCode,
    }),
    (dispatch) => ({
        setPage: (url, page) => dispatch(setPageActions(url, page)),
        setStatusCode: (value) => dispatch(setStatusCodeActions(value)),
    }),
)(PageLoader);

export default WithStateContainer;
