/* eslint-disable react/button-has-type */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { isMessage } from '../../lib/utils';

import styles from '../../../styles/buttons/button.scss';

const propTypes = {
    type: PropTypes.string,
    href: PropTypes.string,
    external: PropTypes.bool,
    target: PropTypes.string,
    children: AppPropTypes.label,
    icon: PropTypes.node,
    iconPosition: PropTypes.oneOf(['left', 'right', 'inline']),
    disabled: PropTypes.bool,
    transparent: PropTypes.bool,
    red: PropTypes.bool,
    small: PropTypes.bool,
    withShadow: PropTypes.bool,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    onClick: PropTypes.func,
};

const defaultProps = {
    type: 'button',
    href: null,
    external: false,
    target: '_blank',
    children: null,
    icon: null,
    iconPosition: 'left',
    disabled: false,
    transparent: false,
    red: false,
    small: false,
    withShadow: false,
    className: null,
    iconClassName: null,
    labelClassName: null,
    onClick: null,
};

const Button = ({
    type,
    href,
    external,
    target,
    children,
    icon,
    iconPosition,
    disabled,
    transparent,
    red,
    small,
    withShadow,
    onClick,
    className,
    iconClassName,
    labelClassName,
}) => {
    const label = isMessage(children) ? <FormattedMessage {...children} /> : children;
    let content;
    if (icon !== null && (iconPosition === 'inline' || label === null)) {
        content = (
            <Fragment>
                <span
                    className={classNames([
                        styles.icon,
                        {
                            [iconClassName]: iconClassName !== null,
                        },
                    ])}
                >
                    {icon}
                </span>
                {label !== null ? (
                    <span
                        className={classNames([
                            styles.label,
                            {
                                [labelClassName]: labelClassName !== null,
                            },
                        ])}
                    >
                        {label}
                    </span>
                ) : null}
            </Fragment>
        );
    } else if (icon !== null) {
        content = (
            <span className={styles.cols}>
                <span className={classNames([styles.col, styles.colLeft])}>
                    {iconPosition === 'left' ? icon : null}
                </span>
                <span className={classNames([styles.col, styles.colCenter])}>{label}</span>
                <span className={classNames([styles.col, styles.colRight])}>
                    {iconPosition === 'right' ? icon : null}
                </span>
            </span>
        );
    } else {
        content = label;
    }
    const buttonClassNames = classNames([
        styles.container,
        {
            [styles.withIcon]: icon !== null,
            [styles.iconOnly]: icon !== null && label === null,
            [styles.transparent]: transparent,
            [styles.red]: red,
            [styles.small]: small,
            [styles.withShadow]: withShadow,
            [styles.isLink]: href !== null,
            [styles.disabled]: disabled,
            [className]: className !== null,
        },
    ]);
    if (href !== null) {
        return external ? (
            <a href={href} className={buttonClassNames} onClick={onClick} target={target}>
                {content}
            </a>
        ) : (
            <Link
                to={href}
                className={buttonClassNames}
                onClick={onClick}
            >
                {content}
            </Link>
        );
    }
    return (
        <button type={type} className={buttonClassNames} onClick={onClick} disabled={disabled}>
            {content}
        </button>
    );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
