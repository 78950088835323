import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CloseIcon from '../icons/Close';
import Button from './Button';

import styles from '../../../styles/buttons/close.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const CloseButton = ({ className, ...props }) => (
    <Button
        className={classNames([styles.container, {
            [className]: className !== null,
        }])}
        {...props}
    >
        <CloseIcon className={styles.icon} fat />
    </Button>
);

CloseButton.propTypes = propTypes;
CloseButton.defaultProps = defaultProps;

export default CloseButton;
