/* globals google: true */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import { loadVenues } from '../../lib/requests';
import { setVenues as setVenuesAction } from '../../actions/EventsMapActions';
import { withTracking } from '../../lib/TrackingContext';

import CustomMap from './CustomMap';
import EventsMapPopup from './EventsMapPopup';

import styles from '../../../styles/partials/events-map.scss';

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    trackEvent: PropTypes.func.isRequired,
    venues: AppPropTypes.venues,
    className: PropTypes.string,
    setVenues: PropTypes.func.isRequired,
};

const defaultProps = {
    venues: null,
    className: null,
};

class EventsMap extends PureComponent {
    static getMarkersFromItems(items) {
        return items.map(it => ({
            latitude: it.latitude,
            longitude: it.longitude,
            venue: it,
        }));
    }

    /* eslint-disable no-bitwise */
    static getMarkerPosition(marker) {
        const map = marker.getMap();
        const numTiles = 1 << map.getZoom();
        const projection = map.getProjection();
        const worldCoordinate = projection.fromLatLngToPoint(marker.getPosition());
        const pixelCoordinate = new google.maps.Point(
            worldCoordinate.x * numTiles,
            worldCoordinate.y * numTiles,
        );

        const bounds = map.getBounds();
        const topLeft = new google.maps.LatLng(
            bounds.getNorthEast().lat(),
            bounds.getSouthWest().lng(),
        );

        const topLeftWorldCoordinate = projection.fromLatLngToPoint(topLeft);
        const topLeftPixelCoordinate = new google.maps.Point(
            topLeftWorldCoordinate.x * numTiles,
            topLeftWorldCoordinate.y * numTiles,
        );

        return new google.maps.Point(
            pixelCoordinate.x - topLeftPixelCoordinate.x,
            pixelCoordinate.y - topLeftPixelCoordinate.y,
        );
    }

    static getDerivedStateFromProps(props, state) {
        const venuesChanged = props.venues !== state.venues;
        if (venuesChanged) {
            return {
                venues: props.venues,
                markers: EventsMap.getMarkersFromItems(props.venues),
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.onVenuesLoaded = this.onVenuesLoaded.bind(this);
        this.onVenuesLoadError = this.onVenuesLoadError.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onMapBoundsChange = this.onMapBoundsChange.bind(this);
        this.onClickOutsidePopup = this.onClickOutsidePopup.bind(this);

        this.refMapContainer = null;

        this.state = {
            venues: null, // eslint-disable-line react/no-unused-state
            markers: [],
            isLoading: props.venues === null,
            selectedMarker: null,
            markerPosition: null,
        };
    }

    componentDidMount() {
        const { venues } = this.props;
        if (venues === null) {
            this.loadVenues();
        }
    }

    onVenuesLoaded(items) {
        const { setVenues } = this.props;
        setVenues(items);
        this.setState({
            isLoading: false,
        });
    }

    onVenuesLoadError() {
        this.setState({
            isLoading: false,
        });
    }

    // eslint-disable-next-line
    onMarkerClick(marker, { venue }, index) {
        const { trackEvent } = this.props;
        const { selectedMarker } = this.state;
        if (selectedMarker !== null && selectedMarker.index === index) {
            this.clearSelectedMarker();
            return;
        }
        const markerPosition = EventsMap.getMarkerPosition(marker);
        this.setState({
            selectedMarker: {
                marker,
                venue,
                index,
            },
            markerPosition,
        });

        trackEvent('EventsMap', 'click', venue.name);
    }

    onMapBoundsChange() {
        const { selectedMarker } = this.state;
        if (selectedMarker !== null) {
            const { marker } = selectedMarker;
            const map = marker.getMap();
            if (!map.getBounds().contains(marker.getPosition())) {
                this.clearSelectedMarker();
                return;
            }
            const markerPosition = EventsMap.getMarkerPosition(marker);
            this.setState({
                markerPosition,
            });
        }
    }

    onClickOutsidePopup() {
        this.clearSelectedMarker();
    }

    clearSelectedMarker() {
        this.setState({
            selectedMarker: null,
            markerPosition: null,
        });
    }

    loadVenues() {
        const { urlGenerator } = this.props;
        const url = urlGenerator.route('api.venues');
        loadVenues(url)
            .then(this.onVenuesLoaded)
            .catch(this.onVenuesLoadError);
    }

    renderPopup() {
        const { urlGenerator } = this.props;
        const { selectedMarker, markerPosition } = this.state;
        const { venue } = selectedMarker;
        return (
            <EventsMapPopup
                {...markerPosition}
                url={urlGenerator.route('api.events', {
                    venue: venue.id,
                })}
                onClickOutside={this.onClickOutsidePopup}
            />
        );
    }

    render() {
        const { className } = this.props;
        const { isLoading, selectedMarker, markers } = this.state;
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.isLoading]: isLoading,
                        [className]: className !== null,
                    },
                ])}
            >
                <div
                    className={styles.mapContainer}
                    ref={(ref) => {
                        this.refMapContainer = ref;
                    }}
                >
                    <CustomMap
                        markers={markers}
                        latitude={45.606647}
                        longitude={-73.71241}
                        zoom={11}
                        className={styles.map}
                        onMarkerClick={this.onMarkerClick}
                        onBoundsChange={this.onMapBoundsChange}
                    />
                </div>
                {selectedMarker !== null ? this.renderPopup() : null}
            </div>
        );
    }
}

EventsMap.propTypes = propTypes;
EventsMap.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ eventsMap }) => ({
        ...eventsMap,
    }),
    dispatch => ({
        setVenues: items => dispatch(setVenuesAction(items)),
    }),
)(EventsMap);
const WithUrlGenerator = withUrlGenerator()(WithStateContainer);
const WithTrackingContainer = withTracking(WithUrlGenerator);
export default WithTrackingContainer;
