import HomeIcon from './Home';
import MagazineIcon from './Magazine';
import EventsIcon from './Events';
import DistrictsIcon from './Districts';
import DirectoryIcon from './Directory';

export default {
    home: HomeIcon,
    magazine: MagazineIcon,
    events: EventsIcon,
    districts: DistrictsIcon,
    directory: DirectoryIcon,
};
