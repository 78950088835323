/* globals GOOGLE_API_KEY: true */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import * as AppPropTypes from '../../lib/PropTypes';
import Map from './Map';
import mapStyles from '../../data/mapStyles.json';

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    apiKey: PropTypes.string,
    center: AppPropTypes.position.isRequired,
    styles: PropTypes.array, // eslint-disable-line
};

const defaultProps = {
    apiKey: GOOGLE_API_KEY,
    styles: mapStyles,
};

const CustomMap = ({ intl, center, ...props }) => (
    <Map locale={intl.locale} latitude={center.latitude} longitude={center.longitude} {...props} />
);

CustomMap.propTypes = propTypes;
CustomMap.defaultProps = defaultProps;

const WithStateContainer = connect(({ site }) => ({
    center: site.lavalPosition,
}))(CustomMap);
const WithIntlContainer = injectIntl(WithStateContainer);
export default WithIntlContainer;
