/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import Helmet from 'react-helmet';

import * as AppPropTypes from '../../lib/PropTypes';
import { addNonBreakingSpaces } from '../../lib/utils';

import styles from '../../../styles/blocks/embed.scss';

const propTypes = {
    embed: AppPropTypes.embed,
    title: PropTypes.string,
    text: PropTypes.string,
    fullWidth: PropTypes.bool,
    fixedSize: PropTypes.bool,
    isSection: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    embed: null,
    title: null,
    text: null,
    fullWidth: false,
    fixedSize: false,
    isSection: false,
    className: null,
};

// eslint-disable-next-line
const Embed = ({ embed, title, text, fullWidth, fixedSize, isSection, className }) => {
    const hasTitle = !isEmpty(title);
    const hasDescription = !isEmpty(text);
    const hasDetails = hasTitle || hasDescription;
    // prettier-ignore
    const isVideo = embed !== null && (embed.type || 'rich') === 'video';
    // prettier-ignore
    const isFixed = embed !== null && (fixedSize || embed.provider === '@bandcamp' || embed.provider === 'Spotify' || embed.provider === 'spotify');
    const embedWidth = embed !== null ? embed.width || null : null;
    const embedHeight = embed !== null ? embed.height || null : null;
    // prettier-ignore
    const embedRatio = isNumber(embedWidth) && isNumber(embedHeight) && !isFixed
        ? embedHeight / embedWidth
        : null;

    // prettier-ignore
    const hasSize = (embedRatio !== null || (embedWidth === '100%' && isNumber(embedHeight))) && !isFixed;

    const hasNineSixteen = isVideo && embedRatio < 1;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.isSection]: isSection,
                    [styles.fullWidth]: fullWidth,
                    [styles.isVideo]: isVideo,
                    [styles.isFixed]: isFixed,
                    [className]: className !== null,
                },
            ])}
        >
            {embed !== null && embed.provider === 'instagram' ? (
                <Helmet>
                    <script async src="//www.instagram.com/embed.js" id="instagram" />
                </Helmet>
            ) : null}
            <div className={styles.inner}>
                {embed !== null ? (
                    <div
                        className={styles.embed}
                        style={{
                            paddingBottom: embedRatio !== null ? `${100 * embedRatio}%` : null,
                            maxWidth:
                                !fullWidth && !isVideo && !isFixed && isNumber(embedWidth)
                                    ? embedWidth
                                    : null,
                            height:
                                embedRatio === null && embedWidth === '100%' ? embedHeight : null,
                        }}
                    >
                        {embed.iframeUrl && !isFixed ? (
                            <iframe
                                src={embed.iframeUrl}
                                className={classNames([
                                    styles.iframe,
                                    {
                                        [styles.fill]: hasNineSixteen,
                                        [styles.fullscreen]: hasSize,
                                    },
                                ])}
                                frameBorder="0"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="Embed"
                            />
                        ) : (
                            <div
                                className={classNames([
                                    styles.iframeContainer,
                                    {
                                        [styles.fill]: hasNineSixteen,
                                        [styles.fullscreen]: hasSize,
                                    },
                                ])}
                                dangerouslySetInnerHTML={{ __html: embed.iframe }}
                            />
                        )}
                    </div>
                ) : null}
                {hasDetails ? (
                    <div className={styles.details}>
                        {hasTitle ? (
                            <h4 className={styles.title}>{addNonBreakingSpaces(title)}</h4>
                        ) : null}
                        {hasDescription ? <p className={styles.description}>{text}</p> : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

Embed.propTypes = propTypes;
Embed.defaultProps = defaultProps;

export default Embed;
