import React from 'react';
// import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import Carousel from './Carousel';

import styles from '../../../styles/blocks/event-groups-carousel.scss';

const messages = defineMessages({
    title: {
        id: 'content.festivals',
        defaultMessage: 'Festivals',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const defaultProps = {};

const EventGroupsCarousel = ({ intl, urlGenerator, ...props }) => (
    <Carousel
        className={styles.container}
        title={intl.formatMessage(messages.title)}
        smallTitle
        url={urlGenerator.route('api.event_groups')}
        // itemMaxWidth={300}
        emptyCardClassName={styles.emptyCard}
        isFullWidth
        {...props}
        purplePage
    />
);

EventGroupsCarousel.propTypes = propTypes;
EventGroupsCarousel.defaultProps = defaultProps;

const WithUrlGeneratorContainer = withUrlGenerator()(EventGroupsCarousel);
const WithIntlContainer = injectIntl(WithUrlGeneratorContainer);
export default WithIntlContainer;
