/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Img from 'laravel-image';
import { Link } from 'react-router-dom';

import * as AppPropTypes from '../../lib/PropTypes';
import { addNonBreakingSpaces } from '../../lib/utils';
import MoreButton from '../buttons/More';
import Blocks from '../blocks/Blocks';
import Author from '../partials/MagazineAuthor';
import Embed from '../blocks/Embed';

import styles from '../../../styles/magazines/video.scss';

const propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    author: AppPropTypes.author,
    date: PropTypes.string,
    page: AppPropTypes.page,
    image: AppPropTypes.image,
    video: AppPropTypes.embed,
    blocks: AppPropTypes.blocks,
    short: PropTypes.bool,
    isPageHeading: PropTypes.bool,
    isPreview: PropTypes.bool,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
};

const defaultProps = {
    url: null,
    title: null,
    author: null,
    date: null,
    page: null,
    image: null,
    video: null,
    blocks: null,
    short: false,
    isPageHeading: false,
    isPreview: false,
    className: null,
    headerClassName: null,
};

const MagazineVideo = ({
    url,
    title,
    author,
    date,
    image,
    video,
    page,
    blocks,
    short,
    isPageHeading,
    isPreview,
    className,
    headerClassName,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [styles.short]: short,
                [className]: className !== null,
            },
        ])}
    >
        <div
            className={classNames([
                styles.header,
                {
                    [headerClassName]: headerClassName !== null,
                },
            ])}
            style={{
                backgroundImage:
                    image !== null
                        ? `url("${Img.url(image.url, {
                              purple: true,
                              large: true,
                          })}")`
                        : null,
            }}
        >
            <div className={styles.inner}>
                {title !== null ? (
                    <div className={classNames([styles.section, styles.titleContainer])}>
                        {isPageHeading ? (
                            <h1 className={styles.title}>
                                {url !== null ? (
                                    <Link to={url}>{addNonBreakingSpaces(title)}</Link>
                                ) : (
                                    addNonBreakingSpaces(title)
                                )}
                            </h1>
                        ) : (
                            <h2 className={styles.title}>
                                {url !== null ? (
                                    <Link to={url}>{addNonBreakingSpaces(title)}</Link>
                                ) : (
                                    addNonBreakingSpaces(title)
                                )}
                            </h2>
                        )}
                    </div>
                ) : null}

                {author !== null ? (
                    <div className={classNames([styles.section, styles.authorContainer])}>
                        <Author {...author} date={date} />
                    </div>
                ) : null}

                {video !== null ? (
                    <div className={classNames([styles.section, styles.videoContainer])}>
                        <Embed embed={video} />
                    </div>
                ) : null}
            </div>
        </div>
        {!short && blocks !== null && blocks.length > 0 ? (
            <div className={classNames([styles.section, styles.blocksContainer])}>
                <Blocks
                    page={page}
                    blocks={blocks.map((it) =>
                        it.type === 'magazines_carousel'
                            ? {
                                  ...it,
                                  purple: true,
                              }
                            : it,
                    )}
                    blocksAreSections
                    isPreview={isPreview}
                    purplePage={false}
                />
            </div>
        ) : null}

        {short ? (
            <div className={styles.moreContainer}>
                <MoreButton action="view" href={url} purple className={styles.more} />
            </div>
        ) : null}
    </div>
);

MagazineVideo.propTypes = propTypes;
MagazineVideo.defaultProps = defaultProps;

export default React.memo(MagazineVideo);
