import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Img from 'laravel-image';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedDate } from 'react-intl';

import PageStatus from '../partials/PageStatus';

import * as AppPropTypes from '../../lib/PropTypes';
import { addNonBreakingSpaces, isMessage, getDateObject } from '../../lib/utils';

import messages from './messages';
import styles from '../../../styles/cards/magazine.scss';

const propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.string,
    publishAt: PropTypes.string,
    image: AppPropTypes.image,
    cardImage: AppPropTypes.image,
    date: PropTypes.string,
    small: PropTypes.bool,
    actionButtonLabel: AppPropTypes.label,
    withShadow: PropTypes.bool,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    url: null,
    title: null,
    status: null,
    publishAt: null,
    image: null,
    cardImage: null,
    date: null,
    small: false,
    actionButtonLabel: messages.readButton,
    withShadow: false,
    isLoading: false,
    className: null,
};

const MagazineCard = ({
    url,
    title,
    status,
    publishAt,
    image,
    cardImage,
    date,
    small,
    withShadow,
    isLoading,
    actionButtonLabel,
    className,
}) => {
    const finalImage = cardImage || image;
    // console.log(title, cardImage, image); // eslint-disable-line
    return (
        <Link
            to={url}
            className={classNames([
                styles.container,
                {
                    [styles.small]: small,
                    [styles.withShadow]: withShadow,
                    [styles.isLoading]: isLoading,
                    [className]: className !== null,
                },
            ])}
        >
            {status && status !== 'published' ? (
                <PageStatus className={styles.status} status={status} date={publishAt} />
            ) : null}
            {title !== null ? (
                <span className={styles.inner}>
                    <span className={styles.middle}>
                        <strong className={styles.title}>{addNonBreakingSpaces(title)}</strong>
                    </span>
                </span>
            ) : null}
            {title !== null && url != null && actionButtonLabel !== null ? (
                <span className={styles.action}>
                    <span className={styles.button}>
                        {isMessage(actionButtonLabel) ? (
                            <FormattedMessage {...actionButtonLabel} />
                        ) : null}
                    </span>
                </span>
            ) : null}
            {date !== null ? (
                <span className={styles.date}>
                    <FormattedDate
                        value={getDateObject(date)}
                        format=""
                        month="long"
                        day="numeric"
                        year="numeric"
                    />
                </span>
            ) : null}
            {finalImage !== null ? (
                <span
                    className={styles.image}
                    style={{
                        backgroundImage: `url("${Img.url(finalImage.url, {
                            // red: true,
                            purple: true,
                            small,
                            medium: !small,
                        })}")`,
                    }}
                />
            ) : null}
            <span className={styles.border} />
        </Link>
    );
};

MagazineCard.propTypes = propTypes;
MagazineCard.defaultProps = defaultProps;

export default MagazineCard;
