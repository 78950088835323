import Loadable from 'react-loadable';

export { default as Text } from './Text';
export { default as Video } from './Video';
export { default as Podcast } from './Podcast';

export const Photo = Loadable({
    loader: () => import('./Photo'),
    loading: () => null,
});
