/**
 * Constants
 */
export const SET_VENUES = 'eventsMap@SET_VENUES';
export const SET_EVENTS = 'eventsMap@SET_EVENTS';

/**
 * Actions creator
 */
export const setVenues = data => ({
    type: SET_VENUES,
    payload: data,
});

export const setEvents = (venue, event) => ({
    type: SET_EVENTS,
    payload: {
        venue,
        event,
    },
});
