/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

import * as AppPropTypes from '../../lib/PropTypes';
import MDAIcon from '../icons/MDA';

import styles from '../../../styles/cards/event.scss';

const propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    image: AppPropTypes.image,
    venue: AppPropTypes.venue,
    disciplines: AppPropTypes.eventEntities,
    date: AppPropTypes.eventDate,
    isMDA: PropTypes.bool,
    small: PropTypes.bool,
    horizontal: PropTypes.bool,
    withShadow: PropTypes.bool,
    isLoading: PropTypes.bool,
    poster: PropTypes.bool,
    red: PropTypes.bool,
    external: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    url: null,
    title: null,
    image: null,
    venue: null,
    disciplines: [],
    date: null,
    isMDA: false,
    small: false,
    horizontal: false,
    withShadow: false,
    isLoading: false,
    poster: false,
    red: false,
    external: false,
    className: null,
};

const EventCard = ({
    url,
    title,
    image,
    venue,
    disciplines,
    date,
    isMDA,
    horizontal,
    small,
    withShadow,
    isLoading,
    poster,
    red,
    external,
    className,
}) => {
    const category = get(disciplines, '0.name', null);
    const inner = (
        <Fragment>
            {category !== null ? <span className={styles.category}>{category}</span> : null}
            <span
                className={styles.image}
                style={{
                    backgroundImage: image !== null ? `url("${image.sizes.medium}")` : null,
                }}
            >
                {isMDA ? <MDAIcon className={styles.mdaIcon} /> : null}
            </span>
            <span className={styles.label}>
                {category !== null ? <span className={styles.category}>{category}</span> : null}
                <strong className={styles.title}>{title}</strong>
                <span className={styles.details}>
                    {date !== null ? (
                        <span className={styles.date}>
                            {date.formatted}
                        </span>
                    ) : null}
                    {venue !== null ? (
                        <span className={styles.location}>{venue.name}</span>
                    ) : null}
                </span>
            </span>
        </Fragment>
    );

    const linkClassName = classNames([
        styles.container,
        {
            [styles.horizontal]: horizontal,
            [styles.small]: small,
            [styles.poster]: poster,
            [styles.red]: red,
            [styles.withShadow]: withShadow,
            [styles.isLoading]: isLoading,
            [className]: className !== null,
        },
    ]);

    return external ? (
        <a href={url || '#'} target="_blank" className={linkClassName}>
            {inner}
        </a>
    ) : (
        <Link to={url || '#'} className={linkClassName}>
            {inner}
        </Link>
    );
};

EventCard.propTypes = propTypes;
EventCard.defaultProps = defaultProps;

export default EventCard;
