/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon,
} from 'react-share';

import { withTracking } from '../../lib/TrackingContext';
import styles from '../../../styles/partials/share.scss';

const messages = defineMessages({
    title: {
        id: 'content.share_title',
        defaultMessage: 'Partager cette page',
    },
    titleEvent: {
        id: 'content.share_title_event',
        defaultMessage: 'Partager cet événement',
    },
    titleMagazine: {
        id: 'content.share_title_magazine',
        defaultMessage: 'Partager cet article',
    },
    titleVideo: {
        id: 'content.share_title_video',
        defaultMessage: 'Partager cette vidéo',
    },
});

const propTypes = {
    trackSocial: PropTypes.func.isRequired,
    type: PropTypes.string,
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    networks: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            button: PropTypes.func,
        }),
    ),
    isSection: PropTypes.bool,
    purple: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    type: 'page',
    title: null,
    networks: [
        {
            name: 'facebook',
            button: FacebookShareButton,
            icon: FacebookIcon,
            props: ({ url, title }) => ({
                url,
                quote: title,
            }),
        },
        {
            name: 'twitter',
            button: TwitterShareButton,
            icon: TwitterIcon,
        },
        {
            name: 'email',
            button: EmailShareButton,
            icon: EmailIcon,
        },
    ],
    isSection: false,
    purple: false,
    className: null,
};

const Share = ({ trackSocial, type, url, title, networks, isSection, purple, className }) => {
    let titleMessage = messages.title;
    if (type === 'event') {
        titleMessage = messages.titleEvent;
    } else if (type === 'magazine') {
        titleMessage = messages.titleMagazine;
    } else if (type === 'magazine_video') {
        titleMessage = messages.titleVideo;
    } else if (type === 'magazine_podcast') {
        titleMessage = messages.titleMagazine;
    }
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.isSection]: isSection,
                    [styles.purple]: purple,
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                <h4 className={styles.title}>
                    <FormattedMessage {...titleMessage} />
                </h4>
                <div className={styles.buttons}>
                    <ul className={styles.items}>
                        {networks.map(
                            ({ name, button: Button, icon: Icon, props: buttonProps = null }) => (
                                <li className={styles.item} key={`social-${name}`}>
                                    <Button
                                        {...(buttonProps !== null
                                            ? buttonProps({ url, title })
                                            : {
                                                  url,
                                                  title,
                                              })}
                                        beforeOnClick={() => trackSocial(name, 'clickShare')}
                                        onShareWindowClose={() => trackSocial(name, 'share')}
                                        className={styles.button}
                                    >
                                        <Icon
                                            className={styles.icon}
                                            round
                                            iconBgStyle={{ fill: purple ? '#454896' : '#8eb9e1' }}
                                        />
                                    </Button>
                                </li>
                            ),
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

Share.propTypes = propTypes;
Share.defaultProps = defaultProps;

const WithSocialContainer = withTracking(Share);
export default WithSocialContainer;
