import React from 'react';
import PropTypes from 'prop-types';

const pointsMap = {
    down: '4,6 8,2 0,2',
    right: '2,4 6,8 6,0',
    up: '4,2 0,6 8,6',
    left: '6,4 2,0 2,8',
};

const propTypes = {
    color: PropTypes.string,
    orientation: PropTypes.oneOf(Object.keys(pointsMap)),
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    orientation: 'down',
    className: null,
};

const CaretIcon = ({ color, orientation, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="8px"
        height="8px"
        viewBox="0 0 8 8"
        xmlSpace="preserve"
        className={className}
    >
        <polygon fill={color} points={pointsMap[orientation]} />
    </svg>
);

CaretIcon.propTypes = propTypes;
CaretIcon.defaultProps = defaultProps;

export default CaretIcon;
