import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    stroke: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    stroke: '#FEE0DD',
    className: null,
};

const CheckmarkIcon = ({ color, stroke, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="60px"
        height="60px"
        viewBox="0 0 60 60"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            d="M60,30c0,16.6-13.4,30-30,30C13.4,60,0,46.6,0,30C0,13.4,13.4,0,30,0C46.6,0,60,13.4,60,30"
        />
        <polyline
            fill="none"
            stroke={stroke}
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="20,31.2 28,38.8 40,20 "
        />
    </svg>
);

CheckmarkIcon.propTypes = propTypes;
CheckmarkIcon.defaultProps = defaultProps;

export default CheckmarkIcon;
