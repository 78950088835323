import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const DirectoryIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="20.4px"
        height="16.8px"
        viewBox="0 0 20.4 16.8"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            d="M3.3,13.5c-1.4-1-1.1-2.6-0.9-3.2C2.6,9.6,2.6,9,2.6,9C1.9,8.6,1.9,8,2.1,7.5c0.6-0.4,2-2.2,2-2.2l0-0.1C3.6,2.8,6.2,1,6.2,1l0.1-0.4c-0.3,0-1.1,0-1.4-0.1C4.5,0.4,3.4,0.2,2.6,1.8c-0.5,1-0.1,1.9-0.1,1.9L1.3,6l0.2,0.8L0,8.9c0,0-0.1,0.4,0.2,0.6c0.3,0.2,0.6,0.2,0.6,0.2s-0.2,0.6-0.3,0.7c0,0,0.1,0.5,0.5,0.6c0,0-0.3,0.5-0.2,0.7c0,0,0.3,0.1,0.2,0.3c0,0.2-0.4,1.1,0.1,1.6c0.6,0.5,1.4,0.3,2,0.6c0.6,0.3,0.6,1.3,0.6,1.3s1.2,0.4,2.6,0.4C5.8,12.8,4.8,14.6,3.3,13.5z"
        />
        <path
            fill={color}
            d="M9.3,0c1,0,6.8,0.2,7.7,3.6c0,0,2.7-0.9,3.3,1.5c0.7,2.5-2.6,3.6-2.6,3.6s0.1,3.6-1.9,3.6c-0.7,0-0.7-0.1-1.2-0.1c-0.5,0-1,0.7-0.5,1.7c0,0-1.1,3.2-5.5,2.7c0,0-0.5-0.8-0.7-1.8S7.4,13.8,7,13.2c-0.4-0.5-1.4-0.5-2-0.5s-1.1-0.4-1.1-0.9s0.5-1,0.4-1.2C4.1,10.4,4,10.3,4,10.3s0-0.4,0.4-0.6L4.2,9.2c0,0,0.4-0.3,0.3-0.5C4.4,8.5,3.8,8.2,3.8,7.9s0.5-0.6,0.6-0.7s1.3-1.1,1.3-1.6S5.6,5,5.6,4.7s0.7-2.2,2.2-3C7.8,1.7,7.8,0,9.3,0z"
        />
    </svg>
);

DirectoryIcon.propTypes = propTypes;
DirectoryIcon.defaultProps = defaultProps;

export default DirectoryIcon;
