/* eslint-disable indent */
import { LOCATION_CHANGE } from 'connected-react-router';

import {
    SET_SIZE,
    SET_SCROLL,
    SET_FONTS_LOADED,
    SET_MENU_OPENED,
    SET_POPUP_CLOSED,
    SET_FOOTER_PURPLE,
} from '../actions/LayoutActions';

import { getScroll } from '../lib/utils';

const initialState = {
    size: {
        width: window.innerWidth || 0,
        height: window.innerHeight || 0,
    },
    scroll: {
        ...getScroll(),
    },
    fontsLoaded: false,
    menuOpened: false,
    popupClosed: false,
    footerIsPurple: false,
};

const LayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SIZE: {
            const { width } = action.payload;
            return {
                ...state,
                size: {
                    ...action.payload,
                },
                menuOpened: state.size.width < 760 && width >= 760 ? false : state.menuOpened,
            };
        }
        case SET_SCROLL: {
            return {
                ...state,
                scroll: {
                    ...action.payload,
                },
            };
        }
        case SET_FONTS_LOADED:
            return {
                ...state,
                fontsLoaded: action.payload,
            };
        case SET_MENU_OPENED:
            return {
                ...state,
                menuOpened: action.payload,
            };
        case SET_POPUP_CLOSED:
            return {
                ...state,
                popupClosed: action.payload,
            };
        case SET_FOOTER_PURPLE:
            return {
                ...state,
                footerIsPurple: action.payload,
            };
        case LOCATION_CHANGE:
            return {
                ...state,
                menuOpened: false,
            };
        default:
            return state;
    }
};

export default LayoutReducer;
