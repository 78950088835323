/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import * as AppPropTypes from '../../lib/PropTypes';

import Logo from './Logo';
import Menu from '../menus/Menu';
import MenuIcons from '../icons/menuIcons';

import styles from '../../../styles/partials/main-menu.scss';

const addIcon = ({ label, ...it }) => {
    const Icon = MenuIcons[it.key] || null;
    return {
        ...it,
        label: (
            <span className={styles.inner}>
                <span className={styles.middle}>
                    {Icon !== null ? <Icon className={styles.icon} /> : null}
                    <span className={styles.label}>
                        {label.split(' ').map((text, index) => (
                            <span key={`text-${it.key}-${index}`}>{text}</span>
                        ))}
                    </span>
                </span>
            </span>
        ),
    };
};

const propTypes = {
    logoLink: PropTypes.string,
    menuItems: AppPropTypes.menuItems,
    pagesMenuItems: AppPropTypes.menuItems,
    menuOpened: PropTypes.bool,
};

const defaultProps = {
    logoLink: null,
    menuItems: [],
    pagesMenuItems: [],
    menuOpened: false,
};

const MainMenu = ({
    logoLink, menuItems, pagesMenuItems, menuOpened,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [styles.menuOpened]: menuOpened,
            },
        ])}
    >
        <div className={styles.cols}>
            <div className={classNames([styles.col, styles.colLogo])}>
                <div className={styles.logoContainer}>
                    <Link to={logoLink}>
                        <Logo className={styles.logo} />
                    </Link>
                </div>
            </div>
            <div className={classNames([styles.col, styles.colMenu])}>
                <div className={styles.menuContainer}>
                    <Menu
                        items={menuItems.map(it => addIcon(it))}
                        className={styles.menu}
                        itemsClassName={styles.items}
                        itemClassName={styles.item}
                        currentClassName={styles.current}
                        linkClassName={styles.link}
                    />
                    <Menu
                        items={pagesMenuItems}
                        className={styles.pagesMenu}
                        itemsClassName={styles.items}
                        itemClassName={styles.item}
                        currentClassName={styles.current}
                        linkClassName={styles.link}
                    />
                </div>
            </div>
        </div>
    </div>
);

MainMenu.propTypes = propTypes;
MainMenu.defaultProps = defaultProps;

export default MainMenu;
