/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/partials/contact-map.scss';

const messages = defineMessages({

});

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const ContactMap = ({
    className,
}) => (
    <div
        className={classNames({
            [styles.container]: true,
            [className]: className !== null,
        })}
    >
        <div className={styles.inner}>

        </div>
    </div>
);

ContactMap.propTypes = propTypes;
ContactMap.defaultProps = defaultProps;

export default ContactMap;
