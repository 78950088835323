import React, { Component } from 'react';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import isObject from 'lodash/isObject';
import classNames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import SearchIcon from '../icons/Search';
import CloseIcon from '../icons/Close';

import styles from '../../../styles/partials/search.scss';

const messages = defineMessages({
    placeholder: {
        id: 'content.search_placeholder',
        defaultMessage: 'Recherche',
    },
});

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    intl: AppPropTypes.intl.isRequired,
    action: PropTypes.func,
    value: PropTypes.string,
    placeholder: AppPropTypes.label,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    focusedClassName: PropTypes.string,
    clearButtonClassName: PropTypes.string,
    light: PropTypes.bool,
    fatIcon: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClear: PropTypes.func,
    onFocusChange: PropTypes.func,
};

const defaultProps = {
    value: null,
    action: null,
    placeholder: messages.placeholder,
    className: null,
    inputClassName: null,
    focusedClassName: null,
    clearButtonClassName: null,
    light: false,
    fatIcon: false,
    onSubmit: null,
    onClear: null,
    onFocusChange: null,
};

class Search extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onClickClear = this.onClickClear.bind(this);

        this.refInput = null;

        this.state = {
            focused: false,
            query: props.value,
        };
    }

    componentDidUpdate(prevProps, { focused: prevFocused }) {
        const { onFocusChange } = this.props;
        const { focused } = this.state;
        const focusedChanged = prevFocused !== focused;
        if (focusedChanged && onFocusChange !== null) {
            onFocusChange(focused);
        }
    }

    onSubmit(e) {
        const { onSubmit } = this.props;
        const { query } = this.state;
        if (query === null || trim(query).length < 1) {
            e.preventDefault();
            this.refInput.focus();
            return;
        }
        if (onSubmit !== null) {
            onSubmit(e, query);
        }
    }

    onChange(e) {
        this.setState({
            query: e.target.value,
        });
    }

    onFocus() {
        this.setState({
            focused: true,
        });
    }

    onBlur() {
        this.setState({
            focused: false,
        });
    }

    onClickClear() {
        const { onClear } = this.props;
        this.refInput.blur();

        this.setState({
            query: null,
        });

        if (onClear !== null) {
            onClear();
        }
    }

    render() {
        const {
            urlGenerator,
            intl,
            placeholder,
            light,
            fatIcon,
            className,
            inputClassName,
            focusedClassName,
            clearButtonClassName,
            action,
        } = this.props;
        const { focused, query } = this.state;

        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.light]: light,
                        [styles.fatIcon]: fatIcon,
                        [className]: className !== null,
                        [styles.focused]: focused,
                        [focusedClassName]: focusedClassName !== null && focused,
                    },
                ])}
            >
                <form
                    action={action || urlGenerator.route('search')}
                    method="GET"
                    className={styles.form}
                    onSubmit={this.onSubmit}
                >
                    <input
                        type="text"
                        name="q"
                        className={classNames([
                            styles.input,
                            {
                                [inputClassName]: inputClassName !== null,
                            },
                        ])}
                        placeholder={
                            isObject(placeholder) ? intl.formatMessage(placeholder) : placeholder
                        }
                        value={query || ''}
                        ref={(ref) => {
                            this.refInput = ref;
                        }}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                    />
                    <button
                        type="submit"
                        className={classNames([styles.button, styles.searchButton])}
                    >
                        <SearchIcon fat={fatIcon} className={styles.icon} />
                    </button>
                    <button
                        type="button"
                        className={classNames([
                            styles.button,
                            styles.clearButton,
                            {
                                [styles.visible]: query !== null && query.length > 0,
                                [clearButtonClassName]: clearButtonClassName !== null,
                            },
                        ])}
                        onClick={this.onClickClear}
                    >
                        <CloseIcon className={styles.icon} />
                    </button>
                </form>
            </div>
        );
    }
}

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

const WithUrlContainer = withUrlGenerator()(Search);
const WithIntlContainer = injectIntl(WithUrlContainer);
export default WithIntlContainer;
