import PropTypes from 'prop-types';

/**
 * Core
 */
export const urlGenerator = PropTypes.shape({
    route: PropTypes.func.isRequired,
});

export const intl = PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
});

export const history = PropTypes.shape({
    listen: PropTypes.func.isRequired,
});

export const location = PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
});

export const components = PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
);

export const statusCode = PropTypes.oneOf([404, 500]);

export const position = PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
});

export const contactsFilters = PropTypes.shape({
    disciplines: PropTypes.arrayOf(PropTypes.number),
    districts: PropTypes.arrayOf(PropTypes.number),
});

export const magazinesFilters = PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.number),
});

export const eventsFilters = PropTypes.shape({
    timeSpan: PropTypes.string,
    q: PropTypes.string,
    children: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.number),
    types: PropTypes.arrayOf(PropTypes.number),
    venues: PropTypes.arrayOf(PropTypes.number),
    regions: PropTypes.arrayOf(PropTypes.number),
    audiences: PropTypes.arrayOf(PropTypes.number),
});

/**
 * Interface
 */
export const message = PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
});

export const label = PropTypes.oneOfType([message, PropTypes.string, PropTypes.node]);

export const textLocale = PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string),
    PropTypes.string,
]);

export const menuItem = PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.node,
    url: PropTypes.string,
    external: PropTypes.bool,
});

export const menuItems = PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(menuItem), menuItem]),
);

export const menuColumn = PropTypes.shape({
    title: PropTypes.node,
    items: menuItems.isRequired,
});

export const menuColumns = PropTypes.arrayOf(menuColumn);

export const toggleOption = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: label.isRequired,
    children: PropTypes.node,
});

export const toggleOptions = PropTypes.arrayOf(toggleOption);

export const filtersOption = PropTypes.shape({
    name: PropTypes.string.isRequired,
    label,
    options: toggleOptions,
});

export const filtersOptions = PropTypes.arrayOf(filtersOption);

/**
 * Elements
 */
export const image = PropTypes.shape({
    url: PropTypes.string.isRequired,
    sizes: PropTypes.shape({
        thumbnail: PropTypes.string.isRequired,
        small: PropTypes.string.isRequired,
        medium: PropTypes.string.isRequired,
        large: PropTypes.string.isRequired,
    }).isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
});

export const images = PropTypes.arrayOf(image);

export const photo = PropTypes.shape({
    photo: image,
    description: PropTypes.string,
    credits: PropTypes.string,
});

export const photos = PropTypes.arrayOf(photo);

export const logo = PropTypes.shape({
    logo: image,
    name: PropTypes.string,
    url: PropTypes.string,
});

export const logos = PropTypes.arrayOf(logo);

export const embed = PropTypes.shape({
    type: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url: PropTypes.string.isRequired,
    iframe: PropTypes.string.isRequired,
    iframeUrl: PropTypes.string,
    thumbnail: image,
});

export const audio = PropTypes.shape({
    url: PropTypes.string,
});

export const podcast = PropTypes.shape({
    audio,
    audioUrl: PropTypes.string,
    show: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
});

export const contact = PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    url: PropTypes.string,
});

export const category = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
});

export const categories = PropTypes.arrayOf(category);

export const link = PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string,
});
export const links = PropTypes.arrayOf(link);

export const address = PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    region: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
});

export const districtStats = PropTypes.shape({
    events: PropTypes.number.isRequired,
    locations: PropTypes.number.isRequired,
    contacts: PropTypes.number.isRequired,
});

export const answer = PropTypes.shape({
    label: PropTypes.string,
    image,
    good: PropTypes.bool,
    explanation: PropTypes.string,
});
export const answers = PropTypes.arrayOf(answer);

export const document = PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    filename: PropTypes.string,
});
export const documents = PropTypes.arrayOf(document);

/**
 * Blocks
 */

export const block = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    type: PropTypes.string.isRequired,
    embed,
    photos,
    answers,
});

export const blocks = PropTypes.arrayOf(block);

/**
 * Pages
 */
export const page = PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image,
    slug: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    urls: PropTypes.objectOf(PropTypes.string),
    blocks,
});

export const pages = PropTypes.arrayOf(page);

/**
 * Districts
 */
export const district = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

export const districts = PropTypes.arrayOf(district);

/**
 * Events
 */

export const room = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
});

export const venue = PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    region: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    room,
});

export const venues = PropTypes.arrayOf(venue);

export const price = PropTypes.shape({
    price: PropTypes.string,
    description: PropTypes.string,
});

export const prices = PropTypes.arrayOf(price);

export const eventEntity = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
});

export const eventEntities = PropTypes.arrayOf(eventEntity);

export const eventOrganisation = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
    url: PropTypes.string,
});

export const eventDate = PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string,
    formatted: PropTypes.string.isRequired,
});

export const eventDates = PropTypes.arrayOf(eventDate);

export const event = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    date: eventDate,
    dates: eventDates,
    image,
    photos,
    video: embed,
    venue,
    prices,
    contact,
    audiences: eventEntities,
    tags: eventEntities,
    organisation: eventOrganisation,
});

export const events = PropTypes.arrayOf(event);

/**
 * Magazine
 */

export const author = PropTypes.shape({
    name: PropTypes.string,
    image,
    district,
});

export const magazine = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    author,
    image,
    video: embed,
    photos,
    blocks,
});

export const magazines = PropTypes.arrayOf(magazine);

/**
 * Contacts
 */
export const contactPage = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    address,
    contact,
    links,
    categories,
    documents,
    blocks,
});

export const contactPages = PropTypes.arrayOf(contactPage);

/**
 * Popup
 */
export const popup = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.isRequired,
    description: PropTypes.isRequired,
    button: link,
    blocks,
});
