/**
 * Constants
 */
export const SET_PAGE = 'site@SET_PAGE';
export const SET_NEW_ARTICLE = 'site@SET_NEW_ARTICLE';
export const SET_STATUS_CODE = 'site@SET_STATUS_CODE';

/**
 * Actions creator
 */
export const setPage = (url, page) => ({
    type: SET_PAGE,
    payload: {
        url,
        page,
    },
});

export const setNewArticle = page => ({
    type: SET_NEW_ARTICLE,
    payload: page,
});

export const setStatusCode = value => ({
    type: SET_STATUS_CODE,
    payload: value,
});
