import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';

import PageMeta from '../partials/PageMeta';
import MagazineHeader from '../partials/MagazineHeader';
import Magazine from '../magazines/Magazine';
import Share from '../partials/Share';

import styles from '../../../styles/pages/magazine.scss';

const propTypes = {
    page: AppPropTypes.page.isRequired,
    isPreview: PropTypes.bool,
};

const defaultProps = {
    isPreview: false,
};

const MagazinePage = ({ page, isPreview }) => (
    <div className={styles.container}>
        <PageMeta {...page.share} />
        <div className={styles.header}>
            <div className={styles.inner}>
                <MagazineHeader
                    href={page.parent.url}
                    title={page.parent.title}
                    isPageHeading={false}
                />
            </div>
        </div>
        <div className={styles.content}>
            <Magazine
                item={page}
                smallStatus
                isPageHeading
                isPreview={isPreview}
                className={styles.magazine}
            />
            <Share
                type={page.type}
                {...page.share}
                className={classNames([
                    styles.share,
                    {
                        [styles.purple]:
                            (page.type === 'magazine_video' || page.type === 'magazine_podcast') &&
                            (page.blocks === null || page.blocks.length === 0),
                    },
                ])}
                isSection
                purple={
                    page.type !== 'magazine_video' ||
                    page.type !== 'magazine_podcast' ||
                    (page.blocks !== null && page.blocks.length > 0)
                }
            />
        </div>
    </div>
);

MagazinePage.propTypes = propTypes;
MagazinePage.defaultProps = defaultProps;

export default React.memo(MagazinePage);
