import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';
import CheckIcon from '../icons/Check';
import styles from '../../../styles/buttons/checkbox.scss';

const propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
};

const defaultProps = {
    checked: false,
    className: null,
    iconClassName: null,
};

const Checkbox = ({
    checked, className, iconClassName, ...props
}) => (
    <Button
        icon={(
            <CheckIcon
                className={classNames([
                    styles.icon,
                    {
                        [iconClassName]: iconClassName !== null,
                    },
                ])}
                checked={checked}
            />
        )}
        iconPosition="inline"
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
        transparent
        {...props}
    />
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
