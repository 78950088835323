import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const ListIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="41px"
        height="41px"
        viewBox="0 0 41 41"
        xmlSpace="preserve"
        className={className}
    >
        <rect
            x="0.5"
            y="0.5"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            width="13.33"
            height="6.67"
        />
        <line
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            x1="0.5"
            y1="30.5"
            x2="40.5"
            y2="30.5"
        />
        <line
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            x1="20.5"
            y1="10.5"
            x2="20.5"
            y2="23.83"
        />
        <line
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            x1="27.17"
            y1="17.17"
            x2="13.83"
            y2="17.17"
        />
        <rect
            x="0.5"
            y="0.5"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            width="40"
            height="40"
        />
    </svg>
);

ListIcon.propTypes = propTypes;
ListIcon.defaultProps = defaultProps;

export default ListIcon;
