import React from 'react';
// import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/pages/error.scss';

const messages = defineMessages({
    title404: {
        id: 'errors.404_title',
        defaultMessage: 'Page Non-Trouvée',
    },
    description404: {
        id: 'errors.404_description',
        defaultMessage: "Cette page n'existe pas",
    },
    title500: {
        id: 'errors.500_title',
        defaultMessage: 'Erreur',
    },
    description500: {
        id: 'errors.500_description',
        defaultMessage: 'Il y a eu une erreur',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    statusCode: AppPropTypes.statusCode,
};

const defaultProps = {
    statusCode: 404,
};

const ErrorPage = ({ intl, statusCode }) => (
    <div className={styles.container}>
        <Helmet>
            <title>
                {intl.formatMessage({
                    id: `meta.title_error_${statusCode}`,
                    defaultMessage: 'Page non-trouvée',
                })}
            </title>
        </Helmet>
        <div className={styles.inner}>
            <h1 className={styles.title}>
                <FormattedMessage {...(messages[`title${statusCode}`] || messages.title500)} />
            </h1>
            <div className={styles.description}>
                <FormattedMessage
                    {...(messages[`description${statusCode}`] || messages.description500)}
                />
            </div>
        </div>
    </div>
);

ErrorPage.propTypes = propTypes;
ErrorPage.defaultProps = defaultProps;

const mapStateToProps = ({ site }) => ({
    statusCode: site.statusCode,
});
const WithStateContainer = connect(mapStateToProps)(ErrorPage);
const WithIntlContainer = injectIntl(WithStateContainer);

export default WithIntlContainer;
