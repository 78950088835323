import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import FiltersBar from './FiltersBar';
import { loadFiltersOptions } from '../../lib/requests';

import styles from '../../../styles/partials/list-filters.scss';

const propTypes = {
    filtersEndpoint: PropTypes.string,
    filtersOptions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: AppPropTypes.label,
            options: AppPropTypes.toggleOptions,
        }),
    ),
    value: PropTypes.shape({
        timeSpan: PropTypes.string,
    }),
    setFiltersOptions: PropTypes.func.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

const defaultProps = {
    filtersEndpoint: null,
    filtersOptions: null,
    value: null,
    className: null,
    onChange: null,
};

class ListFilters extends PureComponent {
    constructor(props) {
        super(props);

        this.onFiltersOptionsLoaded = this.onFiltersOptionsLoaded.bind(this);
        this.onFiltersChange = this.onFiltersChange.bind(this);
    }

    componentDidMount() {
        const { filtersOptions, filtersEndpoint } = this.props;
        if (filtersOptions === null && filtersEndpoint !== null) {
            this.loadFilters();
        }
    }

    onFiltersOptionsLoaded(filters) {
        const { setFiltersOptions } = this.props;
        setFiltersOptions(filters);
    }

    onFiltersChange(filters) {
        const { onChange } = this.props;
        if (onChange !== null) {
            onChange(filters);
        }
    }

    loadFilters() {
        const { filtersEndpoint } = this.props;
        loadFiltersOptions(filtersEndpoint).then(this.onFiltersOptionsLoaded);
    }

    render() {
        const { filtersOptions, value, className } = this.props;
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
            >
                <FiltersBar
                    options={filtersOptions || []}
                    value={value}
                    onChange={this.onFiltersChange}
                />
            </div>
        );
    }
}

ListFilters.propTypes = propTypes;
ListFilters.defaultProps = defaultProps;

export default ListFilters;
