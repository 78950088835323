/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { addNonBreakingSpaces } from '../../lib/utils';

import styles from '../../../styles/blocks/quote.scss';

const propTypes = {
    text: PropTypes.string,
    source: PropTypes.string,
    isSection: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    text: null,
    source: null,
    isSection: false,
    className: null,
};

const QuoteBlock = ({
    text, source, isSection, className,
}) => (
    <div
        className={classNames({
            [styles.container]: true,
            [styles.isSection]: isSection,
            [className]: className !== null,
        })}
    >
        <div className={styles.inner}>
            {!isEmpty(text) ? (
                <blockquote className={styles.text}>
                    {addNonBreakingSpaces(`&nbsp;${text}&nbsp;`)}
                </blockquote>
            ) : null}
            {!isEmpty(source) ? <p className={styles.source}>{`— ${source}`}</p> : null}
        </div>
    </div>
);

QuoteBlock.propTypes = propTypes;
QuoteBlock.defaultProps = defaultProps;

export default QuoteBlock;
