import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl';

import Star from '../icons/Star';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/partials/contact-details.scss';

const messages = defineMessages({
    address: {
        id: 'content.details_address',
        defaultMessage: 'Adresse',
    },
    contact: {
        id: 'content.details_contact',
        defaultMessage: 'Contact',
    },
    links: {
        id: 'content.details_links',
        defaultMessage: '{count, plural, one {Lien} other {Liens}}',
    },
    districts: {
        id: 'content.details_districts',
        defaultMessage: '{count, plural, one {Quartier} other {Quartiers}}',
    },
    categories: {
        id: 'content.details_categories',
        defaultMessage: '{count, plural, one {Domaine} other {Domaines}}',
    },
    sectors: {
        id: 'content.details_sectors',
        defaultMessage: "{count, plural, one {Champ d'activité} other {Champs d'activité}}",
    },
    documents: {
        id: 'content.details_documents',
        defaultMessage: '{count, plural, one {Document} other {Documents}}',
    },
    professional: {
        id: 'content.details_professional',
        defaultMessage: 'Professionnel',
    },
});

const propTypes = {
    address: AppPropTypes.address,
    contact: AppPropTypes.contact,
    links: AppPropTypes.links,
    districts: AppPropTypes.districts,
    disciplines: AppPropTypes.categories,
    sectors: AppPropTypes.categories,
    documents: AppPropTypes.documents,
    professional: PropTypes.bool,
    className: PropTypes.string,
    detailsClassName: PropTypes.string,
};

const defaultProps = {
    address: null,
    contact: null,
    links: null,
    districts: null,
    disciplines: null,
    sectors: null,
    documents: null,
    className: null,
    professional: false,
    detailsClassName: null,
};

const ContactDetails = ({
    address,
    contact,
    links,
    districts,
    disciplines,
    sectors,
    documents,
    professional,
    className,
    detailsClassName,
}) => (
    <div
        className={classNames({
            [styles.container]: true,
            [className]: className !== null,
        })}
    >
        <div className={styles.inner}>
            {professional ? (
                <div className={styles.pro}>
                    <Star className={styles.star} />
                    <h4 className={styles.professional}>
                        <FormattedMessage {...messages.professional} />
                    </h4>
                </div>
            ) : null}
            {address !== null ? (
                <div
                    className={classNames([
                        styles.details,
                        {
                            [detailsClassName]: detailsClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage {...messages.address} />
                    </h4>
                    {address.address !== null ? (
                        <div className={styles.address}>{address.address}</div>
                    ) : null}
                    {address.city !== null ? (
                        <div className={styles.city}>{address.city}</div>
                    ) : null}
                    {address.postalCode !== null ? (
                        <div className={styles.postalCode}>{address.postalCode}</div>
                    ) : null}
                </div>
            ) : null}

            {districts !== null && districts.length > 0 ? (
                <div
                    className={classNames([
                        styles.details,
                        {
                            [detailsClassName]: detailsClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage
                            {...messages.districts}
                            values={{ count: districts.length }}
                        />
                    </h4>
                    <ul>
                        {districts.map((district, i) => (
                            <li key={`district-${district.id}-${i + 1}`}>
                                <Link to={district.url}>{district.title}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}

            {sectors !== null && sectors.length > 0 ? (
                <div
                    className={classNames([
                        styles.details,
                        {
                            [detailsClassName]: detailsClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage
                            {...messages.sectors}
                            values={{ count: sectors.length }}
                        />
                    </h4>
                    <ul>
                        {sectors.map((category, i) => (
                            <li key={`category-${category.id}-${i + 1}`}>{category.name}</li>
                        ))}
                    </ul>
                </div>
            ) : null}

            {disciplines !== null && disciplines.length > 0 ? (
                <div
                    className={classNames([
                        styles.details,
                        {
                            [detailsClassName]: detailsClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage
                            {...messages.categories}
                            values={{ count: disciplines.length }}
                        />
                    </h4>
                    <ul>
                        {disciplines.map((category, i) => (
                            <li key={`category-${category.id}-${i + 1}`}>{category.name}</li>
                        ))}
                    </ul>
                </div>
            ) : null}

            {contact !== null ? (
                <div
                    className={classNames([
                        styles.details,
                        {
                            [detailsClassName]: detailsClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage {...messages.contact} />
                    </h4>
                    {contact.name !== null ? (
                        <div className={styles.name}>{contact.name}</div>
                    ) : null}
                    {contact.phone !== null ? (
                        <div className={styles.phone}>{contact.phone}</div>
                    ) : null}
                    {contact.email !== null ? (
                        <div className={styles.email}>
                            <a href={`mailto:${contact.email}`}>{contact.email}</a>
                        </div>
                    ) : null}
                    {contact.url !== null ? (
                        <div className={styles.link}>
                            <a href={contact.url}>{contact.url}</a>
                        </div>
                    ) : null}
                </div>
            ) : null}

            {links !== null && links.length > 0 ? (
                <div
                    className={classNames([
                        styles.details,
                        {
                            [detailsClassName]: detailsClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage {...messages.links} values={{ count: links.length }} />
                    </h4>
                    <ul>
                        {links.map((link, i) => (
                            <li key={`link-${link.url}-${i + 1}`}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer">
                                    {link.label || link.url}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}

            {documents !== null && documents.length > 0 ? (
                <div
                    className={classNames([
                        styles.details,
                        {
                            [detailsClassName]: detailsClassName !== null,
                        },
                    ])}
                >
                    <h4>
                        <FormattedMessage
                            {...messages.documents}
                            values={{ count: documents.length }}
                        />
                    </h4>
                    <ul>
                        {documents.map((document, i) => (
                            <li key={`document-${document.url}-${i + 1}`}>
                                <a href={document.url} target="_blank" rel="noopener noreferrer">
                                    {document.title || document.filename}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}
        </div>
    </div>
);

ContactDetails.propTypes = propTypes;
ContactDetails.defaultProps = defaultProps;

export default ContactDetails;
