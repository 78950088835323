import React from 'react';
import PropTypes from 'prop-types';

const pathsMap = {
    left: 'M17.7,0v7.2L9.9,15h37.5v5.4H9.9l7.8,7.8v7.2L0,17.7L17.7,0z',
    right: 'M47.4,17.7L29.7,35.4v-7.2l7.8-7.8H0V15h37.5l-7.8-7.8V0L47.4,17.7z',
};

const propTypes = {
    color: PropTypes.string,
    direction: PropTypes.oneOf(Object.keys(pathsMap)),
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    direction: 'right',
    className: null,
};

const ArrowIcon = ({ color, direction, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="47.4px"
        height="35.4px"
        viewBox="0 0 47.4 35.4"
        xmlSpace="preserve"
        className={className}
    >
        <path fill={color} d={pathsMap[direction]} />
    </svg>
);

ArrowIcon.propTypes = propTypes;
ArrowIcon.defaultProps = defaultProps;

export default ArrowIcon;
