import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import styles from '../../../styles/partials/page-status.scss';

const messages = defineMessages({
    draft: {
        id: 'content.page_status.draft',
        defaultMessage: 'Brouillon',
    },
    waiting: {
        id: 'content.page_status.waiting',
        defaultMessage: 'En attente',
    },
    published: {
        id: 'content.page_status.published',
        defaultMessage: 'Publié',
    },
});

const propTypes = {
    status: PropTypes.string,
    date: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    status: 'published',
    date: null,
    className: null,
};

const PageHeader = ({ status, date, className }) => (
    <div
        className={classNames({
            [styles.container]: true,
            [className]: className !== null,
        })}
    >
        <div className={classNames([styles.inner])}>
            <FormattedMessage {...messages[status]} />
            {' '}
            <span>{date}</span>
        </div>
    </div>
);

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default PageHeader;
