import React from 'react';
import PropTypes from 'prop-types';

import { pascalCase } from '../../lib/utils';
import * as CardComponents from './index';

const propTypes = {
    item: PropTypes.shape({ type: PropTypes.string }),
    type: PropTypes.string,
    components: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
};

const defaultProps = {
    item: null,
    type: null,
    components: CardComponents,
};

const Card = ({
    item, components, type, ...props
}) => {
    let CardComponent = null;
    if (type !== null) {
        CardComponent = components[pascalCase(type)] || null;
    } else if (item !== null) {
        CardComponent = components[pascalCase(item.type)] || null;
    }
    if (CardComponent === null) {
        return null;
    }
    return <CardComponent {...props} {...item} />;
};

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default React.memo(Card);
