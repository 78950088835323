/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import Card from '../cards/Card';
import { isMessage } from '../../lib/utils';

import styles from '../../../styles/partials/cards-list.scss';

const messages = defineMessages({
    noResults: {
        id: 'content.no_results',
        defaultMessage: 'Aucun résultat',
    },
});

const propTypes = {
    items: PropTypes.oneOfType([AppPropTypes.pages, AppPropTypes.events]),
    isLoading: PropTypes.bool,
    loadingCards: PropTypes.number,
    columns: PropTypes.oneOf([3, 4]),
    largeSpacing: PropTypes.bool,
    withNoResultsMessage: PropTypes.bool,
    noResultsMessage: AppPropTypes.label,
    className: PropTypes.string,
};

const defaultProps = {
    items: [],
    isLoading: false,
    loadingCards: 0,
    columns: 3,
    largeSpacing: false,
    noResultsMessage: messages.noResults,
    withNoResultsMessage: false,
    className: null,
};

const CardsList = ({
    items,
    isLoading,
    loadingCards,
    columns,
    largeSpacing,
    noResultsMessage,
    withNoResultsMessage,
    className,
    ...props
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [styles.largeSpacing]: largeSpacing,
                [styles.isLoading]: isLoading,
                [styles[`columns${columns}`]]: true,
                [className]: className !== null,
            },
        ])}
    >
        {items.length > 0 || (isLoading && loadingCards > 0) ? (
            <div className={styles.items}>
                {items.map((it, idx) => (
                    <div key={`item-${it.id}-${idx + 1}`} className={styles.item}>
                        <Card {...props} item={it} isLoading={isLoading} />
                    </div>
                ))}
                {isLoading && items.length === 0 && loadingCards > 0
                    ? [...Array(loadingCards)].map((it, index) => (
                        <div key={`item-${index}`} className={styles.item}>
                            <Card {...props} isLoading={isLoading} />
                        </div>
                    ))
                    : null}
            </div>
        ) : null}
        {!isLoading && items.length === 0 && withNoResultsMessage ? (
            <div className={styles.noResults}>
                {isMessage(noResultsMessage) ? (
                    <FormattedMessage {...noResultsMessage} />
                ) : (
                    noResultsMessage
                )}
            </div>
        ) : null}
        {isLoading ? <div className={styles.loading} /> : null}
    </div>
);

CardsList.propTypes = propTypes;
CardsList.defaultProps = defaultProps;

export default CardsList;
