/* eslint-disable react/no-danger, react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Button from '../buttons/Button';
import Text from './Text';
import Checkmark from '../icons/Checkmark';

import { clearQuiz as clearQuizAction } from '../../actions/QuizActions';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/blocks/quiz-results.scss';

const propTypes = {
    results: PropTypes.object, // eslint-disable-line
    text: PropTypes.string,
    count: PropTypes.number,
    isSection: PropTypes.bool,
    clearQuiz: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    results: {},
    text: null,
    count: 0,
    isSection: false,
    clearQuiz: null,
    className: null,
};

const QuizResults = ({
    results, text, count, isSection, clearQuiz, className,
}) => {
    const good = Object.keys(results).reduce((total, idx) => total + (results[idx] ? 1 : 0), 0);
    const green = count > 0 && good > count / 2;
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.green]: green,
                    [styles.isSection]: isSection,
                    [className]: className !== null,
                },
            ])}
        >
            {Object.keys(results).length >= count ? (
                <div className={styles.inner}>
                    <Checkmark className={styles.icon} stroke={green ? '#f5f7f5' : '#fee0dd'} />
                    <div className={styles.count}>
                        <h4 className={styles.text}>{`${good} / ${count}`}</h4>
                    </div>
                    <Text className={styles.explaination} text={text} />
                    <Button className={styles.button} onClick={clearQuiz}>
                        Reccomencer
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

QuizResults.propTypes = propTypes;
QuizResults.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ quiz }) => ({
        results: quiz ? quiz.results : {},
        count: quiz ? quiz.count : 0,
    }),
    dispatch => ({
        clearQuiz: () => dispatch(clearQuizAction()),
    }),
)(QuizResults);

export default WithStateContainer;
