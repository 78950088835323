import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedDate, FormattedTime } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { getDateObject } from '../../lib/utils';

import styles from '../../../styles/partials/magazine-author.scss';

const propTypes = {
    name: PropTypes.string,
    image: AppPropTypes.image,
    district: AppPropTypes.district,
    date: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    name: null,
    image: null,
    district: null,
    date: null,
    className: null,
};

const MagazineAuthor = ({
    name, image, district, date, className,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <div className={styles.imageContainer}>
            <div
                className={styles.image}
                style={{ backgroundImage: image !== null ? `url("${image.url}")` : null }}
            />
        </div>
        <div className={styles.label}>
            {name !== null ? (
                <div className={styles.name}>
                    <strong>{name}</strong>
                    {district !== null ? `- ${district.name}` : null}
                </div>
            ) : null}
            {date !== null ? (
                <div className={styles.date}>
                    <FormattedDate
                        value={getDateObject(date)}
                        day="2-digit"
                        month="long"
                        year="numeric"
                    />
                    {', '}
                    <FormattedTime value={getDateObject(date)} />
                </div>
            ) : null}
        </div>
    </div>
);

MagazineAuthor.propTypes = propTypes;
MagazineAuthor.defaultProps = defaultProps;

export default MagazineAuthor;
