import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const YouTubeIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="18px"
        height="12.7px"
        viewBox="0 0 18 12.7"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            d="M6.6,0c1.2,0,2.3,0,3.5,0c1.4,0,2.9,0.1,4.3,0.1c0.5,0,1,0,1.6,0.1c0.8,0.2,1.3,0.6,1.6,1.3c0.2,0.6,0.3,1.1,0.4,1.7C18,4.7,18,6.1,18,7.5c0,0.9-0.1,1.8-0.2,2.6c-0.1,0.4-0.2,0.7-0.3,1.1c-0.4,0.8-1,1.2-1.9,1.3c-1.2,0.1-2.3,0.1-3.5,0.2c-1.2,0-2.5,0.1-3.7,0.1c-0.8,0-1.7,0-2.5-0.1c-1,0-2-0.1-3-0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.6-0.2-0.9-0.6-1.2-1.2C0.3,10.5,0.2,10,0.1,9.4C0,7.9,0,6.5,0,5.1c0-0.8,0.1-1.7,0.2-2.5c0.1-0.4,0.2-0.8,0.4-1.1c0.4-0.7,0.9-1.1,1.7-1.2c0.6-0.1,1.3-0.1,1.9-0.1C5,0.1,5.8,0.1,6.6,0C6.6,0,6.6,0,6.6,0z M7.2,8.7C8.8,7.8,10.4,7,12,6.2c-1.6-0.8-3.2-1.7-4.8-2.5C7.2,5.3,7.2,7,7.2,8.7z"
        />
    </svg>
);

YouTubeIcon.propTypes = propTypes;
YouTubeIcon.defaultProps = defaultProps;

export default YouTubeIcon;
