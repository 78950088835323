import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/partials/loading-bar.scss';

const propTypes = {
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
    barClassName: PropTypes.string,
};

const defaultProps = {
    loading: false,
    loaded: false,
    color: null,
    className: null,
    barClassName: null,
};

const LoadingBar = ({
    loading, loaded, color, className, barClassName,
}) => (
    <div
        className={classNames({
            [styles.container]: true,
            [styles.loading]: loading,
            [styles.loaded]: loaded,
            [className]: className !== null,
        })}
    >
        <div
            className={classNames({
                [styles.bar]: true,
                [barClassName]: barClassName !== null,
            })}
            style={{
                backgroundColor: color,
            }}
        />
    </div>
);

LoadingBar.propTypes = propTypes;
LoadingBar.defaultProps = defaultProps;

export default LoadingBar;
