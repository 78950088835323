import React from 'react';
import Loadable from 'react-loadable';

const Lottie = Loadable({
    loader: () => import('react-lottie'),
    loading: () => null,
    // eslint-disable-next-line react/prop-types
    render: ({ default: LoadedComponent }, { animRef = null, forwardedRef = null, ...props }) => (
        <LoadedComponent
            {...props}
            ref={
                animRef !== null || forwardedRef !== null
                    ? (el) => {
                        if (animRef !== null) {
                            animRef(el !== null ? el.anim : null);
                        }
                        if (forwardedRef !== null) {
                            forwardedRef(el);
                        }
                    }
                    : null
            }
        />
    ),
});

const LottieWithRef = React.forwardRef((props, ref) => <Lottie {...props} forwardedRef={ref} />);

export default LottieWithRef;
