import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import PlusIcon from '../icons/Plus';
import MinusIcon from '../icons/Minus';
import Button from '../buttons/Button';
import Filters from './Filters';

import styles from '../../../styles/partials/filters-bar.scss';

const messages = defineMessages({
    moreFilters: {
        id: 'content.filters_more',
        defaultMessage: 'Plus de critères',
    },
    lessFilters: {
        id: 'content.filters_less',
        defaultMessage: 'Moins de critères',
    },
});

const propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: AppPropTypes.label,
            options: AppPropTypes.toggleOptions,
        }),
    ),
    value: PropTypes.shape({
        timeSpan: PropTypes.string,
    }),
    className: PropTypes.string,
    onChange: PropTypes.func,
};

const defaultProps = {
    options: [],
    value: null,
    className: null,
    onChange: null,
};

class FiltersBar extends PureComponent {
    static getDerivedStateFromProps(props, state) {
        const valueChanged = props.value !== state.value;
        if (valueChanged) {
            return {
                value: props.value,
                opened:
                    props.value !== null && state.value === null && !state.opened
                        ? true
                        : state.opened,
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.onClickOpen = this.onClickOpen.bind(this);

        this.state = {
            value: null, // eslint-disable-line react/no-unused-state
            opened: props.value !== null,
        };
    }

    onClickOpen() {
        this.setState(({ opened }) => ({
            opened: !opened,
        }));
    }

    render() {
        const {
            options, value, className, onChange,
        } = this.props;
        const { opened } = this.state;
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.opened]: opened,
                        [className]: className !== null,
                    },
                ])}
            >
                <div className={styles.cols}>
                    <div className={classNames([styles.col, styles.colButton])}>
                        <Button
                            icon={
                                opened ? (
                                    <MinusIcon className={styles.icon} />
                                ) : (
                                    <PlusIcon className={styles.icon} />
                                )
                            }
                            transparent
                            iconPosition="inline"
                            className={styles.moreButton}
                            onClick={this.onClickOpen}
                        >
                            {opened ? messages.lessFilters : messages.moreFilters}
                        </Button>
                    </div>
                    <div className={classNames([styles.col, styles.colFilters])}>
                        <Filters
                            options={options}
                            value={value}
                            dropdownButtonClassName={styles.dropdownButton}
                            colClassName={styles.col}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

FiltersBar.propTypes = propTypes;
FiltersBar.defaultProps = defaultProps;

export default FiltersBar;
