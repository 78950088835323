import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { defineMessages, FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/blocks/image.scss';

const messages = defineMessages({
    by: {
        id: 'content.photo_by',
        defaultMessage: 'Photo par {name}',
    },
});

const propTypes = {
    page: AppPropTypes.page,
    image: PropTypes.shape({
        url: PropTypes.string,
        sizes: PropTypes.shape({
            large: PropTypes.string,
        }),
    }),
    description: PropTypes.string,
    credits: PropTypes.string,
    isSection: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    page: null,
    image: null,
    description: null,
    credits: null,
    isSection: false,
    className: null,
};

const ImageBlock = ({
    page, image, description, credits, isSection, className,
}) => {
    const hasDescription = !isEmpty(description);
    const hasCredits = !isEmpty(credits);
    const hasDetails = hasDescription || hasCredits;
    return (
        <div
            className={classNames({
                [styles.container]: true,
                [styles.isSection]: isSection,
                [className]: className !== null,
            })}
        >
            <div className={styles.inner}>
                {image !== null ? (
                    <img src={image.sizes.large} alt={page.title} className={styles.image} />
                ) : null}
                {hasDetails ? (
                    <div className={styles.details}>
                        {hasDescription ? (
                            <p className={styles.description}>{description}</p>
                        ) : null}
                        {hasCredits ? (
                            <div className={styles.credits}>
                                <FormattedMessage {...messages.by} values={{ name: credits }} />
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

ImageBlock.propTypes = propTypes;
ImageBlock.defaultProps = defaultProps;

export default ImageBlock;
