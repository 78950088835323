/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages } from 'react-intl';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import { getQueryStringFromFilters } from '../../lib/utils';
import { setList as setListActions } from '../../actions/EventsActions';
import { withListLoader, listPropType } from '../../lib/withListLoader';
import withFilters from '../../lib/withFilters';

import PageMeta from '../partials/PageMeta';
import PageHeader from '../partials/PageHeader';
import EventsFilters from '../partials/EventsFilters';
import CardsList from '../partials/CardsList';
import Button from '../buttons/Button';
import Detector from '../partials/Detector';

import styles from '../../../styles/pages/list-events.scss';

const messages = defineMessages({
    map: {
        id: 'content.events_view_map',
        defaultMessage: 'Carte',
    },
    list: {
        id: 'content.events_view_grid',
        defaultMessage: 'Grille',
    },
    moreButton: {
        id: 'content.more_events_button',
        defaultMessage: 'Plus d’événements...',
    },
    loadingMore: {
        id: 'content.more_button_loading',
        defaultMessage: 'Chargement...',
    },
});

const propTypes = {
    page: AppPropTypes.page.isRequired,
    filters: AppPropTypes.eventsFilters,
    list: listPropType.isRequired,
    setFilters: PropTypes.func.isRequired,
    loadNextPage: PropTypes.func.isRequired,
};

const defaultProps = {
    filters: null,
};

class ListEventGroups extends Component {
    constructor(props) {
        super(props);
        this.onFiltersChange = this.onFiltersChange.bind(this);
        this.onClickMore = this.onClickMore.bind(this);
        this.refContent = null;

        this.state = {
            items: null, // eslint-disable-line react/no-unused-state
        };
    }

    onFiltersChange(value) {
        const { setFilters } = this.props;
        setFilters(value);
    }

    onClickMore() {
        const { loadNextPage } = this.props;
        loadNextPage();
    }

    render() {
        const { page, filters, list } = this.props;
        const { isLoading, items, currentPage, lastPage, total } = list;

        return (
            <div className={styles.container}>
                <PageMeta {...page.share} canonical={filters !== null} noIndex={filters !== null} />
                <div className={styles.inner}>
                    <PageHeader
                        title={page.title}
                        description={page.description}
                        big
                        className={styles.header}
                        colRightClassName={styles.colRight}
                    />
                    <div className={styles.filters}>
                        <EventsFilters
                            value={filters}
                            filtersOptions={null}
                            onChange={this.onFiltersChange}
                        />
                    </div>
                    <div
                        className={styles.content}
                        ref={(ref) => {
                            this.refContent = ref;
                        }}
                    >
                        <CardsList
                            items={items}
                            isLoading={total === 0 && isLoading}
                            type="event"
                            loadingCards={3}
                            withNoResultsMessage
                        />
                        {currentPage < lastPage ? (
                            <div className={styles.loadMore}>
                                <Detector onEnter={this.onClickMore} disabled={isLoading}>
                                    <Button red disabled={isLoading} onClick={this.onClickMore}>
                                        {isLoading ? messages.loadingMore : messages.moreButton}
                                    </Button>
                                </Detector>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

ListEventGroups.propTypes = propTypes;
ListEventGroups.defaultProps = defaultProps;

const WithListLoaderContainer = withListLoader(
    ({ urlGenerator, page, filters }) => {
        const url = urlGenerator
            .route('events_group.list', {
                term: page.id,
                term_slug: page.slug,
            })
            .replace(/(\.json|\/)?$/, '.json');
        return `${url}${
            filters !== null
                ? `?${queryString.stringify(filters, {
                      arrayFormat: 'bracket',
                  })}`
                : ''
        }`;
    },
    (props) => props,
)(ListEventGroups);

const WithFiltersContainer = withFilters({
    timeSpan: {
        multiple: false,
        type: 'string',
    },
    q: {
        multiple: false,
        type: 'string',
    },
    children: {
        multiple: false,
        type: 'bool',
    },
    tags: true,
    types: true,
    venues: true,
    regions: true,
    audiences: true,
    organizations: true,
})(WithListLoaderContainer);

const WithStateContainer = connect(
    ({ events }) => ({
        ...events,
    }),
    (dispatch, { location }) => ({
        setFilters: (filters) => dispatch(push(`${location}${getQueryStringFromFilters(filters)}`)),
        setList: (data) => dispatch(setListActions(data)),
    }),
)(WithFiltersContainer);
const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);

export default WithUrlGeneratorContainer;
