/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PodcastPlayer from '../partials/Podcast';

import styles from '../../../styles/blocks/podcast.scss';

const propTypes = {
    podcast: PropTypes.shape({
        url: PropTypes.string,
    }),

    isSection: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    podcast: null,
    isSection: false,
    className: null,
};

const PodcastBlock = ({ podcast, isSection, className }) =>
    podcast !== null ? (
        <div
            className={classNames({
                [styles.container]: true,
                [styles.isSection]: isSection,
                [className]: className !== null,
            })}
        >
            <div className={styles.wrapper}>
                <PodcastPlayer {...podcast} />
            </div>
        </div>
    ) : null;

PodcastBlock.propTypes = propTypes;
PodcastBlock.defaultProps = defaultProps;

export default PodcastBlock;
