import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import { getFiltersFromQuery } from './utils';

const propTypes = {
    query: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    forwardedRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

const defaultProps = {
    query: null,
    forwardedRef: null,
};

const defaultPropsToFilters = query => query;

const withFilters = (propsToFilters = defaultPropsToFilters) => (WrappedComponent) => {
    const WithFilters = ({ forwardedRef, ...props }) => {
        const { query } = props;
        return (
            <WrappedComponent
                filters={
                    isFunction(propsToFilters)
                        ? propsToFilters(props)
                        : getFiltersFromQuery(query, propsToFilters)
                }
                ref={forwardedRef}
                {...props}
            />
        );
    };

    WithFilters.propTypes = propTypes;
    WithFilters.defaultProps = defaultProps;

    return React.forwardRef((props, ref) => <WithFilters {...props} forwardedRef={ref} />);
};

export default withFilters;
