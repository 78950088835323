import React from 'react';
// import PropTypes from 'prop-types';

import * as AppPropTypes from '../../lib/PropTypes';
import Magazine from './Magazine';
import messages from './messages';

const propTypes = {
    actionButtonLabel: AppPropTypes.label,
};

const defaultProps = {
    actionButtonLabel: messages.watchButton,
};

const MagazinePhotoCard = (props) => <Magazine {...props} />;

MagazinePhotoCard.propTypes = propTypes;
MagazinePhotoCard.defaultProps = defaultProps;

export default MagazinePhotoCard;
