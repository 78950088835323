import { defineMessages } from 'react-intl';

export default defineMessages({
    readButton: {
        id: 'content.card_read_button',
        defaultMessage: 'Lire',
    },
    watchButton: {
        id: 'content.card_watch_button',
        defaultMessage: 'Regarder',
    },
    listenButton: {
        id: 'content.card_listen_button',
        defaultMessage: 'Écouter',
    },
});
