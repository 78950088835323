import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PageHeader from './PageHeader';
import MagazineIcon from '../icons/Magazine';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/partials/magazine-header.scss';

const propTypes = {
    href: PropTypes.string,
    title: PropTypes.string,
    right: PropTypes.node,
    isPageHeading: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    href: null,
    title: null,
    right: null,
    isPageHeading: true,
    className: null,
};

const MagazineHeader = ({
    href, title, right, isPageHeading, className,
}) => (
    <PageHeader
        href={href}
        title={title}
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
        titleClassName={styles.title}
        icon={<MagazineIcon className={styles.icon} withStroke />}
        right={right}
        isPageHeading={isPageHeading}
    />
);

MagazineHeader.propTypes = propTypes;
MagazineHeader.defaultProps = defaultProps;

export default MagazineHeader;
