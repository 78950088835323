/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import { getQueryStringFromFilters, getFiltersFromQuery } from '../../lib/utils';
import {
    setList as setListActions,
    setView as setViewActions,
} from '../../actions/MagazinesActions';
import { setFooterPurple as setFooterPurpleActions } from '../../actions/LayoutActions';

import { withListLoader, listPropType } from '../../lib/withListLoader';
import withFilters from '../../lib/withFilters';

import PageMeta from '../partials/PageMeta';
import MagazineHeader from '../partials/MagazineHeader';
import Magazine from '../magazines/Magazine';
import Card from '../cards/Card';
import MagazinesFilters from '../partials/MagazinesFilters';
import Button from '../buttons/Button';
import ListIcon from '../icons/List';
import MagListIcon from '../icons/MagList';
import Toggles from '../buttons/Toggles';
import Detector from '../partials/Detector';

import styles from '../../../styles/pages/list-magazine.scss';

const messages = defineMessages({
    moreButton: {
        id: 'content.more_magazines_button',
        defaultMessage: 'Charger plus d’articles...',
    },
    loadingMore: {
        id: 'content.more_button_loading',
        defaultMessage: 'Chargement...',
    },
    list: {
        id: 'content.events_view_list',
        defaultMessage: 'Liste',
    },
    grid: {
        id: 'content.events_view_grid',
        defaultMessage: 'Grille',
    },
    noResults: {
        id: 'content.no_results_crit',
        defaultMessage: 'Pas de résultats pour ces critères',
    },
});

const propTypes = {
    page: AppPropTypes.page.isRequired,
    list: listPropType.isRequired,
    loadNextPage: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setFooterPurple: PropTypes.func.isRequired,
    view: PropTypes.string,
    views: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: AppPropTypes.label,
        }),
    ),
    setView: PropTypes.func.isRequired,
    filters: AppPropTypes.magazinesFilters,
};

const defaultProps = {
    views: [
        {
            value: 'grid',
            label: messages.grid,
            icon: <ListIcon className={styles.icon} />,
        },
        {
            value: 'list',
            label: messages.list,
            icon: <MagListIcon className={styles.icon} />,
        },
    ],
    view: 'list',
    filters: null,
};

class ListMagazine extends Component {
    constructor(props) {
        super(props);

        this.onFiltersChange = this.onFiltersChange.bind(this);
        this.onViewChange = this.onViewChange.bind(this);
        this.onClickMore = this.onClickMore.bind(this);

        this.refList = null;
    }

    componentDidMount() {
        const { setFooterPurple, view } = this.props;
        if (window && window.scrollTo) {
            window.scrollTo({ top: 0 });
        }
        if (view === 'grid') {
            setFooterPurple(true);
        }
    }

    componentWillUnmount() {
        const { setFooterPurple } = this.props;
        setFooterPurple(false);
    }

    onFiltersChange(value) {
        const { setFilters } = this.props;
        setFilters(value);
    }

    onViewChange(view) {
        const { setView, setFooterPurple } = this.props;
        setView(view);
        if (view === 'grid') {
            setFooterPurple(true);
        } else {
            setFooterPurple(false);
        }
    }

    onClickMore() {
        const { loadNextPage } = this.props;
        loadNextPage();
    }

    renderListView() {
        const { list } = this.props;
        const { items } = list;
        const itemsCount = items.length;
        if (itemsCount === 0) return null;

        return items.map((it, index) => {
            const isVideo = it.type === 'magazine_video' || it.type === 'magazine_podcast';
            const nextItem = items[index + 1] || null;
            const nextIsVideo =
                nextItem !== null &&
                (nextItem.type === 'magazine_video' || nextItem.type === 'magazine_podcast');
            return (
                <section
                    key={`magazine-${it.id}-${index + 1}`}
                    className={classNames([
                        styles.item,
                        {
                            [styles.isWhite]: !isVideo,
                            [styles.isPurple]: isVideo,
                            [styles.nextIsPurple]: nextIsVideo,
                        },
                    ])}
                    style={{ zIndex: itemsCount - index }}
                >
                    <Magazine
                        item={it}
                        short
                        className={styles.magazine}
                        headerClassName={styles.magazineHeader}
                    />
                </section>
            );
        });
    }

    renderGridView() {
        const { list } = this.props;
        const { items } = list;
        const itemsCount = items.length;
        if (itemsCount === 0) return null;

        return (
            <div className={styles.inner}>
                {items.map((it, index) => (
                    <div
                        key={`magazine-card-${it.id}-${index + 1}`}
                        className={classNames([styles.cardContainer])}
                    >
                        <div className={styles.card}>
                            <Card item={it} short />
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        const { page, list, filters, view, views } = this.props;
        const { isLoading, items, currentPage, lastPage, total } = list;
        const itemsCount = items.length || 0;
        const firstItem = items[0] || null;
        const { image = null } = firstItem || {};
        const firstItemHasImage = image !== null;
        const firstIsVideo =
            firstItem !== null &&
            (firstItem.type === 'magazine_video' || firstItem.type === 'magazine_podcast');

        return (
            <div className={styles.container}>
                <PageMeta {...page.share} canonical={filters !== null} noIndex={filters !== null} />
                <div className={styles.header}>
                    <div className={styles.inner}>
                        <MagazineHeader
                            title={page.title}
                            right={
                                <Toggles
                                    options={views}
                                    value={view}
                                    buttonClassName={styles.button}
                                    onChange={this.onViewChange}
                                />
                            }
                        />
                        <div className={styles.filters}>
                            <MagazinesFilters value={filters} onChange={this.onFiltersChange} />
                        </div>
                    </div>
                </div>
                <div
                    className={classNames([
                        styles.list,
                        {
                            [styles.list]: view === 'list',
                            [styles.grid]: view === 'grid',
                            [styles.firstIsPurple]:
                                (firstIsVideo && !firstItemHasImage) || view === 'grid',
                        },
                    ])}
                    ref={(ref) => {
                        this.refList = ref;
                    }}
                >
                    {view === 'grid' ? this.renderGridView() : this.renderListView()}
                    {currentPage < lastPage || isLoading ? (
                        <div
                            className={classNames([
                                styles.loadMore,
                                {
                                    [styles.first]: itemsCount === 0,
                                    [styles.purple]:
                                        items !== null &&
                                        itemsCount > 0 &&
                                        items[itemsCount - 1].type === 'magazine_video',
                                },
                            ])}
                        >
                            <Detector onEnter={this.onClickMore} disabled={isLoading}>
                                <Button
                                    className={styles.loadingButton}
                                    red
                                    disabled={isLoading}
                                    onClick={this.onClickMore}
                                >
                                    {isLoading ? messages.loadingMore : messages.moreButton}
                                </Button>
                            </Detector>
                        </div>
                    ) : null}
                    {isLoading === false && total === 0 ? (
                        <p
                            className={classNames([
                                styles.noResults,
                                {
                                    [styles.purple]:
                                        (firstIsVideo && !firstItemHasImage) || view === 'grid',
                                },
                            ])}
                        >
                            <FormattedMessage {...messages.noResults} />
                        </p>
                    ) : null}
                </div>
            </div>
        );
    }
}

ListMagazine.propTypes = propTypes;
ListMagazine.defaultProps = defaultProps;

const WithListLoaderContainer = withListLoader(
    ({ urlGenerator, page, filters }) => {
        const url = urlGenerator
            .route('pages.list', {
                page: page.slug,
            })
            .replace(/(\.json|\/)?$/, '.json');
        return `${url}${
            filters !== null
                ? `?${queryString.stringify(filters, {
                      arrayFormat: 'bracket',
                  })}`
                : ''
        }`;
    },
    (props) => props,
)(ListMagazine);
const WithFiltersContainer = withFilters(({ query, category }) => {
    if (query !== null && Object.keys(query).length > 0) {
        return getFiltersFromQuery(query, {
            types: {
                multiple: true,
                type: 'string',
            },
            categories: true,
        });
    }
    if (category !== null) {
        return {
            categories: [category.id],
        };
    }
    return null;
})(WithListLoaderContainer);
const WithStateContainer = connect(
    ({ magazines }) => ({
        ...magazines,
    }),
    (dispatch, { page }) => ({
        setFilters: (filters) => dispatch(push(`${page.url}${getQueryStringFromFilters(filters)}`)),
        setList: (data) => dispatch(setListActions(data)),
        setView: (data) => dispatch(setViewActions(data)),
        setFooterPurple: (data) => dispatch(setFooterPurpleActions(data)),
    }),
)(WithFiltersContainer);
const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);

export default WithUrlGeneratorContainer;
