import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';

import Carousel from './Carousel';

const messages = defineMessages({
    title: {
        id: 'content.suggested_magazines',
        defaultMessage: 'Suggestions de lecture',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    page: AppPropTypes.page.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
};

const defaultProps = {
    title: null,
};

const SuggestedMagazines = ({ intl, urlGenerator, page, id, title, ...props }) => (
    <Carousel
        url={urlGenerator.route('api.suggested_magazines', {
            pageId: page.id,
            block: id,
        })}
        id={id}
        title={title || intl.formatMessage(messages.title)}
        isFullWidth={false}
        page={page}
        // hideOnEmpty
        {...props}
    />
);
SuggestedMagazines.propTypes = propTypes;
SuggestedMagazines.defaultProps = defaultProps;

const WithStateContainer = connect(({ site = null }) => ({
    magazinesPage:
        site !== null
            ? Object.keys(site.pages).find((url) => site.pages[url].handle === 'magazines') || null
            : null,
}))(SuggestedMagazines);

const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);
const WithIntlContainer = injectIntl(WithUrlGeneratorContainer);
export default WithIntlContainer;
