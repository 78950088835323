/**
 * Constants
 */
export const SET_SIZE = 'layout@SET_SIZE';
export const SET_SCROLL = 'layout@SET_SCROLL';
export const SET_FONTS_LOADED = 'layout@SET_FONTS_LOADED';
export const SET_MENU_OPENED = 'layout@SET_MENU_OPENED';
export const SET_POPUP_CLOSED = 'layout@SET_POPUP_CLOSED';
export const SET_FOOTER_PURPLE = 'layout@SET_FOOTER_PURPLE';
/**
 * Actions creator
 */
export const setSize = (payload) => ({
    type: SET_SIZE,
    payload,
});

export const setScroll = (payload) => ({
    type: SET_SCROLL,
    payload,
});

export const setFontsLoaded = (payload) => ({
    type: SET_FONTS_LOADED,
    payload,
});

export const setMenuOpened = (payload) => ({
    type: SET_MENU_OPENED,
    payload,
});

export const setPopupClosed = (payload) => ({
    type: SET_POPUP_CLOSED,
    payload,
});

export const setFooterPurple = (payload) => ({
    type: SET_FOOTER_PURPLE,
    payload,
});
