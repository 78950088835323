/* eslint-disable indent */
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import queryString from 'query-string';
import toDate from 'date-fns/toDate';

export const pascalCase = (str) =>
    str
        .replace(/[^a-z0-9]+/gi, ' ')
        .replace(/(\w)(\w*)/g, (g0, g1, g2) => `${g1.toUpperCase()}${g2.toLowerCase()}`)
        .replace(/\s+/gi, '');

export const getPageUrlFromLocation = (location, match, urlGenerator) => {
    const hasCategory = (match.params.category || null) !== null;
    return hasCategory
        ? urlGenerator.route('pages.list.with_category', match.params)
        : location.pathname;
};

export const getPageFromLocation = (pages, location, match, urlGenerator) => {
    const url = getPageUrlFromLocation(location, match, urlGenerator);
    return pages[url] || null;
};

export const castFilter = (value, type) => {
    if (type === 'integer') {
        return parseInt(value, 10);
    }
    if (type === 'string') {
        return value;
    }
    if (type === 'bool' || type === 'boolean') {
        return value === 'true' || value === '1' || value === 1;
    }
    return value;
};

export const getFiltersFromQuery = (query, shape) => {
    if (query === null) {
        return null;
    }
    const filters = Object.keys(shape).reduce((allFilters, key) => {
        const filterShape = {
            multiple: true,
            type: 'integer',
            ...(shape[key] !== true ? shape[key] : null),
        };
        let value = query[key] || null;
        if (isEmpty(value)) {
            return allFilters;
        }
        if (filterShape.multiple && !isArray(value)) {
            value = [value];
        }
        return {
            ...allFilters,
            [key]: isArray(value)
                ? value.map((val) => castFilter(val, filterShape.type))
                : castFilter(value, filterShape.type),
        };
    }, {});
    return Object.keys(filters).length > 0 ? filters : null;
};

export const getQueryStringFromFilters = (filters) => {
    const query = Object.keys(filters).reduce(
        (allFilters, key) =>
            !isEmpty(filters[key])
                ? {
                      ...allFilters,
                      [key]: filters[key],
                  }
                : allFilters,
        null,
    );
    return query !== null
        ? `?${queryString.stringify(filters, {
              arrayFormat: 'bracket',
          })}`
        : '';
};

export const lastPageBlockColor = (page) => {
    const blocks = page.blocks || null;
    if (blocks === null || blocks.length === 0) {
        return null;
    }
    const lastBlock = blocks[blocks.length - 1];
    if (lastBlock.type === 'suggested_events') {
        return 'grey';
    }
    if (lastBlock.type === 'suggested_contacts') {
        return 'white';
    }
    return null;
};

export const lastMagazineIsVideo = (magazines) => {
    if (magazines === null) {
        return false;
    }
    const items = magazines.reduce(
        (prevItems, itemsPage) => [...prevItems, ...itemsPage.items],
        [],
    );
    const itemsCount = items.length;
    if (itemsCount === 0) {
        return false;
    }

    return (
        items[itemsCount - 1].type === 'magazine_video' ||
        items[itemsCount - 1].type === 'magazine_podcast'
    );
};

// prettier-ignore
export const getLocaleText = (text, locale, defaultValue = null) => (isString(text) || text === null
    ? text
    : get(text, locale, defaultValue));

// prettier-ignore
export const getLocaleTextWithFallback = (text, locale) => (text !== null
    ? getLocaleText(text, locale, Object.values(text)[0] || null)
    : null);

export const generateItemStaggerStyle = (itemSelector, itemsPerPage, maxPage = 1) => {
    const itemsStyles = [];
    for (let page = 0; page < maxPage; page += 1) {
        for (let i = 0; i < itemsPerPage; i += 1) {
            // prettier-ignore
            itemsStyles.push(`
                ${itemSelector}:nth-child(${(page * itemsPerPage) + i + 1}) {
                    opacity: 0;
                    animation-delay: ${0.1 * i}s;
                }
            `);
        }
    }
    return itemsStyles.join('\n');
};

export const prefixes = ['moz', 'webkit', 'ms', 'o'];

export const getPrefixedStyleProp = (prop) => {
    const root = document.documentElement;
    for (let i = 0; i < prefixes.length; i += 1) {
        const prefixedProp = `${prefixes[i]}${prop.substr(0, 1).toUpperCase()}${prop.substr(1)}`;
        if (typeof root.style[prefixedProp] !== 'undefined') {
            return prefixedProp;
        }
    }
    return prop;
};

export const setPrefixedStyle = (el, style) => {
    Object.keys(style).forEach((key) => {
        el.style[getPrefixedStyleProp(key)] = style[key]; // eslint-disable-line
    });
};

const dateCache = {};

export const parseDate = (dateStr) => {
    const dateParts = dateStr.split('-');
    return dateParts.length === 3
        ? {
              year: parseInt(dateParts[0], 10),
              month: parseInt(dateParts[1], 10),
              day: parseInt(dateParts[2], 10),
          }
        : null;
};

export const parseTime = (timeStr) => {
    const timeParts = timeStr.split(':');
    return timeParts.length >= 2 && timeParts.length <= 3
        ? {
              hours: parseInt(timeParts[0], 10),
              minutes: parseInt(timeParts[1], 10),
              seconds: parseInt(timeParts[2] || 0, 10),
          }
        : null;
};

export const getDateObject = (dateStr) => {
    const cachedDate = dateCache[dateStr] || null;
    if (cachedDate !== null) {
        return cachedDate;
    }
    const dateParts = dateStr.split(' ');
    const parsedDate = parseDate(dateParts[0]);
    const parsedTime = dateParts.length > 1 ? parseTime(dateParts[1]) : null;
    if (parsedDate === null && parsedTime === null) {
        return null;
    }
    
    let date = null;
    if (parsedDate !== null) {
        date = toDate(new Date(parsedDate.year, parsedDate.month - 1, parsedDate.day));
    }
    if (parsedTime !== null) {
        date = toDate(new Date(parsedDate.year, parsedDate.month - 1, parsedDate.day, parsedTime.hours, parsedTime.minutes, parsedTime.seconds));
    }
    dateCache[dateStr] = date;
    return date;
};

export const isMessage = (message) => isObject(message) && typeof message.id !== 'undefined';

export const addNonBreakingSpaces = (str) => str.replace(/&nbsp;/g, '\u00a0');
export const cleanExternalLinks = (str) =>
    str !== null
        ? str.replace(/(<a\\b[^<>]*href=['"]?http[^<>]+)>/gi, '$1 target="_blank">')
        : null;

const currentScroll = {
    x: 0,
    y: 0,
};
const emptyObject = {};
export const getScroll = () => {
    const { pageXOffset, pageYOffset } = window || emptyObject;
    const doc = (document || emptyObject).documentElement || emptyObject;
    // prettier-ignore
    currentScroll.x = (pageXOffset || doc.scrollLeft || 0) - (doc.clientLeft || 0);
    // prettier-ignore
    currentScroll.y = (pageYOffset || doc.scrollTop || 0) - (doc.clientTop || 0);
    return currentScroll;
};

export const getWindowWidth = () => {
    const width =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    return { width, height };
};
