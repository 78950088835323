import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';

import Button from './Button';

import styles from '../../../styles/buttons/toggles.scss';

const propTypes = {
    options: AppPropTypes.toggleOptions.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isGrouped: PropTypes.bool,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    currentClassName: PropTypes.string,
    onChange: PropTypes.func,
};

const defaultProps = {
    value: null,
    isGrouped: false,
    className: null,
    itemsClassName: null,
    itemClassName: null,
    buttonClassName: null,
    currentClassName: null,
    onChange: null,
};

class Toggles extends PureComponent {
    constructor(props) {
        super(props);
        this.onClickButton = this.onClickButton.bind(this);
    }

    onClickButton(e, choice) {
        const { value, onChange } = this.props;
        const newValue = choice.value ? choice.value : value;
        if (onChange !== null) {
            onChange(newValue);
        }
    }

    render() {
        const {
            options,
            value,
            isGrouped,
            className,
            itemsClassName,
            itemClassName,
            buttonClassName,
            currentClassName,
        } = this.props;

        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.isGrouped]: isGrouped,
                        [className]: className !== null,
                    },
                ])}
            >
                <ul
                    className={classNames([
                        styles.items,
                        {
                            [itemsClassName]: itemsClassName !== null,
                        },
                    ])}
                >
                    {options.map((it, index) => {
                        const {
                            value: optionValue,
                            label,
                            children = null,
                            className: optionClassName = null,
                            ...buttonProps
                        } = it;
                        return (
                            <li
                                className={classNames([
                                    styles.item,
                                    {
                                        [itemClassName]: itemClassName !== null,
                                        [optionClassName]: optionClassName !== null,
                                    },
                                ])}
                                key={`option-${optionValue}`}
                            >
                                <Button
                                    {...buttonProps}
                                    className={classNames([
                                        styles.button,
                                        {
                                            [styles.current]: optionValue === value,
                                            [buttonClassName]: buttonClassName !== null,
                                            [currentClassName]:
                                                currentClassName !== null && optionValue === value,
                                        },
                                    ])}
                                    onClick={e => this.onClickButton(e, it, index)}
                                >
                                    {label}
                                </Button>
                                {children}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

Toggles.propTypes = propTypes;
Toggles.defaultProps = defaultProps;

export default Toggles;
