import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { connect } from 'react-redux';
import WebFont from 'webfontloader';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import { lastPageBlockColor, lastMagazineIsVideo, getScroll } from '../../lib/utils';
import {
    setSize as setSizeActions,
    setScroll as setScrollActions,
    setFontsLoaded as setFontsLoadedActions,
    setPopupClosed as setPopupClosedActions,
    setFooterPurple as setFooterPurpleActions,
} from '../../actions/LayoutActions';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import LoadingBar from '../partials/LoadingBar';
import MapIcon from '../icons/Map';
import DistrictIcon from '../icons/District';
import Popup from '../partials/Popup';

import styles from '../../../styles/layouts/main.scss';

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    page: AppPropTypes.page,
    popup: AppPropTypes.popup,
    popupClosed: PropTypes.bool,
    statusCode: AppPropTypes.statusCode,
    magazines: AppPropTypes.magazines,
    fontsLoaded: PropTypes.bool.isRequired,
    footerIsPurple: PropTypes.bool.isRequired,
    setFontsLoaded: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    setSize: PropTypes.func.isRequired,
    setScroll: PropTypes.func.isRequired,
    setPopupClosed: PropTypes.func.isRequired,
    setFooterPurple: PropTypes.func.isRequired,
    isPrerender: PropTypes.bool,
    fonts: PropTypes.shape({
        google: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        custom: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    }),
    fontsTimeout: PropTypes.number,
    topMenuItems: AppPropTypes.menuItems.isRequired,
    socialMenuItems: AppPropTypes.menuItems.isRequired,
    mainMenuItems: AppPropTypes.menuItems.isRequired,
    menuOpened: PropTypes.bool.isRequired,
    footerMenuColumns: AppPropTypes.menuColumns.isRequired,
};

const defaultProps = {
    page: null,
    popup: null,
    popupClosed: false,
    magazines: [],
    statusCode: null,
    isPrerender: false,
    fonts: {
        custom: {
            families: [
                'TTCommons-Regular',
                'TTCommons-DemiBold',
                'TTCommons-Bold',
                'HeadingPro-Heavy',
            ],
        },
    },
    fontsTimeout: 2000,
};

class MainLayout extends Component {
    static isFooterSplashWhite({ page, magazines }) {
        return (
            page.type === 'magazine' ||
            page.type === 'magazine_photo' ||
            page.type === 'magazine_podcast' ||
            (page.type === 'magazine_video' && page.blocks !== null && page.blocks.length > 0) ||
            lastPageBlockColor(page) === 'white' ||
            (page.handle === 'magazine' && !lastMagazineIsVideo(magazines))
        );
    }

    static isFooterSplashGrey({ page }) {
        return (
            page.handle === 'districts' ||
            page.handle === 'directory' ||
            lastPageBlockColor(page) === 'grey'
        );
    }

    static hasMapBackground({ page }) {
        const handles = ['contact', 'partners', 'events'];
        return handles.indexOf(page.handle) !== -1;
    }

    static hasDistrictBackground({ page }) {
        return page.type === 'district';
    }

    static getDerivedStateFromProps(props, state) {
        const pageChanged = props.page !== state.page;
        if (pageChanged && props.page !== null) {
            return {
                page: props.page,
                magazines: props.magazines,
                footerSplashIsWhite: MainLayout.isFooterSplashWhite(props),
                footerSplashIsGrey: MainLayout.isFooterSplashGrey(props),
                hasMapBackground: MainLayout.hasMapBackground(props),
                hasDistrictBackground: MainLayout.hasDistrictBackground(props),
            };
        }
        const magazinesChanged = props.magazines !== state.magazines;
        if (magazinesChanged && props.page !== null) {
            return {
                magazines: props.magazines,
                footerSplashIsWhite: MainLayout.isFooterSplashWhite(props),
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.onResize = this.onResize.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onFontsActive = this.onFontsActive.bind(this);
        this.onFontsInactive = this.onFontsInactive.bind(this);
        this.onPopupClickClose = this.onPopupClickClose.bind(this);
        this.updateSize = this.updateSize.bind(this);
        this.updateSizeThrottled = throttle(this.updateSize, 1000 / 10, {
            leading: true,
            trailing: true,
        });
        this.updateScroll = this.updateScroll.bind(this);
        this.updateScrollThrottled = throttle(this.updateScroll, 1000 / 20, {
            leading: true,
            trailing: true,
        });

        this.state = {
            page: null, // eslint-disable-line react/no-unused-state
            magazines: null, // eslint-disable-line react/no-unused-state
            footerSplashIsGrey: false,
            hasMapBackground: false,
            hasDistrictBackground: false,
        };
    }

    componentDidMount() {
        const { fonts, fontsTimeout } = this.props;
        WebFont.load({
            ...fonts,
            timeout: fontsTimeout,
            active: this.onFontsActive,
            inactive: this.onFontsInactive,
        });
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        this.updateSizeThrottled.cancel();
    }

    onFontsActive() {
        const { setFontsLoaded } = this.props;
        setFontsLoaded(true);
    }

    onFontsInactive() {
        const { setFontsLoaded } = this.props;
        setFontsLoaded(true);
    }

    onResize() {
        this.updateSizeThrottled();
    }

    onScroll() {
        this.updateScrollThrottled();
    }

    onPopupClickClose() {
        const { setPopupClosed } = this.props;
        setPopupClosed(true);
    }

    updateSize() {
        const { setSize } = this.props;
        setSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    updateScroll() {
        const { setScroll } = this.props;
        const { x, y } = getScroll();
        setScroll({
            x,
            y,
        });
    }

    render() {
        const {
            urlGenerator,
            page,
            popup,
            popupClosed,
            statusCode,
            children,
            fontsLoaded,
            isPrerender,
            topMenuItems,
            socialMenuItems,
            mainMenuItems,
            menuOpened,
            footerMenuColumns,
            footerIsPurple,
        } = this.props;
        const {
            footerSplashIsGrey,
            footerSplashIsWhite: footerIsWhite,
            hasMapBackground,
            hasDistrictBackground,
        } = this.state;

        const footerSplashIsWhite = footerIsWhite && !footerIsPurple;

        const ready = fontsLoaded || isPrerender;

        const style = {
            opacity: ready ? 1 : 0,
        };

        const showPopup = popup !== null && page && page.type === 'list_events';

        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.hasPopup]: showPopup && !popupClosed,
                    },
                ])}
                style={style}
            >
                <div className={styles.inner}>
                    <div className={styles.loading}>
                        <LoadingBar
                            loading={page === null && statusCode === null}
                            loaded={page !== null || statusCode !== null}
                        />
                    </div>
                    <div className={styles.header}>
                        <Header
                            homeLink={urlGenerator.route('home')}
                            topMenuItems={topMenuItems}
                            socialMenuItems={socialMenuItems}
                            mainMenuItems={mainMenuItems}
                            menuOpened={menuOpened}
                        />
                    </div>
                    <div className={styles.content}>{ready ? children : null}</div>
                    <div className={styles.footer}>
                        <Footer
                            menuColumns={footerMenuColumns}
                            splashIsGrey={footerSplashIsGrey}
                            splashIsWhite={footerSplashIsWhite}
                        />
                    </div>
                </div>
                <div className={styles.background}>
                    <div className={styles.inner}>
                        <div
                            className={classNames([
                                styles.map,
                                {
                                    [styles.visible]: hasMapBackground,
                                },
                            ])}
                        >
                            <MapIcon className={styles.icon} />
                        </div>
                        <div
                            className={classNames([
                                styles.district,
                                {
                                    [styles.visible]: false && hasDistrictBackground,
                                },
                            ])}
                        >
                            <DistrictIcon
                                name={
                                    hasDistrictBackground &&
                                    page !== null &&
                                    page.type === 'district'
                                        ? page.handle
                                        : null
                                }
                                className={styles.icon}
                            />
                        </div>
                    </div>
                </div>
                {showPopup ? (
                    <div
                        className={classNames([
                            styles.popupContainer,
                            {
                                [styles.closed]: popupClosed,
                            },
                        ])}
                    >
                        <div className={styles.popup}>
                            <Popup {...popup} page={page} onClickClose={this.onPopupClickClose} />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ layout, site, magazines }, { page = null }) => ({
        size: layout.size,
        footerIsPurple: layout.footerIsPurple || false,
        fontsLoaded: layout.fontsLoaded,
        isPrerender: site.isPrerender || false,
        statusCode: site.statusCode,
        page,
        menuOpened: layout.menuOpened,
        topMenuItems: site.headerTopMenu.map((it) => ({
            ...it,
            current: page !== null && page.url === it.url,
        })),
        socialMenuItems: site.socialMenu,
        mainMenuItems: site.headerMainMenu.map((it) => ({
            ...it,
            current: page !== null && page.url === it.url,
        })),
        footerMenuColumns: site.footerMenu,
        magazines: magazines.items,
        popup: site.popup,
        popupClosed: layout.popupClosed,
    }),
    (dispatch) => ({
        setSize: (size) => dispatch(setSizeActions(size)),
        setScroll: (scroll) => dispatch(setScrollActions(scroll)),
        setFontsLoaded: (loaded) => dispatch(setFontsLoadedActions(loaded)),
        setPopupClosed: (loaded) => dispatch(setPopupClosedActions(loaded)),
        setFooterPurple: (purple) => dispatch(setFooterPurpleActions(purple)),
    }),
)(MainLayout);
const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);

export default WithUrlGeneratorContainer;
