/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import get from 'lodash/get';
import classNames from 'classnames';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import { sendMessage } from '../../lib/requests';
import Button from '../buttons/Button';
import CheckboxButton from '../buttons/Checkbox';
import { withTracking } from '../../lib/TrackingContext';

import styles from '../../../styles/partials/contact-form.scss';

const messages = defineMessages({
    error: {
        id: 'content.contact_error',
        defaultMessage: 'Il s’est produit une erreur.',
    },
    success: {
        id: 'content.contact_success',
        defaultMessage: 'Votre message a été envoyé',
    },
    emailLabel: {
        id: 'content.contact_email_label',
        defaultMessage: 'Votre courriel',
    },
    emailPlaceholder: {
        id: 'content.contact_email_placeholder',
        defaultMessage: 'votre@courriel.ca',
    },
    messageLabel: {
        id: 'content.contact_message_label',
        defaultMessage: 'Votre message',
    },
    messagePlaceholder: {
        id: 'content.contact_message_placeholder',
        defaultMessage: 'Message...',
    },
    newsletterLabel: {
        id: 'content.contact_newsletter_label',
        defaultMessage: 'Je veux m’inscrire à l’infolettre',
    },
    button: {
        id: 'content.contact_button',
        defaultMessage: 'Envoyer',
    },
});

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    intl: AppPropTypes.intl.isRequired,
    trackEvent: PropTypes.func.isRequired,
    className: PropTypes.string,
    onSubmitted: PropTypes.func,
};

const defaultProps = {
    className: null,
    onSubmitted: null,
};

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);
        this.onClickNewsletterCheckbox = this.onClickNewsletterCheckbox.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitted = this.onSubmitted.bind(this);
        this.onSubmitError = this.onSubmitError.bind(this);

        this.state = {
            email: null,
            errorField: null,
            message: null,
            newsletter: false,
            loading: false,
            error: null,
            submitted: false,
        };
    }

    onSubmit(e) {
        e.preventDefault();

        const { email, message } = this.state;
        if (
            email === null
            || trim(email).length < 1
            || message === null
            || trim(message).length < 1
        ) {
            return;
        }

        this.setState({
            loading: true,
            error: null,
            errorField: null,
        });
        this.submit();
    }

    onEmailChange(e) {
        this.setState({
            email: e.target.value,
            error: null,
        });
    }

    onMessageChange(e) {
        this.setState({
            message: e.target.value,
            error: null,
        });
    }

    onClickNewsletterCheckbox() {
        this.setState(({ newsletter }) => ({
            newsletter: !newsletter,
            error: null,
        }));
    }

    onSubmitted({ success }) {
        const { onSubmitted, intl, trackEvent } = this.props;
        const { email } = this.state;
        if (onSubmitted !== null) {
            if (!success) {
                this.setState({
                    loading: false,
                    error: intl.formatMessage(messages.error),
                });
            } else {
                onSubmitted();
            }
        } else {
            this.setState({
                email: null,
                message: null,
                newsletter: false,
                loading: false,
                submitted: success,
                error: !success ? intl.formatMessage(messages.error) : null,
            });
        }

        if (success) {
            trackEvent('ContactForm', 'submitted', email);
        }
    }

    // eslint-disable-next-line
    onSubmitError(error) {
        const { intl } = this.props;
        if (error.name === 'ValidationError') {
            const errorFields = Object.keys(get(error.responseData, 'errors', null));
            this.setState({
                loading: false,
                errorField: errorFields.length > 0 ? errorFields[0] : null,
                error: intl.formatMessage(messages.error),
            });
        } else {
            this.setState({
                loading: false,
                error: intl.formatMessage(messages.error),
            });
        }
    }

    submit() {
        const { urlGenerator } = this.props;
        const { email, message, newsletter } = this.state;
        const url = urlGenerator.route('api.contact');
        return sendMessage(url, email, message, newsletter)
            .then(this.onSubmitted)
            .catch(this.onSubmitError);
    }

    render() {
        const { intl, urlGenerator, className } = this.props;
        const {
            email, message, newsletter, error, errorField, loading, submitted,
        } = this.state;
        const action = urlGenerator.route('api.contact');
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
            >
                <form
                    action={action}
                    method="POST"
                    className={styles.form}
                    onSubmit={this.onSubmit}
                >
                    <div
                        className={classNames([
                            styles.formGroup,
                            {
                                [styles.hasError]: errorField === 'email',
                            },
                        ])}
                    >
                        <label className={styles.label} htmlFor="email">
                            <FormattedMessage {...messages.emailLabel} />
                        </label>
                        <input
                            required
                            type="email"
                            name="email"
                            id="email"
                            className={styles.input}
                            value={email || ''}
                            placeholder={intl.formatMessage(messages.emailPlaceholder)}
                            onChange={this.onEmailChange}
                        />
                    </div>

                    <div
                        className={classNames([
                            styles.formGroup,
                            {
                                [styles.hasError]: errorField === 'message',
                            },
                        ])}
                    >
                        <label className={styles.label} htmlFor="message">
                            <FormattedMessage {...messages.messageLabel} />
                        </label>
                        <textarea
                            required
                            name="message"
                            id="message"
                            className={styles.textarea}
                            value={message || ''}
                            placeholder={intl.formatMessage(messages.messagePlaceholder)}
                            onChange={this.onMessageChange}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <CheckboxButton
                            checked={newsletter}
                            onClick={this.onClickNewsletterCheckbox}
                        >
                            <FormattedMessage {...messages.newsletterLabel} />
                        </CheckboxButton>
                    </div>

                    <div className={styles.actions}>
                        <Button type="submit" className={styles.button} red disabled={loading}>
                            <FormattedMessage {...messages.button} />
                        </Button>

                        {error !== null ? <div className={styles.error}>{error}</div> : null}

                        {submitted ? (
                            <div className={styles.success}>
                                <FormattedMessage {...messages.success} />
                            </div>
                        ) : null}
                    </div>
                </form>
            </div>
        );
    }
}

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

const WithUrlGeneratorContainer = withUrlGenerator()(ContactForm);
const WithIntlContainer = injectIntl(WithUrlGeneratorContainer);
const WithTrackingContainer = withTracking(WithIntlContainer);
export default WithTrackingContainer;
