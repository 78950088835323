import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const MDAIcon = ({ className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="50px"
        height="50px"
        viewBox="0 0 50 50"
        xmlSpace="preserve"
        className={className}
    >
        <circle fill="#e95949" cx="25" cy="25" r="25" />
        <g>
            <path
                fill="#fff"
                d="M38.3,34.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.9v-4.1v-6.3l-3.5,0.3c0,0,0,5.2,0,5.3h-0.1c-0.5-0.4-0.9-0.7-1.3-0.9c-0.4-0.2-0.9-0.3-1.5-0.3c-0.8,0-1.5,0.2-2,0.5c-0.5,0.4-1,0.8-1.3,1.4c-0.3,0.6-0.6,1.2-0.7,1.9c-0.1,0.7-0.2,1.4-0.2,2.1c0,0.6,0.1,1.3,0.2,1.9c0.1,0.6,0.3,1.2,0.7,1.7c0.3,0.5,0.7,0.9,1.2,1.3c0.5,0.3,1.1,0.5,1.9,0.5c0.5,0,1-0.1,1.4-0.2c0.5-0.1,0.9-0.3,1.4-0.4c0.5-0.2,0.9-0.3,1.4-0.4c0.5-0.1,0.9-0.2,1.4-0.2c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3C38.4,34.4,38.4,34.3,38.3,34.2z M34.6,33.2c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.7-0.1-0.9-0.4c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.4-0.2-0.8-0.3-1.1c0-0.4-0.1-0.7-0.1-1s0-0.6,0.1-1s0.2-0.8,0.3-1.1c0.1-0.4,0.3-0.7,0.6-0.9c0.2-0.3,0.6-0.4,0.9-0.4c0.4,0,0.7,0.1,1,0.3s0.5,0.5,0.7,0.8L34.6,33.2L34.6,33.2z"
            />
            <path
                fill="#fff"
                d="M22.1,25.7c-0.8,0-1.4,0.2-1.9,0.5s-0.9,0.8-1.4,1.4c0,0-0.3,0.4-0.5,0s-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.5-1-0.7s-0.8-0.2-1.2-0.2c-0.7,0-1.3,0.1-1.8,0.3s-0.9,0.5-1.2,0.7c-0.3,0.3-0.6,0.5-0.9,0.7s-0.5,0.3-0.7,0.3c-0.3,0-0.6-0.1-1-0.3c-0.3-0.2-0.6-0.4-0.9-0.6c0,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.3s-0.1,0.2-0.2,0.3s-0.1,0.2-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.3s0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.3c0,1.3,0,2.6,0,3.8c0,1.3,0,2.5,0,3.8h3.5v-6.5c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4,0,0.6v6.6h3.5v-6.5c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4,0,0.6v6.6h3.5v-7.9c0-1-0.3-1.7-0.8-2.3C23.9,26,23.1,25.7,22.1,25.7z"
            />
            <path
                fill="#fff"
                d="M49.5,28.1c-0.2-0.6-0.4-1.1-0.8-1.4c-0.4-0.3-0.9-0.6-1.5-0.7c-0.6-0.1-1.4-0.2-2.3-0.2c-0.3,0-0.6,0-0.9,0.1c-0.3,0.1-0.7,0.2-1,0.3s-0.7,0.3-1,0.4c-0.3,0.2-0.6,0.3-0.8,0.5l0.1,1.3c0.5-0.2,1-0.3,1.5-0.5c0.5-0.1,1-0.2,1.6-0.2c0.5,0,1,0.1,1.3,0.3c0.4,0.2,0.6,0.6,0.6,1.2V30c-0.7,0-1.4,0-2.1,0.1c-0.7,0.1-1.4,0.2-2,0.4s-1.1,0.6-1.4,1.1c-0.4,0.5-0.6,1.2-0.6,2.1c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.4,0.7,0.7,1s0.6,0.5,1,0.7s0.8,0.2,1.3,0.2s0.9-0.1,1.3-0.2c0.4-0.1,0.8-0.3,1.3-0.4c0.4-0.2,0.8-0.3,1.3-0.4h0.1c1-2.1,1.8-4.5,2.2-6.9l-0.1-0.6C49.6,28.3,49.5,28.2,49.5,28.1z M46.4,33.8c-0.2,0.3-0.4,0.5-0.7,0.7s-0.6,0.3-1,0.3c-0.3,0-0.6-0.1-0.8-0.3s-0.3-0.5-0.3-0.9c0-0.3,0.1-0.6,0.2-0.7c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.5-0.1,0.7-0.1h0.8v1.6H46.4z"
            />
        </g>
    </svg>
);

MDAIcon.propTypes = propTypes;
MDAIcon.defaultProps = defaultProps;

export default MDAIcon;
