import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import CustomMap from './CustomMap';

import styles from '../../../styles/partials/map-visual.scss';

const propTypes = {
    image: PropTypes.oneOfType([
        AppPropTypes.image,
        PropTypes.shape({
            url: PropTypes.string.isRequired,
        }),
    ]),
    imageChildren: PropTypes.node,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    className: PropTypes.string,
    colLeftClassName: PropTypes.string,
    colRightClassName: PropTypes.string,
};

const defaultProps = {
    image: null,
    imageChildren: null,
    latitude: null,
    longitude: null,
    className: null,
    colLeftClassName: null,
    colRightClassName: null,
};

const MapVisualBlock = ({
    image,
    imageChildren,
    latitude,
    longitude,
    className,
    colLeftClassName,
    colRightClassName,
}) => {
    const hasMap = latitude !== null && longitude !== null;
    let imageUrl = null;
    if (image !== null) {
        imageUrl = typeof image.sizes !== 'undefined' && typeof image.sizes.large !== 'undefined'
            ? image.sizes.large
            : image.url;
    }
    return (
        <div
            className={classNames({
                [styles.container]: true,
                [styles.noMap]: !hasMap,
                [className]: className !== null,
            })}
        >
            <div className={styles.cols}>
                <div
                    className={classNames([
                        styles.col,
                        styles.colLeft,
                        {
                            [colLeftClassName]: colLeftClassName !== null,
                        },
                    ])}
                >
                    <div className={styles.imageContainer}>
                        {imageChildren}
                        <div
                            className={styles.image}
                            style={{
                                backgroundImage: imageUrl ? `url("${imageUrl}")` : null,
                            }}
                        />
                        {imageUrl !== null ? (
                            <img src={imageUrl} className={styles.img} alt="" />
                        ) : null}
                    </div>
                </div>
                {hasMap ? (
                    <div
                        className={classNames([
                            styles.col,
                            styles.colRight,
                            {
                                [colRightClassName]: colRightClassName !== null,
                            },
                        ])}
                    >
                        <CustomMap
                            markers={[
                                {
                                    latitude,
                                    longitude,
                                },
                            ]}
                            latitude={latitude}
                            longitude={longitude}
                            zoom={13}
                            className={styles.map}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

MapVisualBlock.propTypes = propTypes;
MapVisualBlock.defaultProps = defaultProps;

export default MapVisualBlock;
