/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/blocks/stats.scss';

const propTypes = {
    numbers: PropTypes.arrayOf(
        PropTypes.shape({
            number: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    isSection: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    numbers: [],
    isSection: false,
    className: null,
};

const StatsBlock = ({ numbers, isSection, className }) => (
    <div
        className={classNames({
            [styles.container]: true,
            [styles.isSection]: isSection,
            [className]: className !== null,
        })}
    >
        <div className={styles.inner}>
            <ul className={styles.items}>
                {numbers.map(({ number, label = null }, index) => (
                    <li key={`number-${index}`} className={styles.item}>
                        <div className={styles.circle}>
                            <div className={styles.inner}>
                                <div className={styles.middle}>
                                    <h4
                                        className={styles.number}
                                        style={{
                                            // prettier-ignore
                                            fontSize: number !== null
                                                ? Math.max(120 - (number.length * 10), 40)
                                                : null,
                                        }}
                                    >
                                        {number}
                                    </h4>
                                    {label !== null ? (
                                        <p className={styles.label}>{label}</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.border} />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

StatsBlock.propTypes = propTypes;
StatsBlock.defaultProps = defaultProps;

export default StatsBlock;
