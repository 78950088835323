import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const PlayIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="61.3px"
        height="61.3px"
        viewBox="0 0 61.3 61.3"
        xmlSpace="preserve"
        className={className}
    >
        <g>
            <polygon fill={color} points="47,31.5 20.9,16.5 20.9,46.6" />
            <circle
                fill="none"
                stroke={color}
                strokeWidth="3"
                strokeMiterlimit="10"
                cx="30.7"
                cy="30.7"
                r="29.2"
            />
        </g>
    </svg>
);

PlayIcon.propTypes = propTypes;
PlayIcon.defaultProps = defaultProps;

export default PlayIcon;
