import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const MagListIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="15.6px"
        height="19.8px"
        viewBox="0 0 15.6 19.8"
        xmlSpace="preserve"
        className={className}
    >
        <g>
            <rect fill={color} width="15.6" height="5.1" />
            <rect y="7.3" fill={color} width="15.6" height="5.1" />
            <rect y="14.7" fill={color} width="15.6" height="5.1" />
        </g>
    </svg>
);

MagListIcon.propTypes = propTypes;
MagListIcon.defaultProps = defaultProps;

export default MagListIcon;
