/**
 * Constants
 */
export const SET_RESULTS = 'quiz@SET_RESULTS';
export const SET_COUNT = 'quiz@SET_COUNT';
export const CLEAR_QUIZ = 'quiz@CLEAR_QUIZ';

/**
 * Actions creator
 */
export const setResults = results => ({
    type: SET_RESULTS,
    payload: results,
});

export const setCount = count => ({
    type: SET_COUNT,
    payload: count,
});

export const clearQuiz = () => ({
    type: CLEAR_QUIZ,
});
