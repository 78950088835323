import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const HomeIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="18.8px"
        height="17.9px"
        viewBox="0 0 18.8 17.9"
        xmlSpace="preserve"
        className={className}
    >
        <polygon
            fill={color}
            points="9.4,0 0,9.3 1.6,9.3 1.6,17.9 7.9,17.9 7.9,11.6 11,11.6 11,17.9 17.2,17.9 17.2,9.3 18.8,9.3"
        />
    </svg>
);

HomeIcon.propTypes = propTypes;
HomeIcon.defaultProps = defaultProps;

export default HomeIcon;
