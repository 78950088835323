import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

import * as AppPropTypes from '../../lib/PropTypes';
import { pascalCase } from '../../lib/utils';
import * as BlockComponents from './index';

import styles from '../../../styles/blocks/blocks.scss';

const propTypes = {
    page: AppPropTypes.page,
    blocks: AppPropTypes.blocks,
    blocksAreSections: PropTypes.bool,
    className: PropTypes.string,
    blockClassName: PropTypes.string,
    withoutMargin: PropTypes.bool,
    isHomePage: PropTypes.bool,
    isPreview: PropTypes.bool,
    purplePage: PropTypes.bool,
    components: AppPropTypes.components,
};

const defaultProps = {
    page: null,
    blocks: [],
    blocksAreSections: false,
    className: null,
    blockClassName: null,
    withoutMargin: false,
    isHomePage: false,
    isPreview: false,
    purplePage: true,
    components: BlockComponents,
};

const Blocks = ({
    blocks,
    blocksAreSections,
    page,
    className,
    blockClassName,
    withoutMargin,
    isHomePage,
    isPreview,
    purplePage,
    components,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [styles.withoutMargin]: withoutMargin,
                [className]: className !== null,
            },
        ])}
    >
        {blocks.map((block) => {
            const BlockType = pascalCase(block.type) || null;
            const BlockComponent = BlockType ? components[BlockType] || null : null;
            const currentClassName = block.className || null;
            const blockWithoutMargin = get(BlockComponent, 'withoutMargin', false);
            // console.log(block.type, purplePage, block);
            return BlockComponent !== null ? (
                <div
                    key={`block-${block.id}`}
                    className={classNames({
                        [styles.block]: true,
                        [styles.withoutMargin]: blockWithoutMargin,
                        [blockClassName]: blockClassName !== null,
                        [currentClassName]: currentClassName !== null,
                        [styles[`type${BlockType}`]]: BlockType !== null,
                    })}
                >
                    <BlockComponent
                        id={block.id}
                        page={page}
                        isSection={blocksAreSections}
                        isHomePage={isHomePage}
                        isPreview={isPreview}
                        purplePage={purplePage}
                        {...block}
                    />
                </div>
            ) : null;
        })}
    </div>
);

Blocks.propTypes = propTypes;
Blocks.defaultProps = defaultProps;

export default Blocks;
