import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Text from '../Text';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../../styles/blocks/quiz/answer.scss';

const propTypes = {
    selectedAnswer: PropTypes.shape({
        explanation: PropTypes.string,
        good: PropTypes.bool,
    }), // eslint-disable-line
    neutral: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    selectedAnswer: null,
    neutral: false,
    className: null,
};

const Answer = ({ selectedAnswer, neutral, className }) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <div className={styles.inner}>
            {selectedAnswer !== null && selectedAnswer.explanation ? (
                <div
                    className={classNames([
                        styles.explanation,
                        {
                            [styles.good]: !neutral && selectedAnswer.good,
                            [styles.bad]: !neutral && !selectedAnswer.good,
                        },
                    ])}
                >
                    <Text text={selectedAnswer.explanation} />
                </div>
            ) : null}
        </div>
    </div>
);

Answer.propTypes = propTypes;
Answer.defaultProps = defaultProps;

export default Answer;
