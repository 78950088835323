/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import * as AppPropTypes from '../../lib/PropTypes';
import { addNonBreakingSpaces } from '../../lib/utils';
import Blocks from '../blocks/Blocks';
import Author from '../partials/MagazineAuthor';
import MoreButton from '../buttons/More';

import styles from '../../../styles/magazines/text.scss';

const propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    image: AppPropTypes.image,
    author: AppPropTypes.author,
    date: PropTypes.string,
    page: AppPropTypes.page,
    blocks: AppPropTypes.blocks,
    short: PropTypes.bool,
    isPageHeading: PropTypes.bool,
    isPreview: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    url: null,
    title: null,
    image: null,
    author: null,
    date: null,
    page: null,
    blocks: null,
    short: false,
    isPageHeading: false,
    isPreview: false,
    className: null,
};

const MagazineText = ({
    url,
    author,
    date,
    title,
    image,
    page,
    blocks,
    short,
    isPageHeading,
    isPreview,
    className,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [styles.short]: short,
                [className]: className !== null,
            },
        ])}
    >
        <div className={styles.inner}>
            {image !== null ? (
                <div className={classNames([styles.section, styles.imageContainer])}>
                    <img src={image.url} alt={title} className={styles.image} />
                </div>
            ) : null}

            {title !== null ? (
                <div className={classNames([styles.section, styles.titleContainer])}>
                    {isPageHeading ? (
                        <h1 className={styles.title}>
                            {url !== null ? (
                                <Link to={url}>{addNonBreakingSpaces(title)}</Link>
                            ) : (
                                addNonBreakingSpaces(title)
                            )}
                        </h1>
                    ) : (
                        <h2 className={styles.title}>
                            {url !== null ? (
                                <Link to={url}>{addNonBreakingSpaces(title)}</Link>
                            ) : (
                                addNonBreakingSpaces(title)
                            )}
                        </h2>
                    )}
                </div>
            ) : null}

            {author !== null ? (
                <div className={classNames([styles.section, styles.authorContainer])}>
                    <Author {...author} date={date} />
                </div>
            ) : null}
        </div>

        {blocks !== null && blocks.length > 0 ? (
            <div className={classNames([styles.section, styles.blocksContainer])}>
                <Blocks
                    page={page}
                    blocks={(short ? blocks.slice(0, 1) : blocks).map((it) =>
                        it.type === 'magazines_carousel'
                            ? {
                                  ...it,
                                  purple: true,
                              }
                            : it,
                    )}
                    blocksAreSections
                    isPreview={isPreview}
                    purplePage={false}
                />
            </div>
        ) : null}

        {short ? (
            <div className={styles.moreContainer}>
                <MoreButton href={url} className={styles.more} />
            </div>
        ) : null}
    </div>
);

MagazineText.propTypes = propTypes;
MagazineText.defaultProps = defaultProps;

export default React.memo(MagazineText);
