import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    fat: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    fat: false,
    className: null,
};

const CloseIcon = ({ color, fat, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="21.4px"
        height="21.4px"
        viewBox="0 0 21.4 21.4"
        xmlSpace="preserve"
        className={className}
    >
        <g>
            <line
                fill="none"
                stroke={color}
                strokeWidth={fat ? 6 : 2}
                strokeMiterlimit="10"
                strokeLinecap="butt"
                x1="0.7"
                y1="20.7"
                x2="20.7"
                y2="0.7"
            />
            <line
                fill="none"
                stroke={color}
                strokeWidth={fat ? 6 : 2}
                strokeMiterlimit="10"
                strokeLinecap="butt"
                x1="0.7"
                y1="0.7"
                x2="20.7"
                y2="20.7"
            />
        </g>
    </svg>
);

CloseIcon.propTypes = propTypes;
CloseIcon.defaultProps = defaultProps;

export default CloseIcon;
