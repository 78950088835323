/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import { getFiltersFromQuery, getQueryStringFromFilters } from '../../lib/utils';
import withFilters from '../../lib/withFilters';

import PageMeta from '../partials/PageMeta';
import PageHeader from '../partials/PageHeader';
import ContactsFilters from '../partials/ContactsFilters';
import BackBar from '../partials/BackBar';
import ContactsList from '../partials/ContactsList';
import Toggles from '../buttons/Toggles';
import Button from '../buttons/Button';
import AlphaIcon from '../icons/Alpha';
import MagListIcon from '../icons/MagList';
import Detector from '../partials/Detector';

import {
    setView as setViewActions,
    setMaxLetters as setMaxLettersActions,
} from '../../actions/ContactsActions';

import styles from '../../../styles/pages/list-contacts.scss';

const messages = defineMessages({
    backToAll: {
        id: 'content.back_all_contacts',
        defaultMessage: '← Retour à tous les acteurs culturels',
    },
    alpha: {
        id: 'content.events_view_alpha',
        defaultMessage: 'Alphabétique',
    },
    type: {
        id: 'content.events_view_category',
        defaultMessage: 'Catégorie',
    },
    letters: {
        id: 'content.view_next',
        defaultMessage: 'Voir la suite',
    },
    artiste: {
        id: 'content.main_categories.artists',
        defaultMessage: 'Artistes',
    },
    'organisme-culturel': {
        id: 'content.main_categories.organisations',
        defaultMessage: 'Organismes culturels',
    },
    'lieu-de-diffusion': {
        id: 'content.main_categories.locations',
        defaultMessage: 'Lieux culturels',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    page: AppPropTypes.page.isRequired,
    filters: AppPropTypes.contactsFilters,
    category: AppPropTypes.category,
    views: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: AppPropTypes.label,
        }),
    ),
    view: PropTypes.string,
    maxLetters: PropTypes.number.isRequired,
    letters: PropTypes.arrayOf(PropTypes.string),
    count: PropTypes.number,
    setFilters: PropTypes.func.isRequired,
    setView: PropTypes.func.isRequired,
    setMaxLetters: PropTypes.func.isRequired,
};

const defaultProps = {
    filters: null,
    category: null,
    views: [
        {
            value: 'alpha',
            label: messages.alpha,
            icon: <AlphaIcon className={styles.icon} />,
        },
        {
            value: 'type',
            label: messages.type,
            icon: <MagListIcon className={styles.icon} />,
        },
    ],
    view: 'type',
    letters: 'abcdefghijklmnopqrstuvwxyz'.split(''),
    count: 8,
};

class ListContacts extends Component {
    constructor(props) {
        super(props);
        this.onFiltersChange = this.onFiltersChange.bind(this);
        this.onViewChange = this.onViewChange.bind(this);
        this.loadLetters = this.loadLetters.bind(this);
    }

    componentDidUpdate({ filters: prevFilters, category: prevCategory }) {
        const { filters, category } = this.props;
        const filtersChanged = prevFilters !== filters;
        const categoryChanged = category !== prevCategory;
        if (filtersChanged || categoryChanged) {
            window.scrollTo({ top: 0 });
        }
    }

    onViewChange(view) {
        const { setView, setFilters } = this.props;
        setView(view);
        setFilters({});
    }

    onFiltersChange(value) {
        const { setFilters } = this.props;
        setFilters(value);
    }

    getListFilters() {
        const { category, filters } = this.props;
        const listFilters =
            category !== null
                ? {
                      ...filters,
                      categories: [category.id],
                  }
                : filters;
        return listFilters;
    }

    loadLetters() {
        const { maxLetters, count, setMaxLetters } = this.props;
        setMaxLetters(maxLetters + count);
    }

    renderByType(listFilters) {
        const { intl, page, category } = this.props;
        const { categories = [] } = page;

        return categories
            .filter((it) => category === null || it.id === category.id)
            .map((it) => (
                <ContactsList
                    key={`section-${it.id}`}
                    page={page}
                    category={it}
                    title={
                        it.slug && messages[it.slug] ? intl.formatMessage(messages[it.slug]) : null
                    }
                    filters={listFilters}
                    loadable={category !== null}
                />
            ));
    }

    renderByAlpha(listFilters) {
        const { page, category, letters, maxLetters } = this.props;
        const displayedLetters = letters.slice(0, maxLetters); // ; //

        return displayedLetters
            .filter((it) => category === null || it === 'a')
            .map((it) => (
                <ContactsList
                    key={`alpha-${it}`}
                    page={page}
                    letter={it}
                    filters={listFilters}
                    loadable
                    autoclose
                />
            ));
    }

    render() {
        const { page, category, filters, view, views, maxLetters, letters } = this.props;
        const homeUrl = '/fr/repertoire-culturel';
        const { categories = [] } = page;
        const filtersOnly = categories.length === 0 || (filters !== null && category === null);
        const listFilters = this.getListFilters();
        const availableViews =
            category !== null
                ? views.map((v) => (v.value === 'alpha' ? { ...v, disabled: true } : v))
                : views;
        const headerProps = page.url !== homeUrl ? { href: homeUrl } : null;

        return (
            <div className={styles.container}>
                <PageMeta {...page.share} canonical={filters !== null} noIndex={filters !== null} />
                <div className={styles.inner}>
                    <PageHeader
                        title={page.title}
                        className={styles.header}
                        {...headerProps}
                        big
                        right={
                            <Toggles
                                options={availableViews}
                                value={view}
                                buttonClassName={styles.button}
                                onChange={this.onViewChange}
                            />
                        }
                    />
                    <div className={styles.filters}>
                        <ContactsFilters value={filters} onChange={this.onFiltersChange} />
                    </div>
                </div>
                <div
                    className={classNames([
                        styles.content,
                        {
                            [styles.withBackBar]: category !== null,
                        },
                    ])}
                >
                    {category !== null ? (
                        <div className={styles.inner}>
                            <BackBar
                                url={homeUrl}
                                label={messages.backToAll}
                                className={styles.backBar}
                            />
                        </div>
                    ) : null}
                    {filtersOnly ? (
                        <ContactsList page={page} filters={listFilters} loadable />
                    ) : null}
                    {!filtersOnly && view === 'type' ? this.renderByType(listFilters) : null}
                    {!filtersOnly && view === 'alpha' ? this.renderByAlpha(listFilters) : null}
                </div>
                <div className={styles.next}>
                    {view === 'alpha' && maxLetters <= letters.length ? (
                        <div className={styles.loadMore}>
                            <Detector onEnter={this.loadLetters}>
                                <Button onClick={this.loadLetters} red>
                                    {messages.letters}
                                </Button>
                            </Detector>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

ListContacts.propTypes = propTypes;
ListContacts.defaultProps = defaultProps;

const WithFiltersContainer = withFilters(({ query }) => {
    if (query !== null && Object.keys(query).length > 0) {
        return getFiltersFromQuery(query, {
            disciplines: true,
            districts: true,
            sectors: true,
        });
    }
    return null;
})(ListContacts);

const WithStateContainer = connect(
    ({ contacts }) => ({
        ...contacts,
    }),
    (dispatch, { page, category, urlGenerator }) => ({
        setFilters: (filters) =>
            dispatch(
                push(
                    category !== null
                        ? `${urlGenerator.route('pages.list.with_category', {
                              page: page.slug,
                              category: category.slug,
                          })}${getQueryStringFromFilters(filters)}`
                        : `${page.url}${getQueryStringFromFilters(filters)}`,
                ),
            ),
        setView: (type) => dispatch(setViewActions(type)),
        setMaxLetters: (num) => dispatch(setMaxLettersActions(num)),
    }),
)(WithFiltersContainer);

const WithUrlGeneratorContainer = withUrlGenerator()(WithStateContainer);
const WithIntlContainer = injectIntl(WithUrlGeneratorContainer);
export default WithIntlContainer;
