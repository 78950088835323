import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import * as AppPropTypes from '../../lib/PropTypes';

import PageMeta from '../partials/PageMeta';
import PageHeader from '../partials/PageHeader';
import CardsList from '../partials/CardsList';

import styles from '../../../styles/pages/list-districts.scss';

const DistrictsMap = Loadable({
    loader: () => import('../partials/DistrictsMap'),
    loading: () => null,
});

const propTypes = {
    page: AppPropTypes.page.isRequired,
    gotoDistrict: PropTypes.func.isRequired,
};

class ListDistricts extends PureComponent {
    constructor(props) {
        super(props);
        this.onClickDistrict = this.onClickDistrict.bind(this);
    }

    onClickDistrict(e, slug) {
        const { gotoDistrict } = this.props;
        gotoDistrict(slug);
    }

    render() {
        const { page } = this.props;
        return (
            <div className={styles.container}>
                <PageMeta {...page.share} />
                <div className={styles.inner}>
                    <PageHeader
                        title={page.title}
                        big
                        className={styles.header}
                        innerClassName={styles.titleContainer}
                        titleClassName={styles.title}
                    >
                        <div className={styles.mapContainer}>
                            <DistrictsMap
                                className={styles.map}
                                onClickDistrict={this.onClickDistrict}
                            />
                        </div>
                    </PageHeader>
                </div>
                <div className={styles.list}>
                    <div className={styles.inner}>
                        <CardsList items={page.districts} largeSpacing />
                    </div>
                </div>
            </div>
        );
    }
}

ListDistricts.propTypes = propTypes;

const WithStateContainer = connect(null, (dispatch, { page }) => ({
    gotoDistrict: (slug) => {
        const district = page.districts.find((it) => it.handle === slug) || null;
        if (district !== null) {
            dispatch(push(district.url));
        }
    },
}))(ListDistricts);

export default WithStateContainer;
