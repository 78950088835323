import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    eyeColor: PropTypes.string,
    withStroke: PropTypes.bool,
    withoutEye: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    eyeColor: 'currentColor',
    withStroke: false,
    withoutEye: false,
    className: null,
};

const MagazineIcon = ({
    color, eyeColor, withStroke, withoutEye, className,
}) => (withStroke ? (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100px"
        height="85.7px"
        viewBox="0 0 100 85.7"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            d="M75,4c7.2,0,14.3,1.2,21,3.7V80c-6.8-2.2-13.8-3.3-21-3.3c-8.6,0-17,1.6-25,4.7c-8-3.1-16.4-4.7-25-4.7c-7.2,0-14.2,1.1-21,3.3V7.7C10.7,5.2,17.8,4,25,4c8.1,0,16,1.6,23.5,4.7L50,9.3l1.5-0.6C59,5.6,66.9,4,75,4 M75,0c-8.5,0-17,1.7-25,5v0c-8-3.3-16.5-5-25-5S8,1.7,0,5c0,26.9,0,53.8,0,80.7c8-3.3,16.5-5,25-5s17,1.7,25,5c8-3.3,16.5-5,25-5c8.5,0,17,1.7,25,5c0-26.9,0-53.8,0-80.7C92,1.7,83.5,0,75,0L75,0z"
        />
        {!withoutEye ? (
            <path
                fill={eyeColor}
                d="M73.8,40.7c-5.9-7-14.6-11-23.8-11c-9.2,0-17.9,4-23.8,11l-0.8,1l0.8,1c5.9,7,14.6,11,23.8,11c9.2,0,17.9-4,23.8-11l0.8-1L73.8,40.7z M29.3,41.7c3.4-3.7,7.8-6.4,12.6-7.8c-2,2-3.2,4.8-3.2,7.8c0,3,1.2,5.8,3.2,7.8C37.1,48.1,32.7,45.4,29.3,41.7z M50,50c-4.6,0-8.4-3.8-8.4-8.4s3.8-8.4,8.4-8.4c1,0,2,0.2,2.9,0.5l-2,6.7l7-1.6c0.3,0.9,0.5,1.8,0.5,2.8C58.4,46.3,54.6,50,50,50z M58.2,49.5c2-2,3.2-4.8,3.2-7.8c0-3-1.2-5.8-3.2-7.8c4.8,1.4,9.1,4.1,12.6,7.8C67.3,45.4,62.9,48.1,58.2,49.5z"
            />
        ) : null}
    </svg>
) : (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="39px"
        height="33.4px"
        viewBox="0 0 39 33.4"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            d="M19.5,1.9L19.5,1.9C13.3-0.6,6.2-0.6,0,1.9c0,10.5,0,21,0,31.5c6.2-2.6,13.3-2.6,19.5,0c6.2-2.6,13.3-2.6,19.5,0c0-10.5,0-21,0-31.5C32.8-0.6,25.7-0.6,19.5,1.9z"
        />
        {!withoutEye ? (
            <path
                fill={eyeColor}
                d="M33.4,15.7c-3.5-4.1-8.5-6.5-13.9-6.5c-5.4,0-10.4,2.4-13.9,6.5l-0.5,0.6l0.5,0.6c3.5,4.1,8.5,6.5,13.9,6.5c5.4,0,10.4-2.4,13.9-6.5l0.5-0.6L33.4,15.7z M7.4,16.3c2-2.2,4.6-3.7,7.3-4.6c-1.1,1.2-1.9,2.8-1.9,4.6c0,1.8,0.7,3.4,1.9,4.6C11.9,20,9.4,18.4,7.4,16.3z M19.5,21.2c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c0.6,0,1.2,0.1,1.7,0.3L20,15.6l4.1-0.9c0.2,0.5,0.3,1.1,0.3,1.6C24.4,19,22.2,21.2,19.5,21.2z M24.3,20.8c1.1-1.2,1.9-2.8,1.9-4.6c0-1.8-0.7-3.4-1.9-4.6c2.8,0.8,5.3,2.4,7.3,4.6C29.6,18.4,27.1,20,24.3,20.8z"
            />
        ) : null}
    </svg>
));

MagazineIcon.propTypes = propTypes;
MagazineIcon.defaultProps = defaultProps;

export default MagazineIcon;
