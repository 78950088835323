import React, { Component } from 'react';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import classNames from 'classnames';
import get from 'lodash/get';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { withUrlGenerator } from '../../lib/react-container';

import * as AppPropTypes from '../../lib/PropTypes';
import { subscribeNewsletter } from '../../lib/requests';
import Button from '../buttons/Button';
import { withTracking } from '../../lib/TrackingContext';

import styles from '../../../styles/partials/newsletter.scss';

const messages = defineMessages({
    title: {
        id: 'content.newsletter_title',
        defaultMessage: 'Abonnez-vous à notre infolettre',
    },
    description: {
        id: 'content.newsletter_description',
        defaultMessage: 'Recevez à chaque semaine des propositions d’activités et des nouvelles',
    },
    placeholder: {
        id: 'content.newsletter_placeholder',
        defaultMessage: 'Entrez votre courriel...',
    },
    button: {
        id: 'content.newsletter_button',
        defaultMessage: 'S’abonner',
    },
    error: {
        id: 'content.newsletter_error',
        defaultMessage: 'Il s’est produit une erreur.',
    },
    success: {
        id: 'content.newsletter_success',
        defaultMessage: 'Vous êtes maintenant abonné à notre infolettre!',
    },
});

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    trackEvent: PropTypes.func.isRequired,
    intl: AppPropTypes.intl.isRequired,
    email: PropTypes.string,
    withoutLetter: PropTypes.bool,
    red: PropTypes.bool,
    small: PropTypes.bool,
    onSubscribed: PropTypes.func,
};

const defaultProps = {
    email: null,
    withoutLetter: false,
    red: false,
    small: false,
    onSubscribed: null,
};

class Newsletter extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubscribed = this.onSubscribed.bind(this);
        this.onSubscribeError = this.onSubscribeError.bind(this);

        this.state = {
            email: props.email,
            loading: true,
            error: null,
            subscribed: false,
        };
    }

    onSubmit(e) {
        e.preventDefault();

        const { email } = this.state;
        if (email === null || trim(email).length < 1) {
            return;
        }

        this.setState({
            loading: true,
            error: null,
        });
        this.submit();
    }

    onChange(e) {
        this.setState({
            email: e.target.value,
            error: null,
        });
    }

    onSubscribed({ success }) {
        const { onSubscribed, intl, trackEvent } = this.props;
        const { email } = this.state;
        if (onSubscribed !== null) {
            if (!success) {
                this.setState({
                    error: intl.formatMessage(messages.error),
                });
            } else {
                onSubscribed();
            }
        } else {
            this.setState({
                email: null,
                loading: false,
                subscribed: success,
                error: !success ? intl.formatMessage(messages.error) : null,
            });
        }

        if (success) {
            trackEvent('Newsletter', 'register', email || '');
        }
    }

    // eslint-disable-next-line
    onSubscribeError(error) {
        const { intl } = this.props;
        if (error.name === 'ValidationError') {
            this.setState({
                error: get(error.responseData, 'errors.email.0', null),
            });
        } else {
            this.setState({
                error: intl.formatMessage(messages.error),
            });
        }
    }

    submit() {
        const { urlGenerator } = this.props;
        const { email } = this.state;
        const url = urlGenerator.route('api.newsletter');
        return subscribeNewsletter(url, email)
            .then(this.onSubscribed)
            .catch(this.onSubscribeError);
    }

    render() {
        const {
            urlGenerator, intl, withoutLetter, small, red,
        } = this.props;
        const {
            email, error, loading, subscribed,
        } = this.state;
        const action = urlGenerator.route('api.newsletter');
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.small]: small,
                        [styles.hasErrors]: error !== null,
                        [styles.isLoading]: loading,
                    },
                ])}
            >
                <div className={styles.content}>
                    <h4 className={styles.title}>
                        <FormattedMessage {...messages.title} />
                    </h4>
                    <p className={styles.description}>
                        <FormattedMessage {...messages.description} />
                    </p>
                    {!withoutLetter ? <div className={styles.letter} /> : null}
                </div>
                {!subscribed ? (
                    <form
                        action={action}
                        method="POST"
                        className={styles.form}
                        onSubmit={this.onSubmit}
                    >
                        <div className={styles.cols}>
                            <div className={styles.col}>
                                <input
                                    required
                                    type="email"
                                    name="email"
                                    className={styles.input}
                                    value={email || ''}
                                    placeholder={intl.formatMessage(messages.placeholder)}
                                    onChange={this.onChange}
                                />
                                {error !== null ? (
                                    <div className={styles.error}>{error}</div>
                                ) : null}
                            </div>
                            <div className={styles.col}>
                                <Button type="submit" className={styles.button} red={red}>
                                    <FormattedMessage {...messages.button} />
                                </Button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <div className={styles.success}>
                        <FormattedMessage {...messages.success} />
                    </div>
                )}
            </div>
        );
    }
}

Newsletter.propTypes = propTypes;
Newsletter.defaultProps = defaultProps;

const WithUrlGeneratorContainer = withUrlGenerator()(Newsletter);
const WithIntlContainer = injectIntl(WithUrlGeneratorContainer);
const WithTrackingContainer = withTracking(WithIntlContainer);
export default WithTrackingContainer;
