import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isArray from 'lodash/isArray';

import * as AppPropTypes from '../../lib/PropTypes';
import CaretIcon from '../icons/Caret';
import Button from './Button';
import CheckboxButton from './Checkbox';

import styles from '../../../styles/buttons/dropdown.scss';

const propTypes = {
    options: AppPropTypes.toggleOptions.isRequired,
    label: AppPropTypes.label,
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        PropTypes.string,
        PropTypes.number,
    ]),
    menuPosition: PropTypes.oneOf(['left', 'right', 'auto']),
    closeOnSelect: PropTypes.bool,
    className: PropTypes.string,
    dropdownClassName: PropTypes.string,
    itemsClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    optionClassName: PropTypes.string,
    onChange: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
};

const defaultProps = {
    label: null,
    value: null,
    menuPosition: 'auto',
    closeOnSelect: false,
    className: null,
    dropdownClassName: null,
    itemsClassName: null,
    itemClassName: null,
    buttonClassName: null,
    optionClassName: null,
    onChange: null,
    onOpen: null,
    onClose: null,
};

class Dropdown extends PureComponent {
    constructor(props) {
        super(props);

        this.onClickButton = this.onClickButton.bind(this);
        this.onClickOption = this.onClickOption.bind(this);

        this.refDropdown = null;

        this.state = {
            opened: false,
            menuPosition: props.menuPosition !== 'auto' ? props.menuPosition : 'left',
        };
    }

    componentDidUpdate(prevProps, { opened: prevOpened }) {
        const { opened } = this.state;
        const openedChanged = prevOpened !== opened;
        if (openedChanged && opened) {
            this.updatePosition();
        }
    }

    onClickButton() {
        this.toggle();
    }

    onClickOption(e, option) {
        const { value, closeOnSelect, onChange } = this.props;
        const valueArray = (value !== null && !isArray(value) ? [value] : value) || [];
        let newValue;
        if (valueArray.indexOf(option.value) !== -1) {
            newValue = valueArray.filter((val) => val !== option.value);
        } else {
            newValue = [...valueArray, option.value];
        }
        if (newValue.length === 0) {
            newValue = null;
        }
        if (onChange !== null) {
            onChange(newValue);
        }

        if (closeOnSelect) {
            this.close();
        }
    }

    updatePosition() {
        const { menuPosition } = this.state;
        const boundingRect = this.refDropdown.getBoundingClientRect();
        const isOut = boundingRect.right > window.innerWidth - 20;
        const newPosition = isOut ? 'right' : menuPosition;
        if (menuPosition !== newPosition) {
            this.setState({
                menuPosition: newPosition,
            });
        }
    }

    toggle() {
        const { onOpen, onClose } = this.props;
        this.setState(
            ({ opened }) => ({
                opened: !opened,
            }),
            () => {
                const { opened } = this.state;
                if (opened && onOpen !== null) {
                    onOpen();
                } else if (!opened && onClose !== null) {
                    onClose();
                }
            },
        );
    }

    open() {
        const { onOpen } = this.props;
        const { opened } = this.state;
        if (opened) {
            return;
        }
        this.setState(
            {
                opened: true,
            },
            () => {
                this.updatePosition();
                if (onOpen !== null) {
                    onOpen();
                }
            },
        );
    }

    close() {
        const { onClose } = this.props;
        const { opened } = this.state;
        if (!opened) {
            return;
        }
        this.setState(
            {
                opened: false,
            },
            () => {
                if (onClose !== null) {
                    onClose();
                }
            },
        );
    }

    render() {
        const {
            options,
            value,
            label,
            className,
            dropdownClassName,
            itemsClassName,
            itemClassName,
            buttonClassName,
            optionClassName,
        } = this.props;
        const { opened, menuPosition } = this.state;
        const valueArray = value !== null && !isArray(value) ? [value] : value;

        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.opened]: opened,
                        [styles.right]: menuPosition === 'right',
                        [className]: className !== null,
                    },
                ])}
            >
                <Button
                    icon={
                        opened ? <CaretIcon orientation="down" /> : <CaretIcon orientation="left" />
                    }
                    iconPosition="inline"
                    transparent
                    className={classNames([
                        styles.button,
                        {
                            [buttonClassName]: buttonClassName !== null,
                        },
                    ])}
                    onClick={this.onClickButton}
                >
                    {valueArray !== null && valueArray.length > 0
                        ? `${label} (${valueArray.length})`
                        : label}
                </Button>
                <div
                    className={classNames([
                        styles.dropdown,
                        {
                            [dropdownClassName]: dropdownClassName !== null,
                        },
                    ])}
                    ref={(ref) => {
                        this.refDropdown = ref;
                    }}
                >
                    <ul
                        className={classNames([
                            styles.items,
                            {
                                [itemsClassName]: itemsClassName !== null,
                            },
                        ])}
                    >
                        {options.map((it, index) => {
                            const { value: optionValue, label: optionLabel } = it;
                            // prettier-ignore
                            const checked = value !== null
                                ? valueArray.indexOf(optionValue) !== -1
                                : false;

                            return (
                                <li
                                    className={classNames([
                                        styles.item,
                                        {
                                            [itemClassName]: itemClassName !== null,
                                        },
                                    ])}
                                    key={`option-${optionValue}`}
                                >
                                    <CheckboxButton
                                        className={classNames([
                                            styles.button,
                                            {
                                                [optionClassName]: optionClassName !== null,
                                            },
                                        ])}
                                        iconClassName={styles.icon}
                                        labelClassName={styles.label}
                                        checked={checked}
                                        onClick={(e) => this.onClickOption(e, it, index)}
                                    >
                                        {optionLabel}
                                    </CheckboxButton>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
